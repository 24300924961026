import React, { useState } from "react";
import { Trans } from "@lingui/macro";

import CopyIcon from "../../../../assets/copy-icon.svg";

const StepProductsOptionCopy = ({ label, value }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setIsCopied(true);
  };

  return (
    <div className="step-products__left__products__product-detail__option step-products__left__products__product-detail__option--margin">
      <div className="step-products__left__products__product-detail__option__label">{label}</div>
      <div className="step-products__left__products__product-detail__option__value">
        <span>{value}</span>
        <button type="button" onClick={handleCopy}>
          <img src={CopyIcon} alt="" />
        </button>
        {isCopied && (
          <span className="copied">
            <Trans>Copied!</Trans>
          </span>
        )}
      </div>
    </div>
  );
};

export default StepProductsOptionCopy;
