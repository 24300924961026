import React from "react";
import { useField } from "formik";

import "./ProductOptionCheckbox.scss";

export const ProductOptionCheckbox = ({ customOnChange, customChecked, ...props }) => {

  const [{ onChange, checked, ...field }, meta] = useField({ ...props, type: 'checkbox' });

  return (
    <label className="checkbox">
      <input
        type="checkbox"
        className="checkbox__input"
        {...field}
        onChange={customOnChange ? (e) => customOnChange(e) : onChange}
        checked={customChecked ? customChecked : checked}
      />
      <span className="checkbox__checkmark"></span>
      <span className="checkbox__label">{props.label}</span>
    </label>
  );
};
