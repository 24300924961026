import React from "react";
import ReactPaginate from "react-paginate";

export const Pagination = ({ orderPage, setOrderPage, items }) => {
  return (
    <div className="pagination-wrapper">
      <span className="go-first-page" onClick={() => setOrderPage(0)}>
        First
      </span>
      <ReactPaginate
        pageCount={items.length / 10}
        pageRangeDisplayed={10}
        onPageChange={e => setOrderPage(e.selected)}
        containerClassName="pagination"
        pageClassName="pagination-item"
        activeClassName="pagination-item-active"
        forcePage={orderPage}
      />
      <span
        className="go-last-page"
        onClick={() => {
          setOrderPage(Math.floor(Object.values(items).length / 10));
        }}
      >
        Last
      </span>
    </div>
  );
};
