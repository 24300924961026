import {
  LOGIN,
  SET_TOKENS,
  LOGOUT,
  SET_LANGUAGE,
  SET_CONFIGURATION_STEP,
  SET_CLIENTS,
  SET_CURRENT_CLIENT,
  SET_MODEL_OPTIONS,
  RESET_MODEL_OPTIONS,
  SET_CONFIGURATION_CAR_MODEL,
  SET_ALL_PRODUCTS,
  SET_ALL_CONFIGURATIONS,
  SET_CONFIGURATION_SPEC_GROUPS,
  SET_CONFIGURATION_PRODUCTS,
  SET_PRODUCT_CATEGORIES,
  SET_PRODUCT_CATEGORY_STATUS,
  SET_CHOSEN_PACK,
  SET_CONFIGURATION_AMOUNT,
  SET_CHOSEN_BRAND,
  SET_CHOSEN_MODEL,
  RESET_CATEGORIES,
  RESET_PARTIAL_CONFIGURATION,
  RESET_FULL_CONFIGURATION,
  SET_CHOSEN_PACK_PRODUCT_ITEMS,
  SET_ALL_SPECIFICATION_GROUPS,
  SET_SPECIFICATION_GROUP,
  SET_VISITED,
  SET_ALLOWED_STEP,
  RESET_ALLOWED_STEPS,
  SET_ALL_ORDERS,
  SET_SPECIFICATION_GROUP_PER_MODEL,
  CLEAR_PACK,
  RESET_CHOSEN_OPTIONS,
  RESET_CHOSEN_PRODUCTS,
  RESET_CHOSEN_PACK_PRODUCTS,
  RESET_ALL_PRODUCTS,
  SET_SINGLE_CONFIGURATION,
  SET_PHOTO_GALLERY_ITEMS,
  ADD_ORDER_ITEM,
  REMOVE_ORDER_ITEM,
  REMOVE_ORDER_ITEMS,
  SET_ORDER_ITEM_AMOUNT,
  SET_ORDER_ITEM_REFERENCE,
} from "./actions";

export const login = () => {
  return {
    type: LOGIN,
  };
};

export const setTokens = (accessToken, refreshToken) => {
  return {
    type: SET_TOKENS,
    payload: {
      accessToken,
      refreshToken,
    },
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const setLanguage = (lang) => {
  return {
    type: SET_LANGUAGE,
    lang,
  };
};

export const setConfigurationStep = (step) => {
  return {
    type: SET_CONFIGURATION_STEP,
    step,
  };
};

export const setClients = (clients) => {
  return {
    type: SET_CLIENTS,
    payload: clients,
  };
};

export const setCurrentClient = (client) => {
  return {
    type: SET_CURRENT_CLIENT,
    payload: client,
  };
};

export const setModelOptions = (options, brand = "unknown") => {
  return {
    type: SET_MODEL_OPTIONS,
    payload: {
      options,
      brand,
    },
  };
};

export const resetModelOptions = () => {
  return {
    type: RESET_MODEL_OPTIONS
  }
}

export const setCurrConfigCarModel = (carModel) => {
  return {
    type: SET_CONFIGURATION_CAR_MODEL,
    payload: carModel,
  };
};

export const setCurrConfigSpecGroups = (specGroups) => {
  return {
    type: SET_CONFIGURATION_SPEC_GROUPS,
    payload: specGroups,
  };
};

export const setCurrConfigProducts = (products) => {
  return {
    type: SET_CONFIGURATION_PRODUCTS,
    payload: products,
  };
};

export const setCurrConfigAmount = (amount) => {
  return {
    type: SET_CONFIGURATION_AMOUNT,
    payload: amount,
  };
};

export const setAllProducts = (products) => {
  return {
    type: SET_ALL_PRODUCTS,
    payload: products,
  };
};

export const setProductCategories = (categories) => {
  return {
    type: SET_PRODUCT_CATEGORIES,
    payload: categories,
  };
};

export const setProductCategoryStatus = (category) => {
  return {
    type: SET_PRODUCT_CATEGORY_STATUS,
    payload: category,
  };
};

export const setChosenPack = (pack) => {
  return {
    type: SET_CHOSEN_PACK,
    payload: pack,
  };
};

export const clearPack = () => {
  return {
    type: CLEAR_PACK,
  };
};

export const setChosenBrand = (brand) => {
  return {
    type: SET_CHOSEN_BRAND,
    payload: brand,
  };
};

export const setModel = (model) => {
  return {
    type: SET_CHOSEN_MODEL,
    payload: model,
  };
};

export const resetPartialCurrConfig = () => {
  return {
    type: RESET_PARTIAL_CONFIGURATION,
  };
};

export const resetFullCurrConfig = () => {
  return {
    type: RESET_FULL_CONFIGURATION,
  };
};

export const resetCategories = () => {
  return {
    type: RESET_CATEGORIES,
  };
};

export const setChosenPackProductItems = (productItems) => {
  return {
    type: SET_CHOSEN_PACK_PRODUCT_ITEMS,
    payload: productItems,
  };
};

export const setAllSpecificationGroups = (category, specificationGroups) => {
  return {
    type: SET_ALL_SPECIFICATION_GROUPS,
    payload: {
      category,
      specificationGroups,
    },
  };
};

export const setSpecificationGroup = (
  category,
  specificationGroupType,
  specificationGroup
) => {
  return {
    type: SET_SPECIFICATION_GROUP,
    payload: {
      category,
      specificationGroupType,
      specificationGroup,
    },
  };
};

export const setAllConfigurations = (configurations) => {
  return {
    type: SET_ALL_CONFIGURATIONS,
    payload: configurations,
  };
};

export const setSingleConfiguration = (id, configuration) => {
  return {
    type: SET_SINGLE_CONFIGURATION,
    payload: {
      id,
      configuration,
    },
  };
};

export const setVisited = (componentName) => {
  return {
    type: SET_VISITED,
    payload: componentName,
  };
};

export const setAllowedStep = (step) => {
  return {
    type: SET_ALLOWED_STEP,
    payload: step,
  };
};

export const resetAllowedSteps = () => {
  return {
    type: RESET_ALLOWED_STEPS,
  };
};

export const setAllOrders = (orders) => {
  return {
    type: SET_ALL_ORDERS,
    payload: orders,
  };
};

export const setSpecificationGroupPerModel = (
  brand,
  model,
  specificationGroups
) => {
  return {
    type: SET_SPECIFICATION_GROUP_PER_MODEL,
    payload: {
      brand,
      model,
      specificationGroups,
    },
  };
};

export const resetChosenOptions = () => {
  return {
    type: RESET_CHOSEN_OPTIONS,
  };
};

export const resetChosenProducts = () => {
  return {
    type: RESET_CHOSEN_PRODUCTS,
  };
};

export const resetAllProducts = () => {
  return {
    type: RESET_ALL_PRODUCTS,
  };
};

export const resetChosenPackProducts = () => {
  return {
    type: RESET_CHOSEN_PACK_PRODUCTS,
  };
};

export const setPhotoGalleryItems = (photoGallery) => {
  return {
    type: SET_PHOTO_GALLERY_ITEMS,
    payload: photoGallery,
  };
};

export const addOrderItem = (configuration) => {
  return {
    type: ADD_ORDER_ITEM,
    payload: configuration,
  };
};

export const removeOrderItem = (configurationName) => {
  return {
    type: REMOVE_ORDER_ITEM,
    payload: configurationName,
  };
};

export const removeOrderItems = () => {
  return {
    type: REMOVE_ORDER_ITEMS
  };
};

export const setOrderItemAmount = (configurationName, amount) => {
  return {
    type: SET_ORDER_ITEM_AMOUNT,
    payload: {
      name: configurationName,
      amount
    },
  };
};

export const setOrderItemReference = (configurationName, reference) => {
  return {
    type: SET_ORDER_ITEM_REFERENCE,
    payload: {
      name: configurationName,
      reference
    },
  };
};