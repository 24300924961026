import React, { useState } from "react";
import { Formik, Field, Form } from 'formik';
import { Button, Input } from '../_General';
import { Trans } from '@lingui/macro';
import { I18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { setLanguage } from "../../redux/action-creators";
import axios from "axios";
import qs from "qs";
import Logo from "../../assets/logo.svg";


import "./ResetPassword.scss"


const ResetPassword = ({ setLanguage, match, location, history }) => {

    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })
    setLanguage(queryStrings.lang);

    const [isSuccessful, setIsSuccessful] = useState(false);

    const handlePasswordReset = (values, setSubmitting) => {
        const data = {
            password: values.password,
            confirm_password: values.confirmPassword
          };
      
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/hebic/json/configurator-password-reset/${match.params.uuid}`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            data: qs.stringify(data),
        })
        .then(() => {
            setSubmitting(false);
            setIsSuccessful(true);
            setTimeout(() => history.push('/login'), 5000);
        })
        .catch(() => {
            setSubmitting(false);
        });
    }

    const handleValidate = (values) => {
        const errors = {};
        if (!values.password) {
          errors.password = "Required";
        }
        if (!values.confirmPassword) {
            errors.confirmPassword = "Required";
        }
        if (values.password !== values.confirmPassword) {
            errors.matchingPassword = "Required";
        }
        if (values.password.length < 8) {
            errors.passwordLength = "Required";
        }
        return errors;
    };

    return (
        <I18n>
            {({ i18n }) => (
                <div className="password-reset-form">
                    <Formik
                        initialValues={{ 
                            password: "", 
                            confirmPassword: "" 
                        }}
                        validate={(values) => handleValidate(values)}
                        validateOnBlur={false}
                        validateOnChange={false}
                        onSubmit={(values, { setSubmitting }) => {
                            handlePasswordReset(values, setSubmitting)
                        }}
                    >
                        {({ isSubmitting, touched, errors, validateForm }) => (
                        <Form>
                            <Link className="logo" to="/">
                                <img src={Logo} alt="" />
                            </Link>
                            <h1><Trans>Choose new password</Trans></h1>
                            <Field
                            label={i18n._(t`Password`)}
                            type="password"
                            name="password"
                            component={Input}
                            error={touched.password && errors.password}
                            disabled={isSuccessful}
                            comment={i18n._(t`Has to be at least 8 characters long.`)}
                            />
                            <Field
                            label={i18n._(t`Confirm password`)}
                            type="password"
                            name="confirmPassword"
                            component={Input}
                            error={touched.confirmPassword && errors.confirmPassword}
                            disabled={isSuccessful}
                            />
                            {errors.matchingPassword && 
                                <div>
                                    <p class="password-reset-form-error"><Trans>Passwords have to match</Trans>.</p>
                                </div>
                            }
                            {errors.passwordLength && 
                                <div>
                                    <p class="password-reset-form-error"><Trans>Password has to be at least 8 characters long</Trans>.</p>
                                </div>
                            }
                             {isSuccessful &&            
                                <div className="password-reset-form-success">
                                    <p><Trans>Your password has been reset. You will be redirected to the login page in 5 seconds.</Trans>.</p>
                                    <Link to="/login"><Trans>Go to login page</Trans></Link>
                                </div>
                            }
                            {!isSuccessful &&            
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    loader={isSubmitting}
                                    size="s"
                                >
                                    <Trans>Reset password</Trans>
                                </Button>
                            }
                        </Form>
                        )}
                    </Formik>
                </div>
            )}
        </I18n>
    )
}

  export default connect(null, { setLanguage })(ResetPassword);
  