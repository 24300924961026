import React from "react";
import { useField } from "formik";
import { Select } from "../../../_General";
import { I18n } from "@lingui/react";
import { t } from "@lingui/macro";

const LengthOption = ({ options, setFieldValue, setFieldTouched, ...props }) => {

  const [{ name }, meta] = useField(props);

  if (!options) {
    return null;
  }
  const lengthOptions = Object.values(options);
  if (lengthOptions.length === 1) {
    if(meta.value === "" || meta.value.id !== lengthOptions[0].id) {
      setFieldValue(name, lengthOptions[0]);
    }
    return null;
  }

  const customOnChange = (chosenOption) => {
    setFieldValue(name, chosenOption);
  }

  const customOnBlur = () => {
    setFieldTouched(name);
  }

  return (
    <I18n>
      {({ i18n }) => (
        <div
          className={`length-option ${meta.value !== "" &&
            meta.value.hoogte_opties?.length > 1 &&
            "length-option--active"}`}>
          <Select
            placeholder={i18n._(t`Length`)}
            defaultValue={meta.value}
            options={lengthOptions}
            onChangeHook={(chosenOption) => customOnChange(chosenOption)}
            onBlurHook={() => customOnBlur()}
            error={meta.touched && meta.error}
          ></Select>
        </div>
      )}
    </I18n>
  );
};

export default LengthOption;
