import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { t } from "@lingui/macro";
import axios from "axios";
import { Trans } from "@lingui/macro";
import { Input, Button } from "../_General";
import { I18n } from "@lingui/react";

import "./ResetForm.scss";

const ResetForm = ({ setIsPasswordReset }) => {
    const [isSuccessful, setIsSuccessful] = useState(false);

    const handlePasswordReset = (values, setSubmitting) => {
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/hebic/json/configurator-password-reset`,
            data: { email: values.email }
        })
        .then(() => {
            setSubmitting(false);
            setIsSuccessful(true);
        })
        .catch(() => {
            setSubmitting(false);
        });
    }

    const handleValidate = (values) => {
        const errors = {};
        if (!values.email) {
          errors.email = "Required";
        }
        return errors;
    };

    return (
        <I18n>
            {({ i18n }) => (
                <div className="password__reset__form">
                    <Formik
                        initialValues={{ email: ""}}
                        validate={(values) => handleValidate(values)}
                        onSubmit={(values, { setSubmitting }) => handlePasswordReset(values, setSubmitting)}
                    >
                        {({ isSubmitting, touched, errors, values }) => (
                        <Form>
                            <Field
                            label={i18n._(t`Email`)}
                            type="email"
                            name="email"
                            component={Input}
                            error={touched.email && errors.email}
                            />
                            {isSuccessful &&            
                                <div className="password__reset__form__success">
                                    <Trans>We have sent an email to</Trans>: {values.email}
                                </div>
                            }
                            <div className="password__reset__form__actions">
                            <button type="button" className="password__reset__form__actions__go-back" onClick={() => setIsPasswordReset(false)}>
                                <Trans>Go back to login</Trans>
                            </button>
                            <Button
                                type="submit"
                                disabled={isSubmitting}
                                loader={isSubmitting}
                                size="s"
                            >
                                <Trans>Reset password</Trans>
                            </Button>
                            </div>
                        </Form>
                        )}
                    </Formik>
                </div>
            )}
        </I18n>
    );
}

export default ResetForm;