import { combineReducers } from "redux";
import {
  allClients,
  allProducts,
  allConfigurations,
  currentClient,
  carModelOptions,
  loginDetails,
  language,
  currentConfigurationStep,
  currentConfig,
  isChosen,
  visitedComponents,
  allowedSteps,
  allOrders,
  photoGallery,
  orderItems
} from "./reducers";

export default combineReducers({
  allClients,
  allProducts,
  allConfigurations,
  currentClient,
  carModelOptions,
  loginDetails,
  language,
  currentConfigurationStep,
  currentConfig,
  isChosen,
  visitedComponents,
  allowedSteps,
  allOrders,
  photoGallery,
  orderItems
});
