import { useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import qs from "qs";
import { setTokens } from "../../redux/action-creators";

let refreshTokenPromise = null;
let accessTokenTest = '';

export const useFetch = () => {
  const accessToken = useSelector(state => state.loginDetails.accessToken);
  const refreshToken = useSelector(state => state.loginDetails.refreshToken);
  const language = useSelector(state => state.language);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  async function fetchUrl(fetchSettings = { method: "", url: "", data: {}, result: "" }) {

    const generalAxios = axios.create();

    generalAxios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if (error.config && error.response && error.response.status === 401) {
        if (!refreshTokenPromise) {

            const data = {
              grant_type: "refresh_token",
              refresh_token: refreshToken,
              client_id: `${process.env.REACT_APP_OAUTH_CLIENT_ID}`,
              client_secret: `${process.env.REACT_APP_OAUTH_CLIENT_SECRET}`
            };

            refreshTokenPromise = axios({
              method: "POST",
              url: `${process.env.REACT_APP_API_URL}/oauth/token`,
              headers: {
                "Content-Type": "application/x-www-form-urlencoded"
              },
              data: qs.stringify(data)
            })
              .then(res => {
                accessTokenTest = res.data.access_token;
                dispatch(setTokens(res.data.access_token, res.data.refresh_token));
                refreshTokenPromise = null;
              })
              .catch(() => {
                window.localStorage.removeItem("persist:root");
                if (window.localStorage.getItem("persist:root") === null) {
                  window.location.assign("/");
                }
              })
        }
        return refreshTokenPromise.then(() => {
          error.config.headers.Authorization = `Bearer ${accessTokenTest}`
          return generalAxios.request(error.config);
        })

      }
      return Promise.reject(error);
    });

    setLoading(true);
    generalAxios({
      method: fetchSettings.method,
      url: `${process.env.REACT_APP_API_URL}/${language}/${fetchSettings.url}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      },
      data: fetchSettings.data && JSON.stringify(fetchSettings.data)
    })
      .then(res => {
        fetchSettings.result && fetchSettings.result(res);
      })
      .catch(err => {
        fetchSettings.error && fetchSettings.error(err);
      })
      .finally(() => {
        return setLoading(false);
      })
  }
  return [{ loading }, fetchUrl];

};