import React from "react";
import { connect } from "react-redux";
import "./InputCheckbox.scss";
import { Fade } from "../Fade/Fade";

const InputCheckbox = ({ pack, onChange, chosenPack, checked }) => {
  return (
    <Fade classNames="fade-from-bottom">
      <div className="input-group__radio-wrapper">
        <label className="input-group__radio-label" htmlFor={pack.id}>
          <input
            className="input-group__radio-input"
            type="checkbox"
            id={pack.id}
            name="pack"
            value={pack.id}
            checked={checked || chosenPack.id === pack.id}
            onChange={onChange}
          />
          <span className="input-group__checkmark"></span>
          <div className="input-group__radio-content">
            <span className="input-group__radio-content__pack-type">
              <div
                key={pack.categorie_id}
              >{`${pack.categorie_id} ${pack.materiaal_id}`}</div>
            </span>
          </div>
        </label>
      </div>
    </Fade>
  );
};

const mapStateToProps = (state) => {
  return {
    chosenPack: state.currentConfig.pack,
  };
};

export default connect(mapStateToProps)(InputCheckbox);
