import React, { Component } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Gallery.scss";

class AsNavFor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      infoIsOpen: false,
      height: 0,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  showRelevantModels(image) {
    const { photoGallery } = this.props;

    const relevantModels = [];
    for (const model of photoGallery.parameters.model_options) {
      if (model.modelgroup_id === image.modelgroup_id) {
        relevantModels.push(model.label);
      }
    }

    return relevantModels.map((model, index) => {
      if (!relevantModels[index + 1]) {
        return `${model}`;
      } else {
        return `${model}, `;
      }
    });
  }

  render() {
    const { images } = this.props;

    const settings = {
      asNavFor: this.state.nav1,
      ref: (slider) => (this.slider2 = slider),
      swipeToSlide: true,

      prevArrow: (
        <button
          class="slick-prev slick-arrow"
          aria-label="Previous"
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.57"
            height="43.28"
            viewBox="0 0 19.57 43.28"
          >
            <path
              id="Path"
              d="M0,0,17.5,21,0,42"
              transform="translate(0.768 0.64)"
              stroke="#fff"
              fill="transparent"
              stroke-miterlimit="10"
              stroke-width="2"
            />
          </svg>
        </button>
      ),
      nextArrow: (
        <button
          class="slick-next slick-arrow"
          aria-label="Previous"
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.57"
            height="43.28"
            viewBox="0 0 19.57 43.28"
          >
            <path
              id="Path"
              d="M0,0,17.5,21,0,42"
              transform="translate(0.768 0.64)"
              stroke="#fff"
              fill="transparent"
              stroke-miterlimit="10"
              stroke-width="2"
            />
          </svg>
        </button>
      ),
      responsive: [
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };

    return (
      <div className="gallery">
        <Slider
          asNavFor={this.state.nav2}
          ref={(slider) => (this.slider1 = slider)}
          {...settings}
        >
          {images.map((image) => {
            return (
              <div className="gallery__wrapper" key={image.image}>
                <div className="gallery__extra__wrapper">
                  <div
                    className="gallery__item"
                    style={{
                      backgroundImage: `url(${image.image})`,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                    alt=""
                  ></div>
                </div>
                <div className="info">
                  {image.title && <h3>{image.title}</h3>}
                  <span className="semibold">Description</span>
                  <div className="info-wrapper">
                    <div className="info-left">
                      <span>Model</span>
                      {image.categorie_materiaal_captions.map((caption) => {
                        return (
                          <span key={image.image}>
                            {caption.base_categorie_label}
                          </span>
                        );
                      })}
                    </div>
                    <div className="info-right">
                      <span>{this.showRelevantModels(image)}</span>
                      <span>
                        {image.categorie_materiaal_captions.map((caption) => {
                          return (
                            <span key={image.image}>
                              {caption.materiaal_label}
                            </span>
                          );
                        })}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    photoGallery: state.photoGallery,
  };
};

export default connect(mapStateToProps)(AsNavFor);
