import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AnimateHeight from "react-animate-height";
import { Field } from "formik";
import { I18n } from "@lingui/react";
import { t } from "@lingui/macro";

import { setProductCategoryStatus } from "../../../../redux/action-creators";
import { ProductOptionCheckbox, Fade, SpecificationOptionGroup } from "../../../_General";
import StepProductsOptionCopy from "./StepProductsOptionCopy";
import StepProductsOptionRadio from "./StepProductsOptionRadio";
import { findProductItemByCategoryIdAndMaterialId } from "../../../Utilities/findProductItem";
import { initializeProductItemSpecificationsAndRemainingSpecificationOptionGroups } from "../../../Utilities/initializeProductItem";
import StepProductsOptionCheckbox from "./StepProductsOptionCheckbox";
import { buildArticleNumber } from "../../../Utilities/buildArticleNumber";

const StepProductsOption = ({
  allProducts: {
    product_items: productItems
  },
  productOption: { 
    id: categoryId, 
    label, 
    productparameter_opties 
  },
  isChosen,
  setProductCategoryStatus,
  form,
  currentConfig: {
    products: chosenProducts
  }
}) => {
  // On mount we check if there was already a 
  // product item chosen for this product option in the redux store.
  useEffect(() => {
    let productItem;
    if (chosenProducts[categoryId] && Object.keys(chosenProducts[categoryId]).length > 0) {
      productItem = chosenProducts[categoryId];
    } else {
      // Using this logic as a fallback.
      const materialId = productparameter_opties[0].keuze_opties[0].value;
      productItem = findProductItemByCategoryIdAndMaterialId(categoryId, materialId, productItems);
    }
    // Using this logic as a fallback.
    if (productItem && Object.keys(productItem).length > 0 && !productItem.specifications) {
      initializeProductItemSpecificationsAndRemainingSpecificationOptionGroups(productItem);
    }
    setProductItem(productItem);
    const articleNumber = buildArticleNumber(productItem);
    setArticleNumber(articleNumber);
  }, [chosenProducts])

  const [articleNumber, setArticleNumber] = useState();
  const [productItem, setProductItem] = useState();

  /**
   * Adds an initial product item to the form products state.
   */
  const handleProductOptionSelect = (e) => {
    e.persist();
    
    const categoryId = e.target.value;
    const materialId = productparameter_opties[0].keuze_opties[0].value;

    const productItem = findProductItemByCategoryIdAndMaterialId(categoryId, materialId, productItems)

    if (e.target.checked) {
      // Also set the default specification for the product item.
      initializeProductItemSpecificationsAndRemainingSpecificationOptionGroups(productItem);
      setProductItem(productItem);
      const articleNumber = buildArticleNumber(productItem);
      setArticleNumber(articleNumber);

      form.setFieldValue("products", {
        ...form.values.products,
        [categoryId]: {...productItem},
      });
    }
    if (!e.target.checked) {
      setProductItem(null);

      delete form.values.products[categoryId];
      form.setFieldValue("products", form.values.products);
    }
    setProductCategoryStatus(categoryId);
  }

  /**
   * Change the selected product item for the product option
   * to the new product item that matches the newly select material option.
   */
  const handleMaterialOptionSelect = (e) => {
    e.persist();
    const materialId = e.target.value;

    const productItem = findProductItemByCategoryIdAndMaterialId(categoryId, materialId, productItems);

    initializeProductItemSpecificationsAndRemainingSpecificationOptionGroups(productItem);
    setProductItem(productItem);
    const articleNumber = buildArticleNumber(productItem);
    setArticleNumber(articleNumber);

    form.setFieldValue("products", {
      ...form.values.products,
      [categoryId]: productItem
    });
  }

  /**
   * Set the chosen specification on the form 
   * products state for the relevant product item.
   */
  const handleSpecificationOptionSelect = (e, specificationOptionGroup) => {

    
    form.setFieldValue("products", {
      ...form.values.products,
      [categoryId]: {
        ...form.values.products[categoryId],
        [form.values.products[categoryId].specifications]: {
          ...form.values.products[categoryId].specifications,
          ...form.values.products[categoryId].specifications[specificationOptionGroup.type] = e
        }
      }
    });
  }

  /**
   * Check if this product option has a product that is available separately.
   * If there isn't, then we wouldn't want to show this component at all.
   */
  const isSeparatelyAvailable = () => {
    const materials = productparameter_opties[0].keuze_opties;
    
    let productItem;
    for (const material of materials) {
      productItem = findProductItemByCategoryIdAndMaterialId(categoryId, material.value, productItems);
      if (productItem) {
        break;
      }
    }
    if (!productItem) {
      return false;
    }
    return productItem.meta.isSeparatelyAvailable;
  }

  if (!isSeparatelyAvailable()) {
    return null;
  } else {
    return (
      <I18n>
        {({ i18n }) => (
          <Fade classNames="fade-in">
            <div className="step-products__left__products__wrapper">
              <div
                className={`step-products__left__products__product ${isChosen[categoryId] &&
                  `step-products__left__products__product--active`}`}>
                <ProductOptionCheckbox
                  name={`products.${categoryId}`}
                  customChecked={isChosen[categoryId]}
                  label={label}
                  value={categoryId}
                  customOnChange={(e) => handleProductOptionSelect(e)}
                ></ProductOptionCheckbox>
              </div>
              <AnimateHeight duration={200} height={isChosen[categoryId] ? "auto" : 0}>
                {isChosen[categoryId] && (
                  <div
                    className={`step-products__left__products__product-detail ${isChosen &&
                      `step-products__left__products__product--active`}`}>
                    {productItem && productItem.artikelnummer_klant && (
                      <Field
                        component={StepProductsOptionCopy}
                        label={i18n._(t`Your article no.`)}
                        id={categoryId}
                        value={productItem.artikelnummer_klant}
                      />
                    )}
                    <Field
                      component={StepProductsOptionCopy}
                      label={i18n._(t`Hebic article no.`)}
                      id={categoryId}
                      value={articleNumber}
                    />
                    {productparameter_opties[0].keuze_opties.length > 0 && (
                      <Field
                        component={StepProductsOptionRadio}
                        name="products"
                        materials={productparameter_opties[0].keuze_opties}
                        categoryId={categoryId}
                        onChange={(e) => handleMaterialOptionSelect(e)}
                      />
                    )}
                    {productItem && productItem.remainingSpecificationOptionGroups.map(specificationOptionGroup => {
                      return <SpecificationOptionGroup
                        name={`products.${categoryId}.specifications[${specificationOptionGroup.type}]`}
                        key={specificationOptionGroup.type}
                        specificationOptionGroup={specificationOptionGroup}
                        form={form}
                        categoryId={categoryId}
                        chosenSpecifications={productItem.specifications}
                        customOnChange={(e) => handleSpecificationOptionSelect(e, specificationOptionGroup)}
                      ></SpecificationOptionGroup>
                    })}
                    {productItem && productItem.meta.isAssemblable && (
                      <StepProductsOptionCheckbox
                        label={i18n._(t`With assembly`)}
                        name={`products.${categoryId}.withAssembly`}
                      ></StepProductsOptionCheckbox>
                    )}
                  </div>
                )}
              </AnimateHeight>
            </div>
          </Fade>
        )}
      </I18n>
    );
  }
};

const mapStateToProps = state => {
  return {
    allProducts: state.allProducts,
    currentConfig: state.currentConfig,
    isChosen: state.isChosen
  };
};

export default connect(mapStateToProps, { setProductCategoryStatus })(StepProductsOption);
