import React from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { ReactComponent as ShoppingCart } from "../../../assets/shopping-cart.svg";

import "./CartLink.scss";

export let CartLink = ({ orderItems }) => (
  <Link
    className="cart-link"
    to="/cart"
  >
    <ShoppingCart></ShoppingCart>
    <span className="cart-link__tag">{ orderItems.length }</span>
  </Link>
);

const mapStateToProps = state => {
  return {
    orderItems: state.orderItems,
  };
};

CartLink = connect(mapStateToProps)(CartLink);