import React, { useState, useEffect } from "react";
import { useFetch } from "../../../Utilities/useFetch";
import { connect } from "react-redux";
import AnimateHeight from "react-animate-height";

import { Fade } from "../../../_General";
import { setModelOptions, setChosenBrand, setVisited, resetModelOptions } from "../../../../redux/action-creators";
import ConfiguratorModel from "./ConfiguratorModel";
import Arrow from "../../../../assets/arrow-down.svg";

const ConfiguratorModelChoice = ({
  brand,
  brand: {
    id: brandId
  },
  chosenBrandId,
  setModelOptions,
  setChosenBrand,
  setVisited,
  setIsLoading,
  carModelOptions,
  visitedComponents,
  language
}) => {
  const [isModelChosen, setIsModelChosen] = useState(false);
  const [{}, doFetch] = useFetch();
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count > 0) {
      fetchCarModelOptions();
    }
  }, [language])

  useEffect(() => {
    fetchCarModelOptions();
    setCount(count + 1);
    return () => {
      setVisited("model");
    };
  }, []);

  const fetchCarModelOptions = () => {
    doFetch({
      method: "GET",
      url: `hebic/json/merk/${brandId}/automodel-opties`,
      result: res => {
        const carModels = {};
        for (let option of res.data) {
          const newOptions = option.lengte_opties.reduce((acc, curr) => {
            acc[curr.id] = curr;
            return acc;
          }, {});

          carModels[option.model_id] = {
            ...option,
            lengte_opties: newOptions
          };
        }
        setModelOptions(carModels, brandId);
      }
    });
  };

  const toggle = () => {
    setIsModelChosen(!isModelChosen);
    if (chosenBrandId === brandId) {
      return setChosenBrand("");
    }
    setChosenBrand(brandId);
  };

  const hasCarModelOptions = () => {
    return carModelOptions[brandId] && Object.values(carModelOptions[brandId]).length > 0;
  }

  if (!hasCarModelOptions()) {
    return null;
  } else {
    setIsLoading(false);
    return (
      <div>
        <ModelChoiceButton
          brand={brand}
          chosenBrandId={chosenBrandId}
          toggle={toggle}
          visitedComponents={visitedComponents}
        />
        <AnimateHeight duration={200} height={chosenBrandId === brandId ? "auto" : 0}>
          <div
            // Set flex as an inline style, because animation library is preventing the class rules to be used somehow.
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginBottom: "4rem",
              maxWidth: "115rem"
            }}
            className="'configurator__content__model-choice__models">
            {carModelOptions &&
              carModelOptions[brandId] &&
              Object.values(carModelOptions[brandId]).filter((model) => model.model_id && model.lengte_opties && model.hoogte_opties).map(model => {
                return (
                  <ConfiguratorModel
                    key={model.model_id}
                    model={model}
                    brand={brand}
                  />
                );
              })}
          </div>
        </AnimateHeight>
      </div>
    );
  }
};

const ModelChoiceButton = ({ 
  brand: {
    id: brandId,
    label: brandLabel,
    image: brandImage
  },
  chosenBrandId,
  toggle, 
  visitedComponents 
}) => {
  return (
    <Fade classNames="fade-from-bottom">
      <div
        className={`configurator__content__model-choice ${!visitedComponents.model &&
          "configurator__content__model-choice__should-animate"} `}
        style={{
          marginBottom: `${chosenBrandId === brandId ? "2rem" : "4rem"}`
        }}
        onClick={toggle}>
        <div className="configurator__content__model-choice__left">
          <img className="configurator__content__model-choice__left__logo" src={brandImage} alt="car logo" />
          <span>{brandLabel}</span>
        </div>
        <img
          className={`configurator__content__model-choice__left__arrow ${chosenBrandId === brandId &&
            `configurator__content__model-choice__left__arrow--active`}`}
          src={Arrow}
          alt=""
        />
      </div>
    </Fade>
  );
};

const mapStateToProps = state => {
  return {
    carModelOptions: state.carModelOptions,
    chosenBrandId: state.currentConfig.brand,
    visitedComponents: state.visitedComponents,
    language: state.language
  };
};

export default connect(mapStateToProps, {
  setModelOptions,
  resetModelOptions,
  setChosenBrand,
  setVisited
})(ConfiguratorModelChoice);
