import React from "react";
import { connect } from "react-redux";
import { Trans } from "@lingui/macro";
import "./Help.scss";

const Help = ({ currentLanguage }) => {
  return (
    <div className="help">
      <span className="help__title">
        <Trans>Need help?</Trans>
      </span>
      <div className="help__details">
        <span>{`+32 (0)3 888 21 31`}</span>
        <span>info@hebic.be</span>
      </div>
      <a href={`https://www.hebic.be/${currentLanguage}/node/12`} target="_blank" rel="noopener noreferrer">
        <Trans>Check out support page</Trans>
      </a>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentLanguage: state.language,
  };
};

export const ConnectedHelp = connect(mapStateToProps)(Help);