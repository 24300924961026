import React, { useState } from "react";
import { Button, InputRadio } from "../../_General";
import { Trans } from "@lingui/macro";
import { I18n } from "@lingui/react";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import ChangeCompanyDetailsModal from "./ChangeCompanyDetailsModal";

const AccountCompanyDetails = ({ currentClient, allClients, history }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <I18n>
      {() => (
        <div className="account__content__company-details">
          <Formik
            initialValues={{
              company: "",
              delivery_address: currentClient.adressen[0].id
            }}
            >
            {({ setFieldValue, values }) => (
              <Form>
                <h3>
                  <Trans>Company details</Trans>
                </h3>
                <div className="account__content__company-details__facturation-details">
                  <div/>
                  <h4>
                    <Trans>Facturation details</Trans>
                  </h4>
                  <div className="account__content__company-details__address">
                    <span>
                      <strong>
                        {currentClient.label} {currentClient.adressen[0].gemeente}
                      </strong>
                    </span>
                    <span>{currentClient.adressen[0].adres}</span>
                    <span>
                      {currentClient.adressen[0].postcode} {currentClient.adressen[0].gemeente}
                    </span>
                  </div>
                  {allClients.klanten.length > 1 && (
                      <Button size="lg" color="grey" onClick={() => history.push("/client")}>
                        <Trans>Change company</Trans>
                      </Button>
                  )}
                </div>
                <div className="account__content__company-details__delivery">
                  <h4>
                    <Trans>Delivery address</Trans>
                  </h4>
                  <div className="account__content__company-details__delivery__options">
                    {currentClient.adressen.map(address => {
                      return (
                        <Field
                          key={address.id}
                          as={InputRadio}
                          name="delivery_address"
                          id={address.id === null ? currentClient.id : address.id}
                          setFieldValue={setFieldValue}
                          business={{
                            branch: `${address.label} ${address.gemeente}`,
                            street: address.adres,
                            postalCode: address.postcode,
                            city: address.gemeente
                          }}
                          checked={values.delivery_address === address.id}
                        />
                      );
                    })}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <Button size="lg" color="grey" onClick={() => setIsModalOpen(!isModalOpen)}>
            <Trans>Change company details</Trans>
          </Button>
          <ChangeCompanyDetailsModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </div>
      )}
    </I18n>
  );
};

const mapStateToProps = state => {
  return {
    allClients: state.allClients,
    currentClient: state.currentClient
  };
};

export default connect(mapStateToProps)(AccountCompanyDetails);
