import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { I18nProvider } from "@lingui/react";
import { connect } from "react-redux";
import {
  Provider as KeepAliveProvider,
} from 'react-keep-alive';

import Login from "./Login";
import Platform from "../Authorized/Platform/Platform";
import PrivateRoute from "../_General/PrivateRoute/PrivateRoute";
import "./App.scss";
import "../../index.css";
import ChooseClient from "./ChooseClient";
import ResetPassword from "./ResetPassword";

const App = ({ store, persistor, catalogs, currentLanguage }) => {
  return (
    <I18nProvider language={currentLanguage} catalogs={catalogs}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <KeepAliveProvider>
              <Switch>
                <Route path="/login" component={Login} exact />
                <Route path="/login/:uuid" component={ResetPassword} />
                <PrivateRoute path="/client" component={ChooseClient} />
                <PrivateRoute path="/" component={Platform} />
              </Switch>
            </KeepAliveProvider>
          </Router>
        </PersistGate>
      </Provider>
    </I18nProvider>
  );
};

export default connect((state) => {
  return { currentLanguage: state.language };
})(App);
