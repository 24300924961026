import React from "react";
import { I18n } from "@lingui/react";
import { t } from "@lingui/macro";
import MenuItem from "./PlatformMenuItem";
import { ReactComponent as ShoppingCart } from '../../../assets/shopping-cart.svg'

const PlatformMenu = ({ isMenuOpen, setIsMenuOpen }) => {
  return (
    <I18n>
      {({ i18n }) => (
        <nav className="platform__sidebar__menu" onClick={() => setIsMenuOpen && setIsMenuOpen(!isMenuOpen)}>
          <MenuItem
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 56 56">
                <defs>
                  <clipPath id="clip-path">
                    <rect width="56" height="56" fill="none" />
                  </clipPath>
                </defs>
                <g id="icon_grey_configuration" data-name="icon / grey / configuration" clipPath="url(#clip-path)">
                  <g id="icon_grey_cross" data-name="icon / grey / cross">
                    <rect id="base" width="56" height="56" fill="none" />
                    <path
                      id="icon"
                      d="M3.565,56A3.537,3.537,0,0,1,0,52.5V3.5A3.537,3.537,0,0,1,3.565,0H23.174L41,17.5v35A3.537,3.537,0,0,1,37.435,56Zm0-3.5h33.87V22.75H17.826V3.5H3.565ZM21.391,19.25H37.435v-.3L21.7,3.5h-.307ZM10.743,44.114H8.616a1.613,1.613,0,1,1,0-3.226h2.127a4.839,4.839,0,0,1,9.13,0H32.385a1.613,1.613,0,1,1,0,3.226H19.872a4.839,4.839,0,0,1-9.129,0Zm6.462-10.923H8.616a1.612,1.612,0,1,1,0-3.225H17.2a4.839,4.839,0,0,1,9.131,0h6.05a1.612,1.612,0,1,1,0,3.225H26.333a4.838,4.838,0,0,1-9.128,0Z"
                      transform="translate(8)"
                      fill="#778594"
                    />
                  </g>
                </g>
              </svg>
            }
            path="/configurations"
            name={i18n._(t`My configurations`)}
          />
          <MenuItem
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 56 56">
                <defs>
                  <clipPath id="clip-path">
                    <rect width="56" height="56" fill="none" />
                  </clipPath>
                </defs>
                <g id="icon_grey_configurator" data-name="icon / grey / configurator" clipPath="url(#clip-path)">
                  <g id="icon_grey_configurator-2" data-name="icon / grey / configurator">
                    <rect id="base" width="56" height="56" fill="none" />
                    <path
                      id="icon"
                      d="M51.692,56H4.307A4.312,4.312,0,0,1,0,51.692V4.307A4.312,4.312,0,0,1,4.307,0H51.692A4.312,4.312,0,0,1,56,4.307V51.692A4.312,4.312,0,0,1,51.692,56ZM4.307,3.231A1.078,1.078,0,0,0,3.231,4.307V51.692a1.078,1.078,0,0,0,1.076,1.076H51.692a1.078,1.078,0,0,0,1.076-1.076V4.307a1.078,1.078,0,0,0-1.076-1.076ZM40.925,45.77a1.616,1.616,0,0,1-1.613-1.616V26.1a4.839,4.839,0,0,1,0-9.131V11.846a1.613,1.613,0,1,1,3.226,0v5.128a4.839,4.839,0,0,1,0,9.129V44.154A1.616,1.616,0,0,1,40.925,45.77ZM28,45.77a1.616,1.616,0,0,1-1.613-1.616V39.027a4.839,4.839,0,0,1,0-9.13V11.846a1.613,1.613,0,1,1,3.226,0V29.9a4.839,4.839,0,0,1,0,9.129v5.128A1.616,1.616,0,0,1,28,45.77Zm-12.923,0a1.616,1.616,0,0,1-1.613-1.616V32.566a4.84,4.84,0,0,1,0-9.131V11.846a1.613,1.613,0,1,1,3.226,0v11.59a4.838,4.838,0,0,1,0,9.128v11.59A1.616,1.616,0,0,1,15.079,45.77Z"
                      fill="#778594"
                    />
                  </g>
                </g>
              </svg>
            }
            path={"/configurator/model"}
            name={i18n._(t`Configurator`)}
          />
          <MenuItem
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 56 56">
                <defs>
                  <clipPath id="clip-path">
                    <rect width="56" height="56" fill="none" />
                  </clipPath>
                </defs>
                <g id="icon_grey_document" data-name="icon / grey / document" clipPath="url(#clip-path)">
                  <g id="icon_grey_document-2" data-name="icon / grey / document">
                    <rect id="base" width="56" height="56" fill="none" />
                    <path
                      id="icon"
                      d="M3.565,56A3.537,3.537,0,0,1,0,52.5V3.5A3.537,3.537,0,0,1,3.565,0H23.174L41,17.5v35A3.537,3.537,0,0,1,37.435,56Zm0-3.5h33.87V22.75H17.826V3.5H3.565ZM21.391,19.25H37.435v-.3L21.7,3.5h-.307ZM11.111,45.23a1.615,1.615,0,1,1,0-3.23H29.458a1.615,1.615,0,1,1,0,3.23Zm0-6.461a1.616,1.616,0,0,1,0-3.231H29.458a1.616,1.616,0,0,1,0,3.231Zm0-6.462a1.615,1.615,0,1,1,0-3.23H29.458a1.615,1.615,0,1,1,0,3.23Z"
                      transform="translate(8)"
                      fill="#778594"
                    />
                  </g>
                </g>
              </svg>
            }
            path="/orders"
            name={i18n._(t`Orders`)}
          />
          <MenuItem
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 56 56">
                <defs>
                  <clipPath id="clip-path">
                    <rect width="56" height="56" fill="none" />
                  </clipPath>
                </defs>
                <g id="icon_grey_account" data-name="icon / grey / account" clipPath="url(#clip-path)">
                  <g id="icon_grey_account-2" data-name="icon / grey / account">
                    <rect id="base" width="56" height="56" fill="none" />
                    <path
                      id="icon"
                      d="M12.5,25A12.5,12.5,0,0,1,3.661,3.661,12.5,12.5,0,0,1,21.339,21.339,12.418,12.418,0,0,1,12.5,25Zm0-22A9.5,9.5,0,1,0,22,12.5,9.511,9.511,0,0,0,12.5,3Z"
                      transform="translate(16 3)"
                      fill="#778594"
                    />
                    <path
                      id="icon"
                      d="M41,24H1a1,1,0,0,1-1-1V20A19.983,19.983,0,0,1,20,0h2A20,20,0,0,1,42,19.655L42,20v3A1,1,0,0,1,41,24ZM20,3A17,17,0,0,0,3,19.677L3,20a1,1,0,0,0,1,1H38.025A.988.988,0,0,0,39,20.025l0-.319A17,17,0,0,0,22.323,3L22,3Z"
                      transform="translate(7 32)"
                      fill="#778594"
                    />
                  </g>
                </g>
              </svg>
            }
            path="/account"
            name={i18n._(t`My account`)}
          />
        </nav>
      )}
    </I18n>
  );
};

export default PlatformMenu;
