import React from "react";
import "./InputRadio.scss";

export const InputRadio = ({
  id,
  business = {
    branch: undefined,
    street: undefined,
    postalCode: undefined,
    city: undefined
  },
  setFieldValue,
  type,
  form,
  value,
  checked,
  name,
  ...props
}) => {
  return (
    <div className="radio">
      <label className="radio__label" htmlFor={id}>
        <input
          className="radio__input"
          type="radio"
          id={id}
          name={name}
          value={id}
          checked={checked}
          onChange={props.onChange}
        />
        <span className="radio__checkmark"></span>
        {business && (
          <div className="radio__content">
            <strong>{business.branch}</strong>
            <span>{business.street}</span>
            <span>
              {business.postalCode} {business.city}
            </span>
          </div>
        )}
      </label>
    </div>
  );
};
