import React from "react";
import { Trans } from "@lingui/macro";
import "./TextArea.scss";

export const TextArea = ({
  label,
  name,
  className,
  optional,
  required,
  comment,
  placeholder,
  field,
  form,
  type,
  error,
  value,
  disabled,
  ...props
}) => {
  return (
    <div className="input-group">
      <label className={`input-group__label ${className}`} htmlFor={name}>
        {label}
        {optional && (
          <em>
            {"("}
            <Trans>optional</Trans>
            {")"}
          </em>
        )}
        {required && <span>*</span>}
      </label>
      <textarea
        className={`input-group__textarea ${optional &&
          `input-group__textarea--optional`} ${error &&
          `input-group__textarea--error`}`}
        type={type}
        onChange={props.onChange}
        onBlur={props.onBlur}
        id={name}
        {...field}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
      />
      {comment && <span className="input-group__comment">{comment}</span>}
    </div>
  );
};
