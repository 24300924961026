/**
 * Build the Hebic article no. for a product item.
 */
export const buildArticleNumber = (productItem) => {
    if (productItem && 'specifications' in productItem) {
      const specifications = productItem.specifications;

      let articleNumber = `
      ${productItem.artikelnummer}
      ${specifications["Aandrijving"] ? specifications["Aandrijving"].waarde : "0"}
      ${specifications["Deuren vooraan"] ? specifications["Deuren vooraan"].waarde : "0"}${specifications["Deuren achteraan"] ? specifications["Deuren achteraan"].waarde : "0"}
      ${specifications["Afwerking vooraan"] ? specifications["Afwerking vooraan"].waarde : "0"}${specifications["Afwerking achteraan"] ? specifications["Afwerking achteraan"].waarde : "0"}
      ${specifications["Bevestiging vracht"] ? specifications["Bevestiging vracht"].waarde : "0"}
      `;

      return articleNumber;
    }
  };