import React, { useState } from "react";
import { Modal, Input, Fade, Button } from "../../_General";
import { Formik, Form, Field } from "formik";
import { useFetch } from "../../Utilities/useFetch";
import { Trans } from "@lingui/macro";
import { I18n } from "@lingui/react";
import { t } from "@lingui/macro";
import "./DuplicateModal.scss";

export const DuplicateModal = ({
  configuration: { automodel, product_items, naam },
  clientId,
  open,
  onClose,
  history
}) => {
  const [{}, doFetch] = useFetch();
  const [isSuccesful] = useState();

  return (
    <I18n>
    {({ i18n }) => (
      <Modal title={i18n._(t`Duplicate configuration`)} open={open} onClose={onClose}>
        <Formik
          initialValues={{ configurationName: "" }}
          validate={values => {
            const errors = {};
            if (values.configurationName === "") {
              errors.noName = true;
            }
            return errors;
          }}
          onSubmit={values => {
            doFetch({
              method: "POST",
              url: "hebic/json/save-configuratie",
              data: {
                klant_id: clientId,
                naam: values.configurationName,
                automodel: {
                  klant_id: clientId,
                  merk_id: automodel?.merk_id?.toUpperCase(),
                  model_id: automodel?.model_id?.toUpperCase(),
                  hoogte_id: automodel?.hoogte_id,
                  lengte_id: automodel?.lengte_id,
                  aandrijving_id: automodel.aandrijving ? automodel.aandrijving.waarde : "0",
                  deuren_vooraan_id: automodel.deuren_vooraan ? automodel.deuren_vooraan.waarde : "0",
                  deuren_achteraan_id: automodel.deuren_achteraan ? automodel.deuren_achteraan.waarde : "0",
                  afwerking_vooraan_id: automodel.afwerking_vooraan ? automodel.afwerking_vooraan.waarde : "0",
                  afwerking_achteraan_id: automodel.afwerking_achteraan ? automodel.afwerking_achteraan.waarde : "0"
                },
                product_items: product_items,
                aantal: 1
              },
              result: res => {
                if (res.status === 200) {
                  return history.push({
                    pathname: "/configurations",
                    state: {
                      duplicatedConfiguration: naam,
                      newConfiguration: res.data.naam
                    }
                  });
                }
              }
            });
          }}>
          {({ isSubmitting, errors }) => (
            <Form>
              <Field
                label={i18n._(t`Give this configuration a name:`)}
                component={Input}
                type="text"
                name="configurationName"
                placeholder={i18n._(t`Ex. Configuration #12`)}
                error={errors.noName}
              />
              {errors.noName && (
                <Fade classNames="fade-in">
                  <div className="error-notification">
                    <Trans>Please fill in a name for your configuration.</Trans>
                  </div>
                </Fade>
              )}
              <div className="input__button">
                <div>
                  {isSuccesful && (
                    <Fade classNames="fade-in">
                      <div className="notification">
                        <Trans>Your configuration was saved.</Trans>
                      </div>
                    </Fade>
                  )}
                </div>
                <Button size="s" type="submit" disabled={isSubmitting} loader={isSubmitting}>
                  <Trans>Save</Trans>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    )}
    </I18n>
  );
};
