/**
 * Set the default specifications for a product item and 
 * find the specification options that users needs to set manually in UI.
 */
export const initializeProductItemSpecificationsAndRemainingSpecificationOptionGroups = (productItem) => {
  const specifications = {};
  // Specification options that need to be set manually in UI.
  const remainingSpecificationOptionGroups = []

  productItem.specificatie_optie_groepen.forEach(specificationOptionGroup => {
    // Automatically select specification option if only one option is available.
    if (specificationOptionGroup.specificatie_opties.length === 1) {
      specifications[specificationOptionGroup.type] = {
        type: specificationOptionGroup.type,
        waarde: specificationOptionGroup.specificatie_opties[0].waarde,
        label: specificationOptionGroup.specificatie_opties[0].label,
      }
    // If 2 specification options are available, then automatically pick the one
    // with the label 'nul-optie'.  
    } else if (specificationOptionGroup.specificatie_opties.length === 2) {
      if (specificationOptionGroup.specificatie_opties[0].label === "nul-optie") {
        specifications[specificationOptionGroup.type] = {
          type: specificationOptionGroup.type,
          waarde: specificationOptionGroup.specificatie_opties[0].waarde,
          label: specificationOptionGroup.specificatie_opties[0].label,
        }
      } else if (specificationOptionGroup.specificatie_opties[1].label === "nul-optie") {
        specifications[specificationOptionGroup.type] = {
          type: specificationOptionGroup.type,
          waarde: specificationOptionGroup.specificatie_opties[1].waarde,
          label: specificationOptionGroup.specificatie_opties[1].label,
        }
      // Otherwise add the remaining specification that still have to be chosen by the user.    
      } else {
        specifications[specificationOptionGroup.type] = null;
        remainingSpecificationOptionGroups.push(specificationOptionGroup)
      }
    // Otherwise add the remaining specification that still have to be chosen by the user.  
    } else {
      specifications[specificationOptionGroup.type] = null;
      remainingSpecificationOptionGroups.push(specificationOptionGroup)
    }
  })
  productItem.specifications = specifications
  productItem.remainingSpecificationOptionGroups = remainingSpecificationOptionGroups
  return productItem;
}