import React from "react";
import { connect } from "react-redux";
import { Trans } from "@lingui/macro";
import { Fade } from "../index";

import "./Price.scss";

const PriceConnected = ({
  allProducts: {
    assembly_items: assemblyItems
  },
  currentConfig: {
    products: chosenProducts,
    packProducts,
    pack: chosenPack,
    amount
  },
  calculatePrice
}) => {

  const totalPriceCalc = () => {
    let total = 0;

    if (!chosenPack.id) {
      for (let chosenProduct of Object.values(chosenProducts)) {
        total += parseFloat(chosenProduct.prijs);
        if (chosenProduct.withAssembly) {
          total += getAssemblyPrice(chosenProduct);
        }
      }
    } else {
      total += chosenPack.prijs;
      for (const packProduct of Object.values(packProducts)) {
        if (packProduct.withAssembly) {
          total += getAssemblyPrice(packProduct);
        }
      }
    }
    total *= amount;

    return total.toFixed(2);
  };

  /**
   * Gets the assembly price from 
   * the assembly items in redux store.
   */
  const getAssemblyPrice = (product) => {
    const relevantAssemblyItem = assemblyItems.find((item) => {
      if (product.categorie_id === 'WAND' && item.artikelnummer === 'montage wand') {
        return item;
      } else if (product.categorie_id === 'VLOER' && item.artikelnummer === 'montage vloer') {
        return item;
      }
    })
    if (!relevantAssemblyItem) {
      return 0.00;
    }
    return parseFloat(relevantAssemblyItem.prijs);
  }

  return (
    <>
      {chosenProducts || chosenPack.id ? (
        <div className="price-info">
          <Fade classNames="fade-from-bottom">
            <span className="price-info__price">€ {calculatePrice ? calculatePrice() : totalPriceCalc()}</span>
          </Fade>
          <span className="price-info__tax-del">
            <Trans>Exclusive of VAT. Free delivery.</Trans>
          </span>
        </div>
      ) : (
        <Trans>No products have been selected yet.</Trans>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    currentConfig: state.currentConfig,
    allProducts: state.allProducts
  };
};

export const Price = connect(mapStateToProps)(PriceConnected);
