import React from "react";
import { connect } from "react-redux";

const PlatformMenuTop = ({ logo }) => {
  return (
    <div className="platform__sidebar__logo">
      <img
        className="platform__sidebar__logo__image"
        src={logo || "https://via.placeholder.com/180x60.png/FFFFFF/?text=No+Logo+Available"}
        alt="user company logo"
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    logo: state.currentClient.image
  };
};

export default connect(mapStateToProps)(PlatformMenuTop);
