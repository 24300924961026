import React from "react";
import "./ArrowBack.scss";

export const ArrowBack = ({ history }) => {
  return (
    <button className="arrow-back" onClick={() => history.goBack()}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14.571"
        height="13.357"
        viewBox="0 0 14.571 13.357"
        className="arrow-back__image"
      >
        <path
          id="Shape"
          d="M4.464,5.38,7.6,2.18A1.208,1.208,0,0,0,7.6.365a1.423,1.423,0,0,0-1.9,0C5.208.835.395,5.771.395,5.771a1.24,1.24,0,0,0,0,1.815S5.208,12.525,5.7,12.993a1.425,1.425,0,0,0,1.9,0,1.206,1.206,0,0,0,0-1.812l-3.143-3.2H13.3a1.3,1.3,0,0,0,0-2.6Z"
          transform="translate(0 0)"
        />
      </svg>
    </button>
  );
};
