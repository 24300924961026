import React, {useEffect, useState} from "react";
import { Select } from "../index";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import { setLanguage } from "../../../redux/action-creators";

const options = [
  { value: "nl", label: "Nederlands" },
  { value: "fr", label: "Français" },
];

export const Languages = ({ language, setLanguage }) => {
  const [currentLanguage, setCurrentLanguage] = useState()

  const handleDefaultOption = () => {
    const index = options.findIndex((option) => option.value === language);
    return options[index];
  };

  useEffect(() => {
    const chosenLanguage = handleDefaultOption()
    setCurrentLanguage(chosenLanguage)
  }, [language])

  return (
    <Formik
      initialValues={{}}
      validate={(values) => {
        setLanguage(values.language.value);
      }}
    >
      {({ setFieldValue }) => (
        <Form className="languages">
          <Field
            component={Select}
            options={options}
            menuPlacement="top"
            field="language"
            setFieldValue={setFieldValue}
            language
            value={currentLanguage}
          />
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export const ConnectedLanguages = connect(mapStateToProps, {
  setLanguage,
})(Languages);
