import React from "react";

import OrdersRowInternalTable from "./OrdersRowInternalTable";

const Order = ({ order }) => {

  const getConvertedDate = () => {
    const fullDate = new Date(order.datum_order);

    let day = fullDate.getDate();
    let month = fullDate.getMonth() + 1;

    if (day.toString().length < 2) day = '0' + day;
    if (month.toString().length < 2) month = '0' + month;

    return `${day}-${month}-${fullDate.getFullYear()}`;
  }

  return (
    <div className="order">
      <div className="order__top">
        <div className="order__top__id">
          <span>{order.id}</span>
        </div>
        <div className="order__top__datum">
          <span>{getConvertedDate()}</span>
        </div>
      </div>
      <div className="order__bottom">
        <div>
          <OrdersRowInternalTable order={order} />
        </div>
        <div className="order__bottom__status">
          {order.status === "In bestelling" ? <span className="in-order">{order.status}</span> : <span>{order.status}</span>}
        </div>
      </div>
    </div>
  );
};

export default Order;
