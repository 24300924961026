import React, { useEffect, useState } from "react";
import { Trans } from "@lingui/macro";
import arrowDown from "../../../assets/arrow-down.svg";
import arrowUp from "../../../assets/arrow-up.svg";

import "./InputNumberV2.scss"

export const InputNumberV2 = ({
  label,
  name,
  optional,
  required,
  onChange,
  initialValue = 0,
  minValue = 0,
  width = 10
}) => {
  const [number, setNumber] = useState(initialValue);

  useEffect(() => {
    onChange && onChange(number);
  }, [number]);

  return (
    <div className="input-group">
      <label className={`input-group__label`} htmlFor={name}>
        {label && <span>{label}</span>}
        {optional && (
          <em>
            {"("}
            <Trans>optional</Trans>
            {")"}
          </em>
        )}
        {required && <span>*</span>}
      </label>
      <div className="input-number" style={{ width: `${width}rem` }}>
        <input
          className={`input-number__field`}
          style={{ width: `${width - 5}rem` }}
          type="number"
          id={name}
          value={number}
          name={name}
          min={minValue}
          onChange={e => setNumber(parseInt(e.target.value))}
        />
        <div className="input-number__field__arrows">
          <img
            src={arrowUp}
            alt=""
            className="input-number__field__arrows__arrow--up"
            onClick={() => setNumber(number + 1)}
          />
          <img
            src={arrowDown}
            alt=""
            className="input-number__field__arrows__arrow--down"
            onClick={() => {
              if (!(number -1) < minValue) {
                setNumber(number - 1)
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
