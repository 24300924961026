import React, { useState, useEffect } from "react";
import { Trans } from "@lingui/macro";
import { I18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { connect } from "react-redux";

import "./ConfigurationsDetail.scss";
import InputCheckbox from "../../_General/InputCheckbox/InputCheckbox";
import { Input, Button, Fade, Price, ArrowBack, CartLink } from "../../_General";
import ConfiguratorStepOrdersTable from "../Configurator/StepOrders/ConfiguratorStepOrdersTable";
import CarInternal from "./CarInternal";

import IconDocument from "../../../assets/icon-grey-add-document.svg";
import IconDelete from "../../../assets/icon-trash.svg";
import { useFetch } from "../../Utilities/useFetch";

import { 
  setSingleConfiguration, 
  setAllConfigurations, 
  addOrderItem
} from "../../../redux/action-creators";
import { DuplicateModal } from "./DuplicateModal";
import DeleteModal from "./DeleteModal";
import Silhouet from "../Configurator/StepModel/Silhouet";

const ConfigurationsDetail = ({
  allConfigurations,
  match: {
    params: { id }
  },
  currentClient,
  setSingleConfiguration,
  addOrderItem,
  history,
  carModelOptions,
  language,
  clientId
}) => {
  const [configuration] = useState(allConfigurations[id]);
  const [configurationName, setConfigurationName] = useState(allConfigurations[id].naam);
  const [isConfigOutdated, setIsConfigOutdated] = useState(false);
  const [isSuccesful, setIsSsuccesful] = useState(false);
  const [isUnsuccesful, setIsUnsuccesful] = useState(false);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [{ loading }, doFetch] = useFetch();

  useEffect(() => {
    getConfigurations();
  }, [language])

  const getConfigurations = () => {
    doFetch({
      method: "GET",
      url: `hebic/json/klant/${clientId}/configuraties`,
      result: res => {
        const obj = {};

        for (let configuration of res.data) {
          const productItems = {};
          for (const productItem of configuration.product_items) {
            productItems[productItem.categorie_id] = productItem;
          }
          obj[configuration.id] = {
            ...configuration,
            product_items: productItems
          };
        }
        setAllConfigurations(obj);
      }
    });
  }

  const handleNameChange = () => {
    doFetch({
      method: "PATCH",
      url: "hebic/json/update-configuratie",
      data: {
        id: configuration.id,
        naam: configurationName
      },
      result: res => {
        setSingleConfiguration(res.data.id, res.data);
        setIsSsuccesful(true);
        setTimeout(() => setIsSsuccesful(false), 3000);
      }
    });
  };


  /**
 * Add the configuration to the order items.
 */
  const handleAddToShoppingCart = async () => {

    // Do another check here to see if the configuration is still valid.
    // On 400 show to the user that the configuration is not available anymor

    configuration.product_items = Object.values(configuration.product_items);
    configuration.aantal = 1;

    doFetch({
      method: "POST",
      url: "hebic/json/create-configuratie",
      data: configuration,
      result: (res) => {
        if (res.status === 200) {
          addOrderItem(configuration);
          history.push('/cart');
        }
      },
      error: (err) => {
        if (err && err.response.status === 400) {
          setIsConfigOutdated(true);
          setTimeout(() => setIsSsuccesful(false), 3000);
        } else {
          setIsUnsuccesful(true);
          setTimeout(() => setIsSsuccesful(false), 3000);
        }
      }
    });
  }

  return (
    <I18n>
    {({ i18n }) => (
      <div className="configurations-detail">
        <header className="configurations-detail__header">
          <h1>
            <ArrowBack history={history} />
            <Trans>Detail Configuration</Trans>
            {` "${configuration.naam}"`}
          </h1>
          <CartLink></CartLink>
        </header>
        <main className="content configurations-detail__content">
          <div className="configurations-detail__content__left">
            <div className="pack">
              {Object.values(configuration.product_items).filter(({item_type}) => item_type === "Samengesteld").map(productItem => {
                return <InputCheckbox key={productItem.id} pack={productItem} checked={true} />;
              })}
            </div>
            <div className="configuration-name">
              <Input
                label={i18n._(t`Configuration name`)}
                name="configurationName"
                required
                type="text"
                value={configurationName}
                onChange={e => setConfigurationName(e.target.value)}
              />
              <Button size="small" onClick={handleNameChange} loader={loading}>
                <Trans>Change</Trans>
              </Button>
            </div>
            {isSuccesful && (
              <Fade classNames="fade-in">
                <span className="notification">
                  <Trans>Configuration name has been saved.</Trans>
                </span>
              </Fade>
            )}
            <div className="orders-table">
              <h4 className="orders-table__title">
                <Trans>Configuration Car</Trans>
              </h4>
              <table className="orders-table__tables__table">
                <tbody>
                  <tr className="orders-table__tables__table__row">
                    <td className="orders-table__tables__table__row__label">
                      <Trans>Length</Trans>
                    </td>
                    <td className="orders-table__tables__table__row__value">{configuration.automodel.lengte_id}</td>
                  </tr>
                  <tr className="orders-table__tables__table__row">
                    <td className="orders-table__tables__table__row__label">
                      <Trans>Height</Trans>
                    </td>
                    <td className="orders-table__tables__table__row__value">{configuration.automodel.hoogte_id}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              {Object.values(configuration.product_items).map(productItem => {
                if (productItem.item_type !== "Samengesteld" && productItem.item_groep !== 'Montage') {
                  return (
                    <ConfiguratorStepOrdersTable
                      key={productItem.id}
                      title={productItem.categorie_label}
                      product={productItem}
                    />
                  );
                }
              })}
            </div>
          </div>
          <div className="configurations-detail__content__right">
            <div className="sidebar">
              <div className="sidebar__top">
                <div className="sidebar__top__brand-info">
                  <div className="sidebar__top__brand-info__car">
                    <Silhouet
                      carModelOptions={carModelOptions}
                      brand={configuration.automodel.merk_id}
                      model={configuration.automodel.model_id}
                      height={configuration.automodel.hoogte_id}
                      length={configuration.automodel.lengte_id}
                    />
                  </div>
                  <div className="sidebar__top__brand-info__brand">
                    {currentClient.merken.map(brand => {
                      if (brand.id === configuration.automodel.merk_id) {
                        return (
                          <img 
                          key={brand.image} 
                          src={brand.image} 
                          alt={brand.label}/>
                        );
                      }
                    })}
                  </div>
                </div>
                <CarInternal chosenItems={configuration.product_items} />
              </div>

              <div className="sidebar__bottom">
                <div className="sidebar__bottom__actions">
                  <button
                    className="sidebar__bottom__actions__secondary-action"
                    onClick={() => setIsDuplicateModalOpen(true)}>
                    <img src={IconDocument} alt="" />
                    <Trans>Duplicate configuration</Trans>
                  </button>
                  <button
                    className="sidebar__bottom__actions__secondary-action"
                    onClick={() => setIsDeleteModalOpen(true)}>
                    <img src={IconDelete} alt="" />
                    <Trans>Delete configuration</Trans>
                  </button>
                </div>
                <div className="price">
                  <span className="price__notification">
                    <Price calculatePrice={() => parseFloat(configuration.totaalprijs).toFixed(2)} />
                  </span>
                  <Button color="grey" type="button" onClick={handleAddToShoppingCart}>
                    <Trans>Add to shopping cart</Trans>
                  </Button>
                  {isConfigOutdated && (
                    <Fade classNames="fade-in">
                      <div className="notification">
                        <Trans>This configuration is not available anymore.</Trans>
                      </div>
                    </Fade>
                  )}
                  {isUnsuccesful && (
                    <Fade classNames="fade-in">
                      <div className="notification">
                        <Trans>Could not add configuration to cart.</Trans>
                      </div>
                    </Fade>
                  )}
                </div>
              </div>
            </div>
          </div>
          <DuplicateModal
            configuration={configuration}
            clientId={currentClient.id}
            open={isDuplicateModalOpen}
            onClose={() => setIsDuplicateModalOpen(false)}
            history={history}
          />
          <DeleteModal
            configuration={configuration}
            clientId={currentClient.id}
            open={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            history={history}
          />
        </main>
        <div className="actions-mobile">
          <button className="secondary-action" onClick={() => setIsDuplicateModalOpen(true)}>
            <img src={IconDocument} alt="" />
          </button>
          <button className="secondary-action" onClick={() => setIsDeleteModalOpen(true)}>
            <img src={IconDelete} alt="" />
          </button>
        </div>
        <div className="price-mobile">
          <span className="price-mobile__notification">
            <Price calculatePrice={() => parseFloat(configuration.totaalprijs).toFixed(2)} />
          </span>
          <Button color="grey" type="button" onClick={handleAddToShoppingCart}>
            <Trans>Add to shopping cart</Trans>
          </Button>
        </div>
      </div>
      )}
    </I18n>
  );
};

const mapStateToProps = state => {
  return {
    clientId: state.currentClient.id,
    allConfigurations: state.allConfigurations,
    currentClient: state.currentClient,
    carModelOptions: state.carModelOptions,
    language: state.language
  };
};

export default connect(mapStateToProps, { 
  setSingleConfiguration, 
  setAllConfigurations,
  addOrderItem
})(ConfigurationsDetail);
