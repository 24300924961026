import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import { Trans } from "@lingui/macro";
import { I18n } from "@lingui/react";

import { 
  removeOrderItems,   
  resetFullCurrConfig,
  resetCategories,
  resetAllowedSteps
} from "../../../redux/action-creators";
import { useFetch } from "../../Utilities/useFetch";
import { Input, FieldGroup, InputRadio, Button, Fade, Price } from "../../_General";
import { Configuration } from '../../_General'

import "./Cart.scss";

const Cart = ({
  removeOrderItems,
  resetFullCurrConfig,
  resetCategories,
  resetAllowedSteps,
  orderItems = [],
  carModelOptions,
  currentClient,
  history,
  language
}) => {
  const [{}, doFetch] = useFetch();
  const [isUnsuccessful, setIsUnsuccessful] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    setCount(count + 1);
  }, []);

  useEffect(() => {
    if (count > 0) {
      window.scrollTo(0, 0);
    }
  }, [language]);

  const saveOrder = (values, setSubmitting) => {

    // Instead of sending only one configuration, we will send multiple configurations at once.
    // So we have to change the below 'configuratie' attribute from an object to an array and 
    // fill it with the configurations from Redux.

    doFetch({
      method: "POST",
      url: "hebic/json/save-bestelling",
      data: {
        order_id: null,
        client_id: currentClient.id,
        adres_id: values.delivery_address,
        referentie: values.reference,
        configuraties: orderItems
      },
      result: res => {
        setSubmitting(false);
        if (res.status === 400 || res.status === 401 || res.status === 403 || res.status === 500) {
          setIsUnsuccessful(true);
        } else {
          resetFullCurrConfig();
          resetCategories();
          resetAllowedSteps();
          removeOrderItems();
          history.push({
            pathname: "/orders",
            state: { newOrder: res.data }
          });
        }
      }
    });
  };

  return (
    <I18n>
      {({ i18n }) => (
        <div className="cart">
          <div className="cart__header">
            <h1>
              <Trans>Shopping cart</Trans>
            </h1>
            <Button
              size="lg"
              onClick={() => history.push("/configurator/model")}
            >
              <Trans>Add configuration</Trans>
            </Button>
          </div>
          {!orderItems.length > 0 
            ? <div className="cart__empty">
                <Trans>You do not have any configurations to order.</Trans>
                <Button
                  size="lg"
                  onClick={() => history.push("/configurator/model")}
                >
                  <Trans>Add configuration</Trans>
                </Button>
              </div>
            : <Formik 
                initialValues={{
                  reference: "",
                  delivery_address: currentClient.adressen[0].id
                }}
                onSubmit={(values, { setSubmitting }) => {
                  saveOrder(values, setSubmitting);
                }}>
                {({ isSubmitting, setFieldValue, values }) => (
                  <Form>
                    <h2 className="cart__title">
                      <Trans>Configurations</Trans>
                    </h2>
                    <div className="cart__order-details" id="test">
                      {orderItems.map((orderItem, index) => {
                        if (orderItem) {
                          return <Configuration 
                          orderItemIndex={index}
                          orderItem={orderItem} 
                          carModelOptions={carModelOptions}
                        ></Configuration>
                        }
                      })}
                    </div>
                    <div className="cart__extra">
                      <label className="input-group__label" style={{ paddingLeft: "1rem" }}>
                        <Trans>Delivery address</Trans>
                      </label>
                      <div className="cart__extra__delivery-address">
                        {currentClient.adressen.map((address) => {
                          return (
                            <Field
                              key={`${address.id}-${address.label}`}
                              as={InputRadio}
                              name="delivery_address"
                              id={address.id}
                              setFieldValue={setFieldValue}
                              business={{
                                branch: address.label,
                                street: address.adres,
                                postalCode: address.postcode,
                                city: address.gemeente
                              }}
                              checked={values.delivery_address === address.id}
                            />
                          );
                        })}
                      </div>
                    </div>
                    <div className="cart__bottom">
                      <div className="cart__bottom__empty"/>
                      <div className="cart__bottom__filled">
                        <Price
                          calculatePrice={() => {
                            let total = 0.00;
                            orderItems.forEach(orderItem => {
                              total += (parseFloat(orderItem.totaalprijs) * parseInt(orderItem.aantal));
                            })
                            return (parseFloat(total)).toFixed(2);
                          }}
                        ></Price>
                        <div className="cart__bottom__filled__button-notification-wrapper">
                          <Button size="s" disabled={isSubmitting} loader={isSubmitting} type="submit">
                            <Trans>Place order</Trans>
                          </Button>
                          {isUnsuccessful && (
                            <Fade classNames="fade-in">
                              <span className="error-notification">
                                <Trans>Order failed. Please contact Hebic for more info.</Trans>
                              </span>
                            </Fade>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
            </Formik>
        }
        </div>
      )}
    </I18n>
  );
};

const mapStateToProps = state => {
  return {
    carModelOptions: state.carModelOptions,
    currentClient: state.currentClient,
    language: state.language,
    orderItems: state.orderItems
  };
};

export default connect(mapStateToProps, { 
  resetFullCurrConfig,
  resetCategories,
  resetAllowedSteps,
  removeOrderItems 
})(Cart);
