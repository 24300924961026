import React, { useState } from 'react';
import { connect } from "react-redux";
import { Trans } from "@lingui/macro";
import { I18n } from "@lingui/react";
import { Field } from "formik";
import AnimateHeight from "react-animate-height";
import Modal from 'react-modal';
import { t } from "@lingui/macro";

import ConfigurationIcon from "../../../assets/icon-grey-configuration.svg";
import RemoveIcon from "../../../assets/icon-grey-delete.svg";

import "./Configuration.scss";
import "../../_General/Input/Input.scss";

import {
  removeOrderItem,
  setOrderItemAmount,
  setOrderItemReference
} from "../../../redux/action-creators";

import { DeleteOrderItemModal, InputNumberV2, SaveConfigurationModal, Input } from '../../_General';
import Silhouet from '../../Authorized/Configurator/StepModel/Silhouet';
import ConfiguratorStepOrdersTable from '../../Authorized/Configurator/StepOrders/ConfiguratorStepOrdersTable';

Modal.setAppElement('#root');

export let Configuration = ({
  orderItem: {
    naam: name,
    automodel: {
      merk_id: brandId,
      model_id: modelId,
      lengte_id: lengthId,
      hoogte_id: heightId
    },
    product_items,
    aantal: amount,
    referentie: reference
  },
  carModelOptions,
  setOrderItemAmount,
  setOrderItemReference
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [showSaveConfigurationModal, setShowSaveConfigurationModal] = useState(false);
  const [showDeleteOrderItemModal, setShowDeleteOrderItemModal] = useState(false);
  const carModel = carModelOptions && carModelOptions[brandId][modelId];

  return (
    <I18n>
      {({ i18n }) => (
        <div class="order-item">
          <div class="order-item__top">
            <div class="order-item__top__silhouette">
              <Silhouet
                brand={brandId}
                model={modelId}
                height={heightId}
                length={lengthId}
              />
            </div>
            <div class="order-item__top__info">
              <div class="order-item__top__info__model">
                <div class="order-item__top__info__model__left">
                  <table>
                    <tr>
                      <td><Trans>Brand</Trans></td>
                      <td>{brandId}</td>
                    </tr>
                    <tr>
                      <td><Trans>Model</Trans></td>
                      <td>{carModel.model_label}</td>
                    </tr>
                    <tr>
                      <td><Trans>Year</Trans></td>
                      <td>{carModel.model_jaar}</td>
                    </tr>
                  </table>
                  <div class="order-item__top__info__model__left__length-height">
                    <span>{carModel?.lengte_opties[lengthId]?.label}</span>
                    {carModel?.hoogte_opties.map(option => {
                      if (option.id === heightId) {
                        return <span>{option.label}</span>
                      }
                    })}
                  </div>
                </div>
                <div class="order-item__top__info__model__right">
                  <div class="order-item__top__info__model__right__actions">
                    <button
                      class="action"
                      type="button"
                      onClick={() => setShowSaveConfigurationModal(true)}
                    >
                      <img className="action__icon" src={ConfigurationIcon} alt="" />
                    </button>
                    <button
                      class="action"
                      type="button"
                      onClick={() => setShowDeleteOrderItemModal(true)}
                    >
                      <img style={{ height: "3rem" }} className="action__icon" src={RemoveIcon} alt="" />
                    </button>
                  </div>
                  <div class="order-item__top__info__model__right__amount">
                    <InputNumberV2
                      width={11}
                      initialValue={amount ?? 1}
                      minValue={1}
                      onChange={(amount) => setOrderItemAmount(name, amount)}
                    ></InputNumberV2>
                  </div>
                </div>
              </div>
              <div class="order-item__top__info__reference">
                <Input
                  label={i18n._(t`Reference`)}
                  optional
                  type="text"
                  placeholder={i18n._(t`Reference...`)}
                  maxLength="30"
                  comment={i18n._(t`Max. 30 characters`)}
                  onChange={(e) => setOrderItemReference(name, e.target.value)}
                  value={reference}
                  />
              </div>
              <div class="order-item__top__info__products">
                {product_items.map(productItem => {
                  if (productItem.item_type !== "Samengesteld" && productItem.item_groep !== 'Montage') {
                  return (
                    <div className="product">
                      <img className="product__image" src={productItem.materiaal_icon} alt="" />
                      <div className="product__labels">
                        <span className="product__labels__category">{productItem.categorie_id.toLowerCase()}</span>
                        <span className="product__labels__material">{productItem.materiaal_id}</span>
                      </div>
                    </div>
                  );
                  }
                })}
              </div>
            </div>
          </div>
          <div class="order-item__bottom">
            <AnimateHeight duration={200} height={showDetails ? "auto" : 0}>
              {product_items.map(productItem => {
                if (productItem.item_type !== "Samengesteld" && productItem.item_groep !== 'Montage') {
                return (
                  <ConfiguratorStepOrdersTable
                    key={productItem.id}
                    title={productItem.categorie_label}
                    product={productItem}
                  />
                );
                }
              })}
            </AnimateHeight>
            <div class="order-item__bottom__toggle" >
              <button 
                class="order-item__bottom__toggle__button" 
                type="button"
                onClick={() => setShowDetails(!showDetails)}
                >
                  {showDetails 
                    ? <Trans>Close details</Trans>
                    : <Trans>Watch details</Trans>
                  }
              </button>
            </div>
          </div>
          <SaveConfigurationModal
            show={showSaveConfigurationModal}
            onClose={() => setShowSaveConfigurationModal(false)}
          ></SaveConfigurationModal>
          <DeleteOrderItemModal
            configurationName={name}
            show={showDeleteOrderItemModal}
            onClose={() => setShowDeleteOrderItemModal(false)}
          ></DeleteOrderItemModal>
        </div>
      )}
    </I18n>
  )
}

const mapStateToProps = state => {
  return {
    carModelOptions: state.carModelOptions,
    clientId: state.currentClient.id,
    chosenProducts: state.currentConfig.products,
    chosenOptions: state.currentConfig.chosenOptions,
    packProducts: state.currentConfig.packProducts,
    pack: state.currentConfig.pack,
    allProducts: state.allProducts
  };
};

Configuration = connect(mapStateToProps, {
  removeOrderItem,
  setOrderItemAmount,
  setOrderItemReference
})(Configuration);