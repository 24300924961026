import React from "react";
import { Trans } from "@lingui/macro";

const OrdersRowInternalTable = ({ order }) => {
  return (
    <table className="order__details">
      <tbody>
        <tr>
          <th></th>
          {order.bestelling_lijnen[0]?.artikelnummer_klant_basis && (
            <th className="order__details__headers">
              <Trans>Your article number</Trans>
            </th>
          )}
          <th className="order__details__headers">
            <Trans>Hebic article number</Trans>
          </th>
          <th className="order__details__headers">
            <Trans>Amount</Trans>
          </th>
          <th style={{ paddingLeft: "2rem" }} className="order__details__headers">
            <Trans>Reference</Trans>
          </th>
        </tr>
        {order.bestelling_lijnen &&
          order.bestelling_lijnen.map(orderLine => {
            return (
              <tr key={orderLine.product_id}>
                <td style={{ paddingRight: "2rem" }}>
                  {`${orderLine.product_categorie &&
                    orderLine.product_categorie.charAt(0)}${orderLine.product_categorie &&
                    orderLine.product_categorie.substring(1).toLowerCase()}`}
                </td>
                {orderLine.artikelnummer_klant_basis && (
                  <td className="order__details__values">
                    <strong>{orderLine.artikelnummer_klant_basis}</strong>
                  </td>
                )}
                <td className="order__details__values">{orderLine.artikelnummer}</td>
                <td>{orderLine.aantal}</td>
                <td style={{ paddingLeft: "2rem" }}>{orderLine.referentie}</td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default OrdersRowInternalTable;
