import React, { useState } from "react";
import { connect } from "react-redux";
import { setModel } from "../../../../redux/action-creators";
import ConfiguratorModelModal from "./ConfiguratorModelModal";
import { CSSTransition } from "react-transition-group";
import { Trans } from "@lingui/macro";
import SilhouetPlaceholder from "../../../../assets/silhouet-placeholder.svg";

const ConfiguratorModel = ({
  model,
  model: {
    model_id: modelId,
    model_info: modelInfo,
    model_label: modelLabel,
    silhouet_opties: silhouetOptions,
    model_jaar: modelYear
  },
  brand,
  chosenModelId,
  setModel
}) => {
  const [isModelClicked, setIsModelClicked] = useState(false);

  const handleModelClick = () => {
    setIsModelClicked(true);
    setModel(modelId);
  };

  return (
    <div className="configurator__content__model-choice__models__model-wrapper">
      <div className="configurator__content__model-choice__models__model" onClick={handleModelClick}>
        <div className="configurator__content__model-choice__models__model__image">
          <img src={silhouetOptions[0].image || SilhouetPlaceholder} alt="" />
        </div>
        <div className="configurator__content__model-choice__models__model__info">
          <span className="configurator__content__model-choice__models__model__info__model-name">
            {modelLabel}
          </span>
          {modelInfo !== null ? (
            <span>{modelInfo}</span>
          ) : (
            <span>
              <Trans>year</Trans> {modelYear}
            </span>
          )}
        </div>
      </div>
      <CSSTransition in={modelId === chosenModelId || isModelClicked} unmountOnExit timeout={200} classNames="modal">
        <ConfiguratorModelModal
          model={model}
          setIsModelClicked={setIsModelClicked}
          brand={brand}
        />
      </CSSTransition>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    chosenModelId: state.currentConfig.model
  };
};

export default connect(mapStateToProps, { setModel })(ConfiguratorModel);
