import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import { Trans } from "@lingui/macro";

import {
  setConfigurationStep,
  setAllProducts,
  setCurrConfigProducts,
  setProductCategories,
  setChosenPack,
  setProductCategoryStatus,
  setChosenPackProductItems,
  setPhotoGalleryItems,
  setAllowedStep,
  resetChosenProducts,
  resetCategories
} from "../../../../redux/action-creators";
import { Button, Price, Spinner } from "../../../_General";
import StepProductsOption from "./StepProductsOptions";
import Pack from "../../../_General/Pack/Pack";
import { useFetch } from "../../../Utilities/useFetch";
import CarInternal from "./CarInternal";
import PackProductsOption from "./PackProductsOption";
import Silhouet from "../StepModel/Silhouet";

import "./ConfiguratorStepProducts.scss";

const ConfiguratorStepProducts = ({
  setConfigurationStep,
  setCurrConfigProducts,
  setProductCategories,
  setChosenPackProductItems,
  setAllProducts,
  setChosenPack,
  setAllowedStep,
  setPhotoGalleryItems,
  resetChosenProducts,
  resetCategories,
  history,
  isSelected,
  allProducts: {
    product_opties: productOptions = {},
    product_items: productItems = {},
    pack_items: packItems = {}
  },
  currentClient,
  language,
  currentConfig: {
    automodel: carModel,
    products: chosenProducts,
    packProducts,
    pack
  }
}) => {
  const [{ loading }, doFetch] = useFetch();
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count > 0) {
      getCarModelWithProductOptions();
      getPhotoGalleryItems();
    }
  }, [language])

  useEffect(() => {
    getCarModelWithProductOptions();
    getPhotoGalleryItems();
    setConfigurationStep(2);
    setAllowedStep("products");
    setCount(count + 1);
  }, []);

  const getCarModelWithProductOptions = () => {
    doFetch({
      method: "POST",
      url: "hebic/json/automodel/automodel-met-product-opties",
      data: {
        klant_id: currentClient.id,
        merk_id: carModel.merk_id && carModel.merk_id.toUpperCase(),
        model_id: carModel.model_id && carModel.model_id.toUpperCase(),
        hoogte_id: carModel.hoogte_id && carModel.hoogte_id,
        lengte_id: carModel.lengte_id && carModel.lengte_id,
        aandrijving_id: carModel.aandrijving ? carModel.aandrijving.waarde : "0",
        deuren_vooraan_id: carModel.deuren_vooraan ? carModel.deuren_vooraan.waarde : "0",
        deuren_achteraan_id: carModel.deuren_achteraan ? carModel.deuren_achteraan.waarde : "0",
        afwerking_vooraan_id: carModel.afwerking_vooraan ? carModel.afwerking_vooraan.waarde : "0",
        afwerking_achteraan_id: carModel.afwerking_achteraan ? carModel.afwerking_achteraan.waarde : "0"
      },
      result: res => {
        const product_opties = {};
        for (const option of res.data.product_opties) {
          product_opties[option.id] = option;
        }

        const product_items = {};
        for (const item of res.data.product_items) {
          product_items[item.id] = item;
        }

        const pack_items = {};
        for (const item of res.data.pack_items) {
          pack_items[item.id] = item;
        }

        const products = {
          product_opties,
          product_items,
          pack_items,
          assembly_items: res.data.assembly_items,
          componenten_afzonderlijk_bestelbaar: res.data.componenten_afzonderlijk_bestelbaar
        };

        const productCategories = {};
        const categoriesArray = Object.values(products.product_opties).map(option => option.id);

        for (let category of categoriesArray) {
          productCategories[category] = false;
        }
        if (Object.keys(isSelected).length < 1) {
          setProductCategories(productCategories);
        }
        setAllProducts(products);
      }
    });
  };

  const getPhotoGalleryItems = () => {
    doFetch({
      method: "GET",
      url: "client-api/create-search-context",
      result: res => {
        setPhotoGalleryItems(res.data);
      }
    });
  };

  const handlePackSelect = (e, setFieldValue) => {
    e.persist();

    const packItemId = e.target.value;
    const packItem = packItems[packItemId];

    if (e.target.checked) {
      setFieldValue("products", {});
      setFieldValue("pack", packItem);
      setChosenPack(packItem);

      const chosenCategories = {};
      packItem.component_ids.forEach(componentId => {
        if (productItems[componentId]) {
          chosenCategories[productItems[componentId].categorie_id] = true;
        }
      });
      setProductCategories(chosenCategories);

    }
    if (!e.target.checked) {
      resetChosenProducts();
      resetCategories();
      setChosenPack({});
      setFieldValue("pack", {});
      setFieldValue("packProducts", {});
      return;
    }

    const chosenPackItems = packItem.component_ids.map(item => {
      return productItems[item];
    });

    const chosenPackItemsByCategory = {};
    for (let packItem of chosenPackItems) {
      if (packItem && packItem.categorie_id) {
        chosenPackItemsByCategory[packItem.categorie_id] = packItem;
      }
    }

    setFieldValue("packProducts", chosenPackItemsByCategory);
    setChosenPackProductItems(chosenPackItemsByCategory);
  }

  return (
    <>
      <div className="step-products">
        <Formik
          initialValues={{
            pack: pack ? pack : {},
            products: chosenProducts ? chosenProducts : {},
            packProducts: packProducts ? packProducts : {}
          }}
          validate={values => {
            const errors = {};

            let products;
            // Logic for validation of the specification choices for separate products.
            if (!pack.id) {
              products = values.products;
            // Logic for validation of the pack products.
            } else {
              products = values.packProducts;
            }

            for (const [key, product] of Object.entries(products)) {
              const specifications = product.specifications;
              if (specifications) {
                for (const [specificationType, specification] of Object.entries(specifications)) {
                  if (!specification) {
                    errors[key] = {
                      "specifications": {
                        ...(errors[key] && errors[key]["specifications"] && errors[key]["specifications"]),
                        [specificationType]: "Required"
                      }
                    }
                  }
                }
              }
            }

            setCurrConfigProducts(values.products);
            setChosenPackProductItems(values.packProducts);

            return errors;
    
          }}
          onSubmit={() => history.push("/configurator/orders")}
          >
          {({ setFieldValue, submitForm, values }) => (
            <>
              <div className="step-products__left">
                {loading ? (
                  <div className="spinner">
                    <Spinner width="7rem" />
                  </div>
                ) : (
                  <Form className="step-products__left__wrapper">
                    <div>
                      {currentClient.is_pack_visible && Object.values(packItems).length > 0 && (
                        <div className="step-products__left__packages">
                          {Object.values(packItems).map(pack => {
                            return <Pack
                              key={pack.id}
                              customChecked={values.pack.id === pack.id}
                              name="pack"
                              pack={pack}
                              value={pack.id}
                              customOnChange={(e) => handlePackSelect(e, setFieldValue)}
                            ></Pack>
                          })}
                        </div>
                      )}
                      {!pack.id && (
                        <div className="step-products__left__products">
                          {productOptions &&
                            Object.values(productOptions).map(productOption => {
                              return (
                                <Field
                                  name={`products.${productOption.id}`}
                                  component={StepProductsOption}
                                  key={productOption.id}
                                  productOption={productOption}
                                  values={values}
                                />
                              );
                            })}
                        </div>
                      )}
                      {pack.id && values.packProducts && (
                        <div className="step-products__left__products">
                          {Object.values(values.packProducts).map(packProduct => {
                            return (
                              <Field
                                component={PackProductsOption}
                                key={packProduct.id}
                                packProduct={packProduct}
                              />
                            );
                          })}
                        </div>
                      )}
                    </div>

                    <div className="price">
                      <span className="price__notification">
                        <Price />
                      </span>
                      <Button color="grey" type="submit" onClick={() => submitForm()}>
                        <Trans>Order</Trans>
                      </Button>
                    </div>
                  </Form>
                )}
              </div>
              <div className="step-products__right">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%"
                  }}>
                  <div className="step-products__right-top">
                    <div className="step-products__right__brand-info">
                      <div className="step-products__right__brand-info__car">
                        <Silhouet
                          length={carModel.lengte_id}
                          height={carModel.hoogte_id}
                          brand={carModel.merk_id}
                          model={carModel.model_id}
                        />
                      </div>
                      <div className="step-products__right__brand-info__brand">
                        {currentClient.merken.map(brand => {
                          if (brand.id === carModel.merk_id) {
                            return (
                              <img 
                              key={brand.image} 
                              src={brand.image} 
                              alt={brand.label}/>
                            );
                          }
                        })}
                      </div>
                    </div>
                    {productOptions && (
                      <Field component={CarInternal}/>
                    )}
                    <div className="price">
                      <span className="price__notification">
                        <Price />
                      </span>
                      <Button color="grey" type="submit" onClick={() => submitForm()}>
                        <Trans>Go to overview</Trans>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    isSelected: state.isChosen,
    currentClient: state.currentClient,
    allProducts: state.allProducts,
    language: state.language,
    currentConfig: state.currentConfig
  };
};

export default connect(mapStateToProps, {
  setConfigurationStep,
  setAllProducts,
  setCurrConfigProducts,
  setProductCategories,
  setChosenPack,
  setProductCategoryStatus,
  setChosenPackProductItems,
  setAllowedStep,
  setPhotoGalleryItems,
  resetChosenProducts,
  resetCategories
})(ConfiguratorStepProducts);
