/**
 * Get product item by category id and material id from all product items.
 */
export const findProductItemByCategoryIdAndMaterialId = (
  categoryId, 
  materialId, 
  productItems = {}
) => {
  productItems = Object.values(productItems);

  const matchingProductItems = []
  for (let productItem of productItems) {
    if (
      categoryId === productItem.categorie_id &&
      materialId === productItem.materiaal_id &&
      productItem.meta.isSeparatelyAvailable
    ) {
      matchingProductItems.push(productItem)
    }
  }

  let matchedProductItem = null

  if (matchingProductItems.length === 1) {
    matchedProductItem = matchingProductItems[0]
  } else if (matchingProductItems.length > 1) {
    // Always give priority to product items that are not CARGO or DUOFIX.
    // Otherwise just take the first option if there are multiple matches.
    matchingProductItems.forEach(productItem => {
      if (!productItem.artikelnummer.includes('CARGO') && !productItem.artikelnummer.includes('DUOFIX')) {
        matchedProductItem = productItem
      } else {
        matchedProductItem = matchingProductItems[0]
      }
    })
  }
  return matchedProductItem

}