import React, { useState } from "react";
import { Switch, Redirect } from "react-router-dom";

import Account from "../Account/Account";
import Configurations from "../Configurations/Configurations";
import Configurator from "../Configurator/Configurator";
import Orders from "../Orders/Orders";
import Statistics from "../Statistics/Statistics";
import PrivateRoute from "../../_General/PrivateRoute/PrivateRoute";
import Menu from "./PlatformMenu";
import MenuBottom from "./PlatformMenuBottom";
import MenuTop from "./PlatformMenuTop";
import PlatformMenu from "./PlatformMenu";

import "./Platform.scss";
import { Button } from "../../_General";
import ConfigurationsDetail from "../Configurations/ConfigurationsDetail";
import ConfigurationsOrder from "../Configurations/ConfigurationsOrder";
import Cart from "../Cart/Cart";

const Platform = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    window.onbeforeunload = () => window.localStorage.removeItem("persist:root");
    window.location.assign("/");
  };

  return (
    <div className="platform">
      <aside className="platform__sidebar">
        <MenuTop />
        <Menu />
        <MenuBottom handleLogout={handleLogout} />
      </aside>
      <nav className={`mobile-menu ${isMenuOpen && "mobile-menu--active"}`}>
        <PlatformMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        <Button size="m" onClick={handleLogout}>
          Log out
        </Button>
      </nav>
      <button className="mobile-menu-button" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <span className={`stripe ${isMenuOpen && "stripe-1"}`}></span>
        <span className={`stripe ${isMenuOpen && "stripe-2"}`}></span>
        <span className={`stripe ${isMenuOpen && "stripe-3"}`}></span>
      </button>
      <div className="platform__content">
        <Switch>
          <PrivateRoute exact path={`/configurations`} component={Configurations} />
          <PrivateRoute exact path={`/configurations/:id`} component={ConfigurationsDetail} />
          <PrivateRoute exact path={`/configurations/:id/order`} component={ConfigurationsOrder} />
          <PrivateRoute path={`/configurator`} component={Configurator} />
          <PrivateRoute path={`/orders`} component={Orders} />
          <PrivateRoute path={`/statistics`} component={Statistics} />
          <PrivateRoute path={`/account`} component={Account} />
          <PrivateRoute path={`/cart`} component={Cart} />
          <Redirect from="/" to="/configurator/model" />
        </Switch>
      </div>
    </div>
  );
};

export default Platform;
