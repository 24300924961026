export const LOGIN = "LOGIN";
export const SET_TOKENS = "SET_ACCESS_TOKEN";
export const LOGOUT = "LOGOUT";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_CONFIGURATION_STEP = "SET_CONFIGURATION_STEP";
export const SET_MODEL = "SET_MODEL";
export const SET_CLIENTS = "SET_CLIENTS";
export const SET_CURRENT_CLIENT = "SET_CURRENT_CLIENT";
export const SET_MODEL_OPTIONS = "SET_MODEL_OPTIONS";
export const RESET_MODEL_OPTIONS = "RESET_MODEL_OPTIONS";
export const SET_CONFIGURATION_CAR_MODEL = "SET_CONFIGURATION_CAR_MODEL";
export const SET_CONFIGURATION_SPEC_GROUPS = "SET_CONFIGURATION_SPEC_GROUPS";
export const SET_ALL_PRODUCTS = "SET_ALL_PRODUCTS";
export const SET_ALL_CONFIGURATIONS = "SET_ALL_CONFIGURATIONS";
export const SET_SINGLE_CONFIGURATION = "SET_SINGLE_CONFIGURATION";
export const SET_CONFIGURATION_PRODUCTS = "SET_CONFIGURATION_PRODUCTS";
export const SET_CHOSEN_PACK = "SET_CHOSEN_PACK";
export const SET_PRODUCT_CATEGORIES = "SET_PRODUCT_CATEGORIES";
export const SET_PRODUCT_CATEGORY_STATUS = "SET_PRODUCT_CATEGORY_STATUS";
export const SET_CONFIGURATION_AMOUNT = "SET_CONFIGURATION_AMOUNT";
export const SET_CHOSEN_BRAND = "SET_CHOSEN_BRAND";
export const SET_CHOSEN_MODEL = "SET_CHOSEN_MODEL";
export const RESET_PARTIAL_CONFIGURATION = "RESET_PARTIAL_CONFIGURATION";
export const RESET_FULL_CONFIGURATION = "RESET_FULL_CONFIGURATION";
export const RESET_CATEGORIES = "RESET_CATEGORIES";
export const SET_CHOSEN_PACK_PRODUCT_ITEMS = "SET_CHOSEN_PACK_PRODUCT_ITEMS";
export const SET_ALL_SPECIFICATION_GROUPS = "SET_ALL_SPECIFICATION_GROUPS";
export const SET_SPECIFICATION_GROUP = "SET_SPECIFICATION_GROUP";
export const SET_VISITED = "SET_VISITED";
export const SET_ALLOWED_STEP = "SET_ALLOWED_STEP";
export const RESET_ALLOWED_STEPS = "RESET_ALLOWED_STEPS";
export const SET_ALL_ORDERS = "SET_ALL_ORDERS";
export const SET_SPECIFICATION_GROUP_PER_MODEL =
  "SET_SPECIFICATION_GROUP_PER_MODEL";
export const CLEAR_PACK = "CLEAR_PACK";
export const RESET_CHOSEN_OPTIONS = "RESET_CHOSEN_OPTIONS";
export const RESET_CHOSEN_PRODUCTS = "RESET_CHOSEN_PRODUCTS";
export const RESET_CHOSEN_PACK_PRODUCTS = "RESET CHOSEN PACK PRODUCTS";
export const RESET_ALL_PRODUCTS = "RESET_ALL_PRODUCTS";
export const SET_PHOTO_GALLERY_ITEMS = "SET_PHOTO_GALLERY_ITEMS";
export const ADD_ORDER_ITEM = "ADD_ORDER_ITEM";
export const REMOVE_ORDER_ITEM = "REMOVE_ORDER_ITEM";
export const REMOVE_ORDER_ITEMS = "REMOVE_ORDER_ITEMS";
export const SET_ORDER_ITEM_AMOUNT = "SET_ORDER_ITEM_AMOUNT";
export const SET_ORDER_ITEM_REFERENCE = "SET_ORDER_ITEM_REFERENCE";