import React from "react";
import { CSSTransition } from "react-transition-group";

import CloseIcon from "../../../assets/icon-close.svg";
import "./Modal.scss";

export const Modal = ({ title, children, open, onClose }) => {
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <CSSTransition in={open} timeout={200} classNames="my-modal" unmountOnExit>
      <div className={`modal`}>
        <div className="modal__close" onClick={handleClose}>
          <img src={CloseIcon} alt="" />
        </div>
        <div className="modal__title">{title}</div>
        <div className="modal__input">{children}</div>
      </div>
    </CSSTransition>
  );
};

export default Modal;
