import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";

import App from "./components/Unauthorized/App";
import configureStore from "./redux/store";
import catalogNl from "./locales/nl/messages";
import catalogEn from "./locales/en/messages";
import catalogFr from "./locales/fr/messages";
import catalogDe from "./locales/de/messages";

const rootElement = document.getElementById("root");

const { store, persistor } = configureStore();

const catalogs = {
  nl: catalogNl,
  en: catalogEn,
  fr: catalogFr,
  de: catalogDe
};

ReactDOM.render(
  <App store={store} persistor={persistor} catalogs={catalogs} />,
  rootElement
);
