import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";

import AsNavFor from "../Gallery/Gallery";

import CloseIcon from "../../../assets/close-white.svg";
import "./Camera.scss";

export const Camera = ({ images }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <span className="camera" onClick={() => setModalIsOpen(true)}>
      <FontAwesomeIcon icon={faCamera} />
      <CSSTransition
        in={modalIsOpen}
        timeout={200}
        classNames="camera-modal"
        unmountOnExit
      >
        <div className="camera__gallery">
          <div className="camera__gallery__close">
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setModalIsOpen(false);
              }}
            >
              <img src={CloseIcon} alt="" />
            </button>
          </div>
          <AsNavFor images={images} />
        </div>
      </CSSTransition>
    </span>
  );
};
