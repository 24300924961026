import React, { useEffect, useState } from "react";
import { Trans } from "@lingui/macro";
import { connect } from "react-redux";

import CopyIcon from "../../../../assets/copy-icon.svg";
import { buildArticleNumber } from "../../../Utilities/buildArticleNumber";

const PackProductsOptionCopy = ({ label, packProduct, type }) => {

  const [isCopied, setIsCopied] = useState(false);
  const [articleNumber, setArticleNumber] = useState('');

  const handleCopy = () => {
    navigator.clipboard.writeText(articleNumber);
    setIsCopied(true);
  };

  useEffect(() => {
    const articleNumber = buildArticleNumber(packProduct);
    setArticleNumber(articleNumber);
  }, [packProduct]);

  return (
    <div className="step-products__left__products__product-detail__option step-products__left__products__product-detail__option--margin">
      <div className="step-products__left__products__product-detail__option__label">{label}</div>
      <div className="step-products__left__products__product-detail__option__value">
        <span>{type === "hebic" ? articleNumber : packProduct.artikelnummer_klant}</span>
        <button type="button" onClick={handleCopy}>
          <img src={CopyIcon} alt="" />
        </button>
        {isCopied && (
          <span className="copied">
            <Trans>Copied!</Trans>
          </span>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    isChosen: state.isChosen,
    chosenProducts: state.currentConfig.products,
    chosenOptions: state.currentConfig.chosenOptions
  };
};

export default connect(mapStateToProps)(PackProductsOptionCopy);
