import React from "react";
import { Fade } from "../Fade/Fade";
import "./NotificationLarge.scss";

export const NotificationLarge = ({ children }) => {
  return (
    <Fade classNames="fade-in">
      <div className="notification__large">{children}</div>
    </Fade>
  );
};
