import React, { useState } from "react";
import { connect } from "react-redux";
import { Trans } from "@lingui/macro";

import { setSingleConfiguration } from "../../../redux/action-creators";
import { useFetch } from "../../Utilities/useFetch";
import ConfigurationsRowEmpty from "./ConfigurationsRowEmpty";
import Silhouet from "../Configurator/StepModel/Silhouet";

const ConfigurationsRow = ({ configuration, carModelOptions, setSingleConfiguration, history }) => {
  const [configName, setConfigName] = useState(configuration.naam);
  const [{}, doFetch] = useFetch();

  const handleNameChange = () => {
    doFetch({
      method: "PATCH",
      url: "hebic/json/update-configuratie",
      data: {
        id: configuration.id,
        naam: configName
      },
      result: res => {
        setSingleConfiguration(res.data.id, res.data);
      }
    });
  };

  return (
    <>
      <tr className="configurations__content__list__table__row">
        <td className="configurations__content__list__table__row__image" rowSpan="2">
          <Silhouet
            brand={configuration.automodel.merk_id}
            model={configuration.automodel.model_id}
            height={configuration.automodel.hoogte_id}
            length={configuration.automodel.lengte_id}
          />
        </td>
        <td className="configurations__content__list__table__row__cell configurations__content__list__table__row__input">
          <input
            type="text"
            value={configName !== null ? configName : "Geen naam"}
            onChange={e => setConfigName(e.target.value)}
            onBlur={() => handleNameChange()}></input>
        </td>
        <td className="configurations__content__list__table__row__cell">{configuration.automodel.merk_id}</td>
        <td className="configurations__content__list__table__row__cell">{configuration.automodel.model_id}</td>
        <td className="configurations__content__list__table__row__cell">
          {configuration.automodel.merk_id &&
            carModelOptions[configuration.automodel.merk_id][configuration.automodel.model_id] &&
            carModelOptions[configuration.automodel.merk_id][configuration.automodel.model_id].model_jaar}
        </td>
        <td className="configurations__content__list__table__row__cell">{configuration.automodel.lengte_id}</td>
        <td className="configurations__content__list__table__row__cell">{configuration.automodel.hoogte_id}</td>
        <td
          className="configurations__content__list__table__row__cell configurations__content__list__table__row__button"
          rowSpan="2"
          role="button"
          onClick={() => history.push(`/configurations/${configuration.id}`)}>
          <Trans>Detail</Trans>
        </td>
      </tr>
      <tr className="configurations__content__list__table__row">
        <td className="configurations__content__list__table__row__cell" colSpan="6">
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {Object.values(configuration.product_items).map(productItem => {
              if (productItem.item_type !== "Samengesteld" && productItem.item_groep !== 'Montage') {
                return (
                  <div
                    key={productItem.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "2rem 2rem"
                    }}>
                    <img
                      src={productItem.materiaal_icon}
                      style={{
                        marginRight: "1rem",
                        maxWidth: "4rem",
                        borderRadius: "50%"
                      }}
                      alt={productItem.materiaal_id}
                    />
                    <div>
                      <div>{`${productItem.categorie_id.charAt(0).toUpperCase()}${productItem.categorie_id
                        .substring(1)
                        .toLowerCase()}`}</div>
                      <div>{productItem.materiaal_label}</div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </td>
      </tr>
      <ConfigurationsRowEmpty />
    </>
  );
};

const mapStateToProps = state => {
  return {
    clientId: state.currentClient.id,
    carModel: state.currentConfig.automodel,
    carModelOptions: state.carModelOptions,
    chosenProducts: state.currentConfig.products,
    chosenOptions: state.currentConfig.chosenOptions
  };
};

export default connect(mapStateToProps, { setSingleConfiguration })(ConfigurationsRow);
