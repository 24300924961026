import React, { useState } from "react";
import { connect } from "react-redux";
import { login, setTokens } from "../../redux/action-creators";
import { ConnectedHelp, ConnectedLanguages } from "../_General";

import Logo from "../../assets/logo.svg";
import HebicBG from "../../assets/hebic_bg@2x.png";
import "./Login.scss";
import LoginForm from "./LoginForm";
import ResetForm from "./ResetForm";

const Login = ({ history }) => {
  const [isPasswordReset, setIsPasswordReset] = useState(false);

  return (
    <div className="login">
      <div className="login__left">
        <div className="login__left__top">
          <a href="https://www.hebic.be/">
            <img src={Logo} alt="" />
          </a>
        </div>
        {!isPasswordReset ?
          <LoginForm setIsPasswordReset={setIsPasswordReset} history={history} />
          :
          <ResetForm setIsPasswordReset={setIsPasswordReset} />
        }
        <div className="login__left__help">
           <ConnectedHelp />
         </div>
      </div>
      <div className="login__right">
        <div className="login__right__background__wrapper">
          <img className="login__right__background" src={HebicBG} alt="" />
        </div>
         <ConnectedLanguages />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.loginDetails.accessToken,
  };
};

export default connect(mapStateToProps, {
  login,
  setTokens,
})(Login);
