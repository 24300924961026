import React from "react";
import { Trans } from "@lingui/macro";
import "./ConfiguratorStepOrdersTable.scss";

const ConfiguratorStepOrdersTable = ({ title, product }) => {
  return (
    <div className="orders-table">
      <h4 className="orders-table__title">{title}</h4>
      <div className="orders-table__tables">
        <table className="orders-table__tables__table">
          <tbody>
            <tr className="orders-table__tables__table__row">
              <td className="orders-table__tables__table__row__label">Your article no.</td>
              <td className="orders-table__tables__table__row__value">{`${product.artikelnummer}`}</td>
            </tr>
            <tr className="orders-table__tables__table__row">
              <td className="orders-table__tables__table__row__label">Hebic article no.</td>
              <td className="orders-table__tables__table__row__value">{`${product.artikelnummer}`}</td>
            </tr>
            <tr className="orders-table__tables__table__row">
              <td className="orders-table__tables__table__row__label">Material</td>
              <td className="orders-table__tables__table__row__value">{product.materiaal_id}</td>
            </tr>
            {product.specificaties && product.specificaties.filter(({label}) => label !== "nul-optie").map(specification => {
              return (
                <tr key={specification.type} className="orders-table__tables__table__row">
                  <td className="orders-table__tables__table__row__label">{specification.type}</td>
                  <td className="orders-table__tables__table__row__value">{specification.label}</td>
                </tr>
              );
            })}
            {product.meta.isAssemblable && (
              <tr className="orders-table__tables__table__row">
                <td className="orders-table__tables__table__row__label"><Trans>With assembly</Trans></td>
                <td className="orders-table__tables__table__row__value">{product.withAssembly ? <Trans>Yes</Trans> : <Trans>No</Trans>}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ConfiguratorStepOrdersTable;
