import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { setConfigurationStep } from "../../../../redux/action-creators";
import ConfiguratorModelChoice from "./ConfiguratorModelChoice";
import { Spinner } from "../../../_General";
import "../Configurator.scss";

const ConfiguratorStepModel = ({ setConfigurationStep, brands }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setConfigurationStep(1);
  }, []);

  return (
    <div className="steps-model">
      {isLoading && <Spinner width="7rem" />}
      <div>
        {Object.values(brands).map(brand => (
          <ConfiguratorModelChoice 
            key={brand.id} 
            brand={brand} 
            setIsLoading={setIsLoading} 
          />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    brands: state.currentClient.merken
  };
};

export default connect(mapStateToProps, { setConfigurationStep })(ConfiguratorStepModel);
