import React from "react";
import "./Button.scss";
import SpinnerIcon from "../../../assets/button-spinner.svg";

export const Button = ({
  children,
  size = "m",
  color = "green",
  onClick,
  disabled,
  type,
  className,
  loader
}) => {
  return (
    <button
      type={type}
      className={`btn btn--${size} btn--${color} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <span>{children}</span>
      {loader && (
        <img
          src={SpinnerIcon}
          style={{ width: "1.5rem", position: "absolute", right: "18px" }}
          alt="spinner"
        />
      )}
    </button>
  );
};
