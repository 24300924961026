import React from "react";
import { connect } from "react-redux";
import { Field } from "formik";
import { I18n } from "@lingui/react";
import { t } from "@lingui/macro";

import {
  resetChosenProducts,
  resetCategories,
  setChosenPack
} from "../../../../redux/action-creators";
import { Fade } from "../../../_General";
import { ProductOptionCheckbox } from "../../../_General";
import PackProductsOptionCopy from "./PackProductsOptionCopy";
import PackProductsOptionRadio from "./PackProductsOptionRadio";
import { initializeProductItemSpecificationsAndRemainingSpecificationOptionGroups } from "../../../Utilities/initializeProductItem";
import { SpecificationOptionGroup } from "../../../_General";
import StepProductsOptionCheckbox from "./StepProductsOptionCheckbox";

const PackProductsOption = ({
  packProduct,
  packProduct: {
    id,
    categorie_id: categoryId,
    categorie_label: label,
    artikelnummer_klant
  },
  form,
  resetChosenProducts,
  setChosenPack,
  resetCategories,
  allProducts
}) => {

  if (Object.keys(packProduct).length > 0 && !packProduct.specifications) {
    initializeProductItemSpecificationsAndRemainingSpecificationOptionGroups(packProduct);
  }

  /**
   * Resets the pack, pack products and chosen/active categories.
   */
  const handlePackProductSelect = () => {
    resetChosenProducts();
    resetCategories();
    setChosenPack({});
    form.setFieldValue("pack", {});
    form.setFieldValue("packProducts", {});
  }

    /**
   * Set the chosen specification on the form 
   * products state for the relevant product item.
   */
  const handleSpecificationOptionSelect = (e, specificationOptionGroup) => {
    form.setFieldValue("packProducts", {
      ...form.values.packProducts,
      [categoryId]: {
        ...form.values.packProducts[categoryId],
        [form.values.packProducts[categoryId].specifications]: {
          ...form.values.packProducts[categoryId].specifications,
          ...form.values.packProducts[categoryId].specifications[specificationOptionGroup.type] = e
        }
      }
    });
  }

  return (
    <I18n>
      {({ i18n }) => (
        <Fade classNames="fade-in">
          <div className="step-products__left__products__wrapper">
            <div className="step-products__left__products__product step-products__left__products__product--active">
              <ProductOptionCheckbox
                name={`products.${categoryId}`}
                customChecked={true}
                label={label}
                value={categoryId}
                customOnChange={handlePackProductSelect}
              ></ProductOptionCheckbox>
            </div>
            <div className="step-products__left__products__product-detail step-products__left__products__product--active">
              {artikelnummer_klant && (
                <PackProductsOptionCopy 
                  label={i18n._(t`Your article no.`)} 
                  packProduct={packProduct} 
                  type="client" 
                />
              )}
              <PackProductsOptionCopy 
                label={i18n._(t`Hebic article no.`)} 
                packProduct={packProduct} 
                type="hebic" 
              />
              <Field
                component={PackProductsOptionRadio}
                name="products"
                category={categoryId}
                materials={[
                  {
                    value: packProduct.materiaal_id,
                    label: packProduct.materiaal_label,
                    image:
                      allProducts.product_items[id] &&
                      allProducts.product_items[id].materiaal_keuze &&
                      allProducts.product_items[id].materiaal_keuze.image
                  }
                ]}
              />
              {packProduct && packProduct.remainingSpecificationOptionGroups && packProduct.remainingSpecificationOptionGroups.map(specificationOptionGroup => {
                return <SpecificationOptionGroup 
                  name={`packProducts.${categoryId}.specifications[${specificationOptionGroup.type}]`}
                  key={specificationOptionGroup.type}
                  specificationOptionGroup={specificationOptionGroup}
                  form={form}
                  categoryId={categoryId}
                  chosenSpecifications={packProduct.specifications}
                  customOnChange={(e) => handleSpecificationOptionSelect(e, specificationOptionGroup)}
                ></SpecificationOptionGroup>
              })}
              {packProduct && packProduct.meta.isAssemblable && (
                <StepProductsOptionCheckbox
                  label={i18n._(t`With assembly`)}
                  name={`packProducts.${categoryId}.withAssembly`}
                ></StepProductsOptionCheckbox>
              )}
            </div>
          </div>
        </Fade>
      )}
    </I18n>
  );
};

const mapStateToProps = state => {
  return {
    isChosen: state.isChosen,
    currentConfig: state.currentConfig,
    allProducts: state.allProducts
  };
};

export default connect(mapStateToProps, {
  resetChosenProducts,
  resetCategories,
  setChosenPack
})(PackProductsOption);
