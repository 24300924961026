import React, { useState } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import Modal from 'react-modal';
import { Trans } from "@lingui/macro";
import { I18n } from "@lingui/react";
import { t } from "@lingui/macro";

import { Input, Button, Fade } from "../../_General";
import { useFetch } from "../../Utilities/useFetch";

import CloseIcon from "../../../assets/icon-close.svg";

import "./SaveConfigurationModal.scss";

export let SaveConfigurationModal = ({
  clientId,
  currentConfig: {
    automodel: carModel,
    products: chosenProducts,
    packProducts,
    pack: chosenPack
  },
  show = false,
  onClose
}) => {
  const [{}, doFetch] = useFetch();
  const [isSuccesful, setIsSuccesful] = useState(false);

  const handleClose = () => {
    onClose && onClose();
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: "0",
      boxShadow: "0px 0px 40px 0 #383b3d"
    },
  };

  return (
    <Modal
      isOpen={show}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Save configuration modal"
    >
      <I18n>
        {({ i18n }) => (
          <div className="save-configuration-modal">
            <div className="save-configuration-modal__close" onClick={handleClose}>
              <img src={CloseIcon} alt="" />
            </div>
            <div className="save-configuration-modal__title">
              <Trans>Save configuration</Trans>
            </div>
            <div className="save-configuration-modal__input">
              <Formik
                initialValues={{ configurationName: "" }}
                validate={() => {}}
                onSubmit={(values, { setSubmitting }) => {
                  let products;
                  if (!chosenPack.id) {
                    products = chosenProducts;
                  } else {
                    products = packProducts;
                  }
                  let productItems = [];
                  for (let product of Object.values(products)) {
                    const productItem = {
                      id: product.id,
                      specificaties: Object.values(product.specifications),
                      withAssembly: product.withAssembly
                    };
                    productItems.push(productItem);
                  }
              
                  if (chosenPack.id) {
                    productItems.push({ id: chosenPack.id });
                  }
              
                  doFetch({
                    method: "POST",
                    url: "hebic/json/save-configuratie",
                    data: {
                      klant_id: clientId,
                      naam: values.configurationName,
                      automodel: {
                        klant_id: clientId,
                        merk_id: carModel?.merk_id?.toUpperCase(),
                        model_id: carModel?.model_id?.toUpperCase(),
                        hoogte_id: carModel?.hoogte_id,
                        lengte_id: carModel?.lengte_id,
                        aandrijving_id: carModel.aandrijving ? carModel.aandrijving.waarde : "0",
                        deuren_vooraan_id: carModel.deuren_vooraan ? carModel.deuren_vooraan.waarde : "0",
                        deuren_achteraan_id: carModel.deuren_achteraan ? carModel.deuren_achteraan.waarde : "0",
                        afwerking_vooraan_id: carModel.afwerking_vooraan ? carModel.afwerking_vooraan.waarde : "0",
                        afwerking_achteraan_id: carModel.afwerking_achteraan ? carModel.afwerking_achteraan.waarde : "0"
                      },
                      product_items: productItems,
                      aantal: 1
                    },
                    result: () => {
                      setSubmitting(false);
                      setIsSuccesful(true);
                    }
                  });
                }}>
                {({ isSubmitting }) => (
                  <Form>
                    <Field
                      label={i18n._(t`Give this configuration a name`)}
                      component={Input}
                      type="text"
                      name="configurationName"
                      placeholder={i18n._(t`Ex. Configuration #12`)}
                    />
                    <div className="save-configuration-modal__button">
                      <div>
                        {isSuccesful && (
                          <Fade classNames="fade-in">
                            <div className="notification">
                              <Trans>Your configuration was saved.</Trans>
                            </div>
                          </Fade>
                        )}
                      </div>
                      <Button size="s" type="submit" disabled={isSubmitting} loader={isSubmitting}>
                        <Trans>Save</Trans>
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
        </I18n>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    currentConfig: state.currentConfig,
    clientId: state.currentClient.id,
    allProducts: state.allProducts
  };
};

SaveConfigurationModal = connect(mapStateToProps)(SaveConfigurationModal);