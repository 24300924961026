import { createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import appReducer from "./reducers";

let locale = navigator.language || navigator.userLanguage || 'nl';

if (locale !== 'nl' && locale !== 'fr') {
  locale = 'nl';
}
const persistConfig = {
  key: "root",
  storage
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export default () => {
  let store = createStore(
    persistedReducer,
    {
      language: locale
    },
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  let persistor = persistStore(store);
  return { store, persistor };
};
