import React from "react";
import { Trans } from "@lingui/macro";

const MobileOrder = ({ order }) => {
  return (
    <div className="mobile-order">
      <div className="mobile-order__id">{order.id}</div>
      <div className="mobile-order__status">{order.status}</div>
      <div className="mobile-order__order-fields">
        <div className="mobile-order__order-field">
          <div className="mobile-order__order-field__label">
            <Trans>Amount</Trans>
          </div>
          <div className="mobile-order__order-field__value">{order.aantal}</div>
        </div>
        <div className="mobile-order__order-field">
          <div className="mobile-order__order-field__label">
            <Trans>Assembly</Trans>
          </div>
          <div className="mobile-order__order-field__value">No</div>
        </div>
        <div className="mobile-order__order-field">
          <div className="mobile-order__order-field__label">
            <Trans>Date</Trans>
          </div>
          <div className="mobile-order__order-field__value">{order.datum_order}</div>
        </div>
      </div>
      <div className="mobile-order__order-lines">
        {order.bestelling_lijnen.map(orderLine => (
          <div key={orderLine.product_id} className="mobile-order__order-line">
            <div className="mobile-order__order-line__category">{orderLine.product_categorie}</div>
            <div className="mobile-order__order-line__field">
              <div className="mobile-order__order-line__field__value">
                <strong>{orderLine.artikelnummer}</strong>
              </div>
            </div>
            {orderLine.referentie && (<div className="mobile-order__order-line__field">
              <div className="mobile-order__order-line__field__value">
                <Trans>Reference</Trans>: {orderLine.referentie}
              </div>
            </div>)}
            <div className="mobile-order__order-line__field"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileOrder;
