import React from "react";
import { NavLink } from "react-router-dom";

const MenuItem = ({ icon, path, name }) => {
  return (
    <div className="platform__sidebar__menu__link">
      <NavLink
        className="platform__sidebar__menu__link__tag"
        activeClassName="platform__sidebar__menu__link__active"
        isActive={(match, location) => {
          if (path.includes("configurator") && location.pathname.includes("configurator")) {
            return true;
          } else if (path === location.pathname) {
            return true;
          } else {
            return false;
          }
        }}
        to={path}>
        {icon}
        {name}
      </NavLink>
    </div>
  );
};

export default MenuItem;
