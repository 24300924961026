import { useField } from "formik";
import React from "react";
import { Select } from "../../../_General";
import { I18n } from "@lingui/react";
import { t } from "@lingui/macro";

const SpecificationOption = ({ 
    label, 
    options, 
    setFieldValue,
    setFieldTouched,
    ...props 
}) => {

    const [ { name }, meta ] = useField(props);

    if (options.length === 0) {
        return null;
    } else if (options.length === 1) {
        if (!Object.keys(meta.value).length > 0 || meta.value.waarde !== options[0].id) {
            setFieldValue(name, {
                type: name,
                waarde: options[0].id,
                label: options[0].label
            });
        }
        return null;
    } else {
        return (
            <I18n>
                {({ i18n }) => (
                    <div className="model-modal__options__bottom__choices__choice">
                        <label>{label}</label>
                        <Select
                            placeholder={i18n._(t`Make a selection...`)}
                            options={options.map(option => {
                                return {
                                type: name,
                                waarde: option.id,
                                label: option.label
                                };
                            })}
                            defaultValue={Object.keys(meta.value).length > 0 && meta.value}
                            onChangeHook={(chosenOption) => setFieldValue(name, chosenOption)}
                            onBlurHook={() => setFieldTouched(name)}
                            error={meta.touched && meta.error}
                        ></Select>
                    </div>
                )}
            </I18n>
          );
    }
};

export default SpecificationOption;
