import React from "react";
import { Field, useField } from "formik";
import { I18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { Select } from '..'

export const SpecificationOptionGroup = ({ 
  specificationOptionGroup, 
  chosenSpecifications, 
  form, 
  categoryId,
  customOnChange,
  ...props
}) => {

  const [field, meta] = useField(props);

  /**
   * Remove 'nul-optie' specification option 
   * from specification option group.
   */
  const filterOptions = specificationOptionGroup => {
    const filteredSpecificationOptions = specificationOptionGroup.specificatie_opties.filter(specificationOption => {
      return specificationOption.label !== "nul-optie";
    });
    return filteredSpecificationOptions.map(specificationOption => {
      return {
        type: specificationOptionGroup.type,
        waarde: specificationOption.waarde,
        label: specificationOption.label,
        specificatie_label: specificationOptionGroup.label
      };
    });
  };

  return (
    <I18n>
      {({ i18n }) => (
        <div
          key={specificationOptionGroup.type}
          className="step-products__left__products__product-detail__option-selects"
        >
          <label>{specificationOptionGroup.label}</label>
          <Field
            key={specificationOptionGroup.type}
            component={Select}
            required
            placeholder={i18n._(t`Make a selection...`)}
            onChangeHook={(e) => customOnChange(e)}
            options={filterOptions(specificationOptionGroup)}
            value={chosenSpecifications[specificationOptionGroup.type] ? chosenSpecifications[specificationOptionGroup.type] : null}
            error={meta.touched && form.errors && form.errors[categoryId] && form.errors[categoryId].specifications[specificationOptionGroup.type]}
          />
        </div>
      )}
    </I18n>
  );
}