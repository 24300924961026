import { useField } from "formik";
import React from "react";
import { Fade } from "../Fade/Fade";

import "./Pack.scss";

const Pack = ({ pack, customOnChange, customChecked, ...props }) => {

  const [{ onChange, checked, ...field }] = useField({ ...props, type: 'checkbox' });

  return (
    <Fade classNames="fade-from-bottom">
      <div className="pack">
        <label className="pack__label">
          <input
            className="pack__input"
            type="checkbox"
            {...field}
            checked={customChecked ? customChecked : checked}
            onChange={customOnChange ? (e) => customOnChange(e) : onChange}
          />
          <span className="pack__input__checkmark"></span>
          <div className="pack__content">
            <span className="pack__content__variant">
              <div>{`${pack.categorie_id} ${pack.materiaal_id}`}</div>
            </span>
          </div>
        </label>
      </div>
    </Fade>
  );
};

export default Pack;