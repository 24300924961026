import React, { useEffect, useState } from "react";
import { Modal, Button, Select, TextArea, Fade } from "../../_General";
import { connect } from "react-redux";
import { useFetch } from "../../Utilities/useFetch";
import { Trans, t } from "@lingui/macro";
import { Formik, Form, Field } from "formik";

import "./ChangeCompanyDetailsModal.scss";
import { I18n } from "@lingui/react";

const ChangeCompanyDetailsModal = ({
  open,
  onClose,
  contactId,
  clientHebicId,
  language
}) => {
  const [{ loading }, doFetch] = useFetch();
  const [isSuccesful, setIsSuccesful] = useState(false);
  const [webformOptions, setWebformOptions] = useState();
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count > 0) {
      getWebformInfo();
    }
  }, [language])

  useEffect(() => {
    getWebformInfo();
    setCount(count + 1);
  }, []);

  useEffect(() => {
    if (isSuccesful) {
      setTimeout(() => {
        onClose();
        setIsSuccesful(false);
      }, 2000);
    }
  }, [isSuccesful]);

  const getWebformInfo = () => {
    doFetch({
      method: "GET",
      url: "hebic/json/configurator-webform-info",
      result: (res) => setWebformOptions(res.data),
    });
  };

  const sendRequest = (values) => {
    doFetch({
      method: "POST",
      url: "hebic/json/configurator-webform-submit",
      data: {
        type: values.type.value,
        text: values.text,
        contact_hebic_id: contactId,
        client_hebic_id: clientHebicId,
      },

      result: (res) => {
        if (res.status === 200) {
          setIsSuccesful(true);
        }
      },
    });
  };

  return (
    <I18n>
      {({ i18n }) => (
        <Modal title={"Change Company Details"} open={open} onClose={onClose}>
          <div className="change-company-details-modal">
            <Formik
              initialValues={{
                type: "",
                text: "",
              }}
              validate={() => {
              }}
              onSubmit={(values) => {
                sendRequest(values);
              }}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <Field
                    component={Select}
                    label={i18n._(t`What do you want to change?`)}
                    options={webformOptions?.fields[0].options}
                    onChangeHook={(e) => setFieldValue("type", e)}
                    placeholder={i18n._(t`Select...`)}
                  />
                  {values.type !== "" && (
                    <Field
                      component={TextArea}
                      name="text"
                      label={i18n._(t`Relevant information to make the change`)}
                    />
                  )}
                  <div className="input__button">
                    <div>
                      {isSuccesful && (
                        <Fade classNames="fade-in">
                          <div className="notification">
                            <Trans>
                              We will process your request as soon as possible.
                            </Trans>
                          </div>
                        </Fade>
                      )}
                    </div>
                    <Button
                      size="medium"
                      color="green"
                      disabled={loading || values.text === ""}
                      loader={loading}
                    >
                      <Trans>Send change request</Trans>
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
    </I18n>
  );
};

const mapStateToProps = (state) => {
  return {
    contactId: state.allClients.hebic_id,
    clientHebicId: state.currentClient.id,
    language: state.language
  };
};

export default connect(mapStateToProps)(ChangeCompanyDetailsModal);
