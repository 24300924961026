import React from "react";
import { Modal, Button } from "../../_General";
import { useFetch } from "../../Utilities/useFetch";
import { Trans } from "@lingui/macro";
import { I18n } from "@lingui/react";
import { t } from "@lingui/macro";
import "./DeleteModal.scss";

const DeleteModal = ({ configuration: { id, naam }, open, onClose, history }) => {
  const [{ loading }, doFetch] = useFetch();

  const handleDelete = () => {
    doFetch({
      method: "GET",
      url: `hebic/json/configuratie/${id}/delete`,
      result: res => {
        if (res.status === 200) {
          return history.push({
            pathname: "/configurations",
            state: { deletedConfiguration: naam }
          });
        }
      }
    });
  };

  return (
    <I18n>
    {({ i18n }) => (
      <Modal title={i18n._(t`Delete configuration`)} open={open} onClose={onClose}>
        <span>
          <Trans>Are you sure that you want to delete configuration</Trans>
        </span>
        <p className="deleted-config">{naam}</p>
        <Button onClick={handleDelete} disabled={loading}>
          <Trans>Delete configuration</Trans>
        </Button>
      </Modal>
    )}
    </I18n>
  );
};

export default DeleteModal;
