import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";

export const Fade = ({ children, classNames, inProp }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      <CSSTransition in={inProp ? inProp : mounted} timeout={0} classNames={classNames}>
        {children}
      </CSSTransition>
    </>
  );
};
