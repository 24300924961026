import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import onClickOutside from "react-onclickoutside";
import { I18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";

import {
  resetPartialCurrConfig,
  resetCategories,
  setModel,
  setCurrConfigCarModel,
  setCurrConfigSpecGroups,
  resetAllowedSteps,
  resetAllProducts,
  setSpecificationGroupPerModel
} from "../../../../redux/action-creators";
import { Button, Fade } from "../../../_General";
import { useFetch } from "../../../Utilities/useFetch";
import Close from "../../../../assets/close.svg";
import LengthOption from "./LengthOption";
import HeightOption from "./HeightOption";
import Silhouet from "./Silhouet";
import SpecificationOption from "./SpecificationOption";

const ConfiguratorModelModal = ({
  model: {
    model_id: modelId,
    model_label: modelLabel,
    lengte_opties: lengthOptions,
    model_jaar: modelYear
  },
  brand,
  resetCategories,
  resetPartialCurrConfig,
  setIsModelClicked,
  setCurrConfigCarModel,
  setCurrConfigSpecGroups,
  setSpecificationGroupPerModel,
  resetAllowedSteps,
  resetAllProducts,
  setModel,
  history,
  currentConfig,
  carModelOptions
}) => {
  const [{}, doFetch] = useFetch();
  const carModel = carModelOptions[brand.id][modelId];

  const handleClose = () => {
    setModel("");
    setIsModelClicked(false);
  };

  ConfiguratorModelModal.handleClose = () => setIsModelClicked(false);

  const handleEsc = event => {
    if (event.keyCode === 27) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEsc);
    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, []);

  const getSpecifications = values => {
    doFetch({
      method: "POST",
      url: "hebic/json/automodel/automodel-met-specificatie-opties",
      data: {
        merk_id: brand.id,
        model_id: modelId,
        hoogte_id: values.heightOption.id,
        lengte_id: values.lengthOption.id
      },
      result: res => {
        const specificationOptions = {
          aandrijving_opties: res?.data?.aandrijving_opties,
          deuren_vooraan_opties: res?.data?.deuren_vooraan_opties,
          deuren_achteraan_opties: res?.data?.deuren_achteraan_opties,
          afwerking_vooraan_opties: res?.data?.afwerking_vooraan_opties,
          afwerking_achteraan_opties: res?.data?.afwerking_achteraan_opties,
          bevestiging_vracht_opties: res?.data?.bevestiging_vracht_opties
        };

        setSpecificationGroupPerModel(brand.id, modelId, specificationOptions);
      }
    });
  };

  return (
    <I18n>
      {({ i18n }) => (
        <div className={`model-modal`}>
          <button className="model-modal__close-button" onClick={handleClose}>
            <img src={Close} alt="" />
          </button>
          <div className="model-modal__options">
            <Formik
              initialValues={{
                lengthOption:
                  modelId === currentConfig.automodel.model_id ? { id: currentConfig.automodel.lengte_id, label: currentConfig.automodel.lengte_id } : "",
                heightOption:
                  modelId === currentConfig.automodel.model_id ? { id: currentConfig.automodel.hoogte_id, label: currentConfig.automodel.hoogte_id } : "",
                engine: modelId === currentConfig.automodel.model_id ? currentConfig.automodel.aandrijving : {},
                doorsFront: modelId === currentConfig.automodel.model_id ? currentConfig.automodel.deuren_vooraan : {},
                doorsBack: modelId === currentConfig.automodel.model_id ? currentConfig.automodel.deuren_achteraan : {},
                finishFront: modelId === currentConfig.automodel.model_id ? currentConfig.automodel.afwerking_vooraan : {},
                finishBack: modelId === currentConfig.automodel.model_id ? currentConfig.automodel.afwerking_achteraan :{}
              }}
              validateOnBlur={true}
              validate={values => {
                const errors = {};

                if (values.lengthOption === "") {
                  errors.lengthOption = "Required";
                }
                if (values.heightOption === "") {
                  errors.heightOption = "Required";
                }

                // We get the specifications for the given length option 
                // and height option here since I do not see a better way to do it.
                if (!errors.lengthOption && !errors.heightOption) {
                  getSpecifications(values);
                }

                if (carModel.aandrijving_opties?.length > 1 && !Object.keys(values["engine"]).length > 0 ) {
                  errors.engine = "Required";
                }
                if (
                  carModel.deuren_vooraan_opties?.length > 1 &&
                  !Object.keys(values["doorsFront"]).length > 0
                ) {
                  errors.doorsFront = "Required";
                }
                if (
                  carModel.deuren_achteraan_opties?.length > 1 &&
                  !Object.keys(values["doorsBack"]).length > 0
                ) {
                  errors.doorsBack = "Required";
                }
                if (
                  carModel.afwerking_vooraan_opties?.length > 1 &&
                  !Object.keys(values["finishFront"]).length > 0
                ) {
                  errors.finishFront = "Required";
                }
                if (
                  carModel.afwerking_achteraan_opties?.length > 1 &&
                  !Object.keys(values["finishBack"]).length > 0
                ) {
                  errors.finishBack = "Required";
                }

                return errors;
              }}
              onSubmit={values => {
                resetPartialCurrConfig();
                resetCategories();
                resetAllowedSteps();
                resetAllProducts();
                setCurrConfigCarModel({
                  merk_id: brand.id,
                  model_id: modelId,
                  hoogte_id: values.heightOption.id,
                  lengte_id: values.lengthOption.id,
                  aandrijving: values.engine,
                  deuren_vooraan: values.doorsFront,
                  deuren_achteraan: values.doorsBack,
                  afwerking_vooraan: values.finishFront,
                  afwerking_achteraan: values.finishBack
                });

                const filteredValues = Object.keys(values).reduce((acc, el) => {
                  if (el !== "lengthOption" && el !== "heightOption") {
                    acc[el] = values[el];
                  }
                  return acc;
                }, {});

                setCurrConfigSpecGroups(filteredValues);
                history.push("/configurator/products");
              }}>
              {({ setFieldValue, setValues, setFieldTouched, values }) => {
                return (
                  <Form>
                    <div className="model-modal__image">
                      <Silhouet
                        brand={brand.id}
                        model={modelId}
                        height={values.lengthOption && values.heightOption.id}
                        length={values.heightOption && values.lengthOption.id}
                      />
                    </div>
                    <div className="model-modal__options__top">
                      <div className="model-modal__options__top__info">
                        <span className="model-modal__options__top__info__model-name">{modelLabel}</span>
                        <span>
                          <Trans>Year </Trans> {modelYear}
                        </span>
                      </div>
                      <div className="model-modal__options__top__choices">
                        <LengthOption
                          name="lengthOption"
                          options={lengthOptions}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                        />
                        <HeightOption
                          name="heightOption"
                          options={values.lengthOption && values.lengthOption.hoogte_opties }
                          isLengthOptionChosen={values.lengthOption !== ""}
                          setValues={setValues}
                          setFieldTouched={setFieldTouched}
                          values={values}
                        />
                      </div>
                    </div>
                    {((carModel.active) && Object.keys(values.heightOption).length > 0 && Object.keys(values.lengthOption).length > 0) && (
                      <>
                        <div className="model-modal__options__divider"/>
                        <Fade classNames="fade-in">
                          <div className="fade-in__wrapper">
                            <div className="model-modal__options__bottom">
                              {carModel.aandrijving_opties?.length > 0 && (
                                <SpecificationOption
                                  name="engine"
                                  label={i18n._(t`Engine`)}
                                  defaultValue={modelId === currentConfig.automodel.model_id && currentConfig.specificatie_optie_groepen["doorsFront"]}
                                  options={carModel.aandrijving_opties}
                                  setFieldValue={setFieldValue}
                                  setFieldTouched={setFieldTouched}
                                ></SpecificationOption>
                              )}
                              {(carModel.deuren_vooraan_opties?.length > 1 || carModel.deuren_achteraan_opties?.length > 1) && (
                                  <div className="model-modal__options__bottom__title-wrapper">
                                    <span className="model-modal__options__bottom__title">
                                      <Trans>Doors</Trans>
                                    </span>
                                  </div>
                                )}
                              <div className="model-modal__options__bottom__choices">
                                {carModel.deuren_vooraan_opties?.length > 0 &&
                                  <SpecificationOption
                                    name="doorsFront"
                                    label={i18n._(t`Front`)}
                                    defaultValue={modelId === currentConfig.automodel.model_id && currentConfig.specificatie_optie_groepen["doorsFront"]}
                                    options={carModel.deuren_vooraan_opties}
                                    setFieldValue={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                  ></SpecificationOption>
                                }
                                {carModel.deuren_achteraan_opties?.length > 0 &&
                                  <SpecificationOption
                                    name="doorsBack"
                                    label={i18n._(t`Back`)}
                                    defaultValue={modelId === currentConfig.automodel.model_id && currentConfig.specificatie_optie_groepen["doorsBack"]}
                                    options={carModel.deuren_achteraan_opties}
                                    setFieldValue={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                  ></SpecificationOption>
                                }
                              </div>
                              {(carModel.afwerking_vooraan_opties?.length > 1 || carModel.afwerking_achteraan_opties?.length > 1) && (
                                  <div className="model-modal__options__bottom__title-wrapper">
                                    <span className="model-modal__options__bottom__title">
                                      <Trans>Finish</Trans>
                                    </span>
                                  </div>
                                )}
                              <div className="model-modal__options__bottom__choices">
                                {carModel.afwerking_vooraan_opties?.length > 0 &&
                                  <SpecificationOption
                                    name="finishFront"
                                    label={i18n._(t`Front`)}
                                    defaultValue={modelId === currentConfig.automodel.model_id && currentConfig.specificatie_optie_groepen["finishFront"]}
                                    options={carModel.afwerking_vooraan_opties}
                                    setFieldValue={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                  ></SpecificationOption>
                                }
                                {carModel.afwerking_achteraan_opties?.length > 0 &&
                                  <SpecificationOption
                                    name="finishBack"
                                    label={i18n._(t`Back`)}
                                    defaultValue={modelId === currentConfig.automodel.model_id && currentConfig.specificatie_optie_groepen["finishBack"]}
                                    options={carModel.afwerking_achteraan_opties}
                                    setFieldValue={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                  ></SpecificationOption>
                                }
                              </div>
                            </div>
                            <div className="model-modal__options__button">
                              <Button size="lg" type="submit">
                                <Trans>Confirm</Trans>
                              </Button>
                            </div>
                          </div>
                        </Fade>
                      </>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      )}
    </I18n>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => ConfiguratorModelModal.handleClose
};

const mapStateToProps = state => {
  return {
    currentConfig: state.currentConfig,
    carModelOptions: state.carModelOptions
  };
};

const newConfiguratorModelModal = withRouter(
  connect(mapStateToProps, {
    resetCategories,
    resetPartialCurrConfig,
    setModel,
    setCurrConfigCarModel,
    setCurrConfigSpecGroups,
    setSpecificationGroupPerModel,
    resetAllowedSteps,
    resetAllProducts
  })(ConfiguratorModelModal)
);

export default onClickOutside(newConfiguratorModelModal, clickOutsideConfig);
