import React, { useState, useEffect } from "react";
import { Trans } from "@lingui/macro";
import { connect } from "react-redux";
import { resetFullCurrConfig, resetCategories, resetAllowedSteps, setAllOrders } from "../../../redux/action-creators";
import { Button, CartLink } from "../../_General";
import { useFetch } from "../../Utilities/useFetch";
import OrdersFiltered from "./OrdersFiltered";

import "./Orders.scss";

const Orders = ({
  history,
  resetFullCurrConfig,
  resetCategories,
  resetAllowedSteps,
  setAllOrders,
  currentClientId,
  allOrders,
  language
}) => {
  const [{ loading }, doFetch] = useFetch();
  const [activeFilter, setActiveFilter] = useState("ongoing");
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count > 0) {
      getOrders();
    }
  }, [language])

  useEffect(() => {
    window.scrollTo(0, 0);
    getOrders();
    setCount(count + 1);
  }, []);

  const getOrders = () => {
    doFetch({
      method: "GET",
      url: `hebic/json/klant/${currentClientId}/orders`,
      result: res => {
        const ordersByDescId = res.data.sort((a, b) => parseInt(b.id.split("/")[1]) - parseInt(a.id.split("/")[1]));
        const obj = {};
        for (let order of ordersByDescId) {
          obj[order.id] = order;
        }
        setAllOrders(obj);
      }
    });
  }

  return (
    <div className="orders">
      <div className="orders__header">
        <h1>
          <Trans>Orders</Trans>
        </h1>
        <Button
          onClick={() => {
            resetFullCurrConfig();
            resetCategories();
            resetAllowedSteps();
            history.push("/configurator/model");
          }}>
          <Trans>New order</Trans>
        </Button>
        <CartLink></CartLink>
      </div>
      <nav className="orders__switcher">
        <button
          className={`orders__switcher__button ${activeFilter === "ongoing" && "orders__switcher__button--active"}`}
          onClick={() => setActiveFilter("ongoing")}>
          <Trans>Ongoing</Trans>
        </button>
        <button
          className={`orders__switcher__button ${activeFilter === "history" && "orders__switcher__button--active"}`}
          onClick={() => setActiveFilter("history")}>
          <Trans>History</Trans>
        </button>
      </nav>
      <main className="content orders__content">
        {activeFilter === "ongoing" && (
          <OrdersFiltered
            key={"orders-ongoing"}
            history={history}
            loading={loading}
            orders={Object.values(allOrders).filter(order => order.status.toLowerCase() !== "gefactureerd")}
          />
        )}
        {activeFilter === "history" && (
          <OrdersFiltered
            key={"orders-history"}
            history={history}
            loading={loading}
            orders={Object.values(allOrders).filter(order => order.status.toLowerCase() === "gefactureerd")}
          />
        )}
      </main>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    currentClientId: state.currentClient.id,
    allOrders: state.allOrders,
    language: state.language
  };
};

export default connect(mapStateToProps, {
  resetFullCurrConfig,
  resetCategories,
  resetAllowedSteps,
  setAllOrders
})(Orders);
