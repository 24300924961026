import {
  LOGIN,
  SET_TOKENS,
  LOGOUT,
  SET_LANGUAGE,
  SET_CONFIGURATION_STEP,
  SET_CLIENTS,
  SET_CURRENT_CLIENT,
  SET_MODEL_OPTIONS,
  RESET_MODEL_OPTIONS,
  SET_CONFIGURATION_CAR_MODEL,
  SET_CONFIGURATION_SPEC_GROUPS,
  SET_CONFIGURATION_PRODUCTS,
  SET_CONFIGURATION_AMOUNT,
  SET_ALL_PRODUCTS,
  SET_ALL_CONFIGURATIONS,
  SET_SINGLE_CONFIGURATION,
  SET_PRODUCT_CATEGORIES,
  SET_PRODUCT_CATEGORY_STATUS,
  SET_CHOSEN_PACK,
  SET_CHOSEN_BRAND,
  SET_CHOSEN_MODEL,
  RESET_PARTIAL_CONFIGURATION,
  RESET_FULL_CONFIGURATION,
  RESET_CATEGORIES,
  SET_CHOSEN_PACK_PRODUCT_ITEMS,
  SET_SPECIFICATION_GROUP,
  SET_VISITED,
  SET_ALLOWED_STEP,
  RESET_ALLOWED_STEPS,
  SET_ALL_ORDERS,
  SET_SPECIFICATION_GROUP_PER_MODEL,
  CLEAR_PACK,
  RESET_CHOSEN_OPTIONS,
  RESET_CHOSEN_PRODUCTS,
  RESET_ALL_PRODUCTS,
  RESET_CHOSEN_PACK_PRODUCTS,
  SET_PHOTO_GALLERY_ITEMS,
  ADD_ORDER_ITEM,
  REMOVE_ORDER_ITEM,
  REMOVE_ORDER_ITEMS,
  SET_ORDER_ITEM_AMOUNT,
  SET_ORDER_ITEM_REFERENCE,
} from "../actions";

export const loginDetails = (
  state = {
    isLoggedIn: false,
    accessToken: "",
    refreshToken: "",
  },
  action
) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, isLoggedIn: true };
    case SET_TOKENS:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    case LOGOUT:
      return { ...state, isLoggedIn: false };
    default:
      return state;
  }
};

export const allClients = (state = [], action) => {
  switch (action.type) {
    case SET_CLIENTS:
      return action.payload;
    default:
      return state;
  }
};

export const currentClient = (state = {}, action) => {
  switch (action.type) {
    case SET_CURRENT_CLIENT:
      return action.payload;
    default:
      return state;
  }
};

export const carModelOptions = (state = {}, action) => {
  switch (action.type) {
    case SET_MODEL_OPTIONS:
      return { ...state, [action.payload.brand]: action.payload.options };
    case SET_SPECIFICATION_GROUP_PER_MODEL:
      return {
        ...state,
        [action.payload.brand]: {
          ...state[action.payload.brand],
          [action.payload.model]: {
            ...state[action.payload.brand][action.payload.model],
            ...action.payload.specificationGroups,
            active: true,
          },
        },
      };
    case RESET_MODEL_OPTIONS:
      return {};
    default:
      return state;
  }
};

export const language = (state = "nl", action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return action.lang;
    default:
      return state;
  }
};

export const currentConfigurationStep = (state = "", action) => {
  switch (action.type) {
    case SET_CONFIGURATION_STEP:
      return action.step;
    default:
      return state;
  }
};

export const currentConfig = (
  state = {
    brand: "",
    model: "",
    automodel: {
      merk_id: "",
      model_id: "",
      hoogte_id: "",
      lengte_id: "",
    },
    specificatie_optie_groepen: {},
    products: {},
    chosenOptions: {},
    packProducts: {},
    pack: {},
    amount: 1,
  },
  action
) => {
  switch (action.type) {
    case SET_CHOSEN_BRAND:
      return {
        ...state,
        brand: action.payload,
      };
    case SET_CHOSEN_MODEL:
      return {
        ...state,
        model: action.payload,
      };
    case SET_CONFIGURATION_CAR_MODEL:
      return { ...state, automodel: action.payload };
    case SET_CONFIGURATION_SPEC_GROUPS:
      return { ...state, specificatie_optie_groepen: action.payload };
    case SET_CONFIGURATION_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case RESET_CHOSEN_PRODUCTS:
      return {
        ...state,
        products: {},
      };
    case SET_CHOSEN_PACK:
      return {
        ...state,
        pack: action.payload,
      };

    case CLEAR_PACK:
      return {
        ...state,
        pack: {},
      };
    case SET_CONFIGURATION_AMOUNT:
      return {
        ...state,
        amount: action.payload,
      };
    case RESET_PARTIAL_CONFIGURATION:
      return {
        ...state,
        automodel: {
          merk_id: "",
          model_id: "",
          hoogte_id: "",
          lengte_id: "",
        },
        specificatie_optie_groepen: {},
        products: {},
        chosenOptions: {},
        pack: {},
        amount: 1,
      };
    case RESET_FULL_CONFIGURATION:
      return {
        brand: "",
        model: "",
        automodel: {
          merk_id: "",
          model_id: "",
          hoogte_id: "",
          lengte_id: "",
        },
        specificatie_optie_groepen: {},
        products: {},
        chosenOptions: {},
        pack: {},
        amount: 1,
      };
    case SET_CHOSEN_PACK_PRODUCT_ITEMS:
      return {
        ...state,
        packProducts: action.payload,
      };

    case RESET_CHOSEN_PACK_PRODUCTS:
      return {
        ...state,
        packProducts: {},
      };
    case SET_SPECIFICATION_GROUP:
      return {
        ...state,
        chosenOptions: {
          ...state.chosenOptions,
          [action.payload.category]: {
            ...state.chosenOptions[action.payload.category],
            [action.payload.specificationGroupType]:
              action.payload.specificationGroup,
          },
        },
      };
    case RESET_CHOSEN_OPTIONS:
      return {
        ...state,
        chosenOptions: {},
      };

    default:
      return state;
  }
};

export const allProducts = (state = {}, action) => {
  switch (action.type) {
    case SET_ALL_PRODUCTS:
      return action.payload;
    case RESET_ALL_PRODUCTS:
      return {};
    default:
      return state;
  }
};

export const isChosen = (state = {}, action) => {
  switch (action.type) {
    case SET_PRODUCT_CATEGORIES:
      return action.payload;
    case SET_PRODUCT_CATEGORY_STATUS:
      return {
        ...state,
        [action.payload]: !state[action.payload],
      };
    case RESET_CATEGORIES:
      return {};
    default:
      return state;
  }
};

export const allConfigurations = (state = {}, action) => {
  switch (action.type) {
    case SET_ALL_CONFIGURATIONS:
      return action.payload;
    case SET_SINGLE_CONFIGURATION:
      return {
        ...state,
        [action.payload.id]: action.payload.configuration,
      };
    default:
      return state;
  }
};

export const visitedComponents = (
  state = {
    model: false,
    products: false,
    order: false,
  },
  action
) => {
  switch (action.type) {
    case SET_VISITED:
      return {
        ...state,
        [action.payload]: true,
      };
    default:
      return state;
  }
};

export const allowedSteps = (
  state = {
    products: false,
    order: false,
  },
  action
) => {
  switch (action.type) {
    case SET_ALLOWED_STEP:
      return {
        ...state,
        [action.payload]: true,
      };
    case RESET_ALLOWED_STEPS:
      return {
        products: false,
        order: false,
      };
    default:
      return state;
  }
};

export const allOrders = (state = {}, action) => {
  switch (action.type) {
    case SET_ALL_ORDERS:
      return action.payload;
    default:
      return state;
  }
};

export const photoGallery = (state = {}, action) => {
  switch (action.type) {
    case SET_PHOTO_GALLERY_ITEMS:
      return action.payload;
    default:
      return state;
  }
};

export const orderItems = (state = [], action) => {
  switch (action.type) {
    case ADD_ORDER_ITEM:
      return [...state, action.payload];
    case REMOVE_ORDER_ITEM:
      return state.filter(orderItem => orderItem.naam !== action.payload);
    case REMOVE_ORDER_ITEMS:
      return [];
    case SET_ORDER_ITEM_AMOUNT:
      return state.map(orderItem => {
        if (orderItem.naam === action.payload.name) {
          return { ...orderItem, aantal: action.payload.amount };
        }
        return orderItem;
      })
    case SET_ORDER_ITEM_REFERENCE:
      return state.map(orderItem => {
        if (orderItem.naam === action.payload.name) {
          return { ...orderItem, referentie: action.payload.reference };
        }
        return orderItem;
      })
    default:
      return state;
  }
}