import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import { FieldGroup, Input, Button, Fade } from "../../_General";
import { I18n } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import { useFetch } from "../../Utilities/useFetch";

const AccountPersonalDetails = ({ userInfo }) => {
  const [{ loading }, doFetch] = useFetch();
  const [responseSuccess, setResponseSuccess] = useState();
  const [responseFail, setResponseFail] = useState();

  const handleValidate = values => {
    const errors = {};

    if (values.password.length > 0 && values.password.length < 8) {
      errors.passwordTooShort = true;
    }

    if (values.password !== values.confirmPassword) {
      errors.noMatch = "No match";
    }
    if (values.password === values.confirmPassword) {
      delete errors.noMatch;
    }
    return errors;
  };

  const handleSubmit = (values, setSubmitting) => {
    const data = {
      name: values.name,
      email: values.email,
      tel: values.tel
    };

    if (values.password.length > 0) {
      data.password = values.password;
      data.confirm_password = values.confirmPassword;
    }

    doFetch({
      method: "PATCH",
      url: "hebic/json/contact-update",
      data,
      result: res => {
        if (res.status === 200) {
          setResponseSuccess("Password updated successfully.");
          setSubmitting(false);
        } else {
          setResponseFail("Failed to update password.");
          setSubmitting(false);
        }
      }
    });
  };

  return (
    <I18n>
      {({ i18n }) => (
        <Formik
          initialValues={{
            name: userInfo?.naam ? userInfo?.naam : "",
            tel: userInfo?.adressen[0].telefoon ? userInfo?.adressen[0].telefoon : "",
            email: userInfo?.email ? userInfo?.email : "",
            password: "",
            confirmPassword: ""
          }}
          validate={values => handleValidate(values)}
          onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}>
          {({ isSubmitting, values, errors }) => (
            <Form>
              <div className="account__content__personal-details">
                <h3>
                  <Trans>Personal Details </Trans>
                </h3>
                <FieldGroup>
                  <Field
                    component={Input}
                    label={i18n._(t`Name`)}
                    name="name"
                    required
                    value={values.name}
                    disabled={true}
                  />
                </FieldGroup>
                <FieldGroup>
                  <Field
                    component={Input}
                    label={i18n._(t`Telephone number`)}
                    name="tel"
                    required
                    value={values.tel}
                    disabled={true}
                  />
                  <Field
                    component={Input}
                    label={i18n._(t`Email address`)}
                    name="email"
                    required
                    value={values.email}
                    disabled={true}
                  />
                </FieldGroup>
                <FieldGroup>
                  <Field
                    component={Input}
                    label={i18n._(t`New password`)}
                    type={"password"}
                    name="password"
                    error={
                      (errors.noMatch && (values.password !== "" || values.confirmPassword !== "")) ||
                      errors.passwordTooShort
                    }
                    comment={i18n._(t`Leave empty if you don't want a new password.`)}
                  />
                  <Field
                    component={Input}
                    label={i18n._(t`Confirm password`)}
                    type={"password"}
                    error={errors.noMatch && (values.password !== "" || values.confirmPassword !== "")}
                    name="confirmPassword"
                  />
                </FieldGroup>
                {errors.passwordTooShort && (
                  <Fade classNames="fade-in">
                    <div className="error-notification">Password too short (min. 6 characters)</div>
                  </Fade>
                )}
                {errors.noMatch && (values.password !== "" || values.confirmPassword !== "") && (
                  <Fade classNames="fade-in">
                    <div className="error-notification">Password and confirmed password need to be the same.</div>
                  </Fade>
                )}
              </div>
              <Button size="m" color="grey" disabled={isSubmitting || values.password === ""} loader={loading}>
                <Trans>Save password</Trans>
              </Button>
              {responseSuccess && (
                <Fade classNames="fade-in">
                  <div className="notification">{responseSuccess}</div>
                </Fade>
              )}
              {responseFail && (
                <Fade classNames="fade-in">
                  <div className="error-notification">{responseFail}</div>
                </Fade>
              )}
            </Form>
          )}
        </Formik>
      )}
    </I18n>
  );
};

const mapStateToProps = state => {
  return {
    userInfo: state.allClients,
    currentClient: state.currentClient
  };
};

export default connect(mapStateToProps)(AccountPersonalDetails);
