import React, { useState } from "react";
import { Trans } from "@lingui/macro";
import { connect } from "react-redux";
import { I18n } from "@lingui/react";
import { t } from "@lingui/macro";

import { setCurrConfigAmount, setSingleConfiguration } from "../../../redux/action-creators";
import ConfiguratorStepOrdersTable from "../Configurator/StepOrders/ConfiguratorStepOrdersTable";
import { useFetch } from "../../Utilities/useFetch";
import { Field, Formik, Form } from "formik";
import { Input, InputNumber, FieldGroup, Price, InputRadio, Button, Fade, ArrowBack } from "../../_General";

import "./ConfigurationsOrder.scss";

const ConfigurationsOrder = ({
  allConfigurations,
  match: {
    params: { id }
  },
  amount,
  currentClient,
  history,
  carModelOptions
}) => {
  const [configuration] = useState(allConfigurations[id]);
  const [isUnsuccessful, setIsUnsuccessful] = useState(false);
  const [{}, doFetch] = useFetch();


  return (
    <I18n>
      {({ i18n }) => (
        <div className="configurations-order">
          <header className="configurations-order__header">
            <h1>
              <ArrowBack history={history} />
              <Trans>Order Configuration</Trans> {configuration.naam}
            </h1>
          </header>
          <main className="content configurations-order__content">
            <div className="configurations-order__content__left">
              <h2 className="step-orders__title">
                <Trans>Place Order</Trans>
              </h2>
              <div className="step-orders__model-info">
                <div className="step-orders__model-info__item">
                  <span className="step-orders__model-info__item__label">
                    <Trans>Brand</Trans>
                  </span>
                  <span className="step-orders__model-info__item__value">{configuration.automodel.merk_id}</span>
                </div>
                <div className="step-orders__model-info__item">
                  <span className="step-orders__model-info__item__label">
                    <Trans>Model</Trans>
                  </span>
                  <span className="step-orders__model-info__item__value">
                    {carModelOptions[configuration.automodel.merk_id][configuration.automodel.model_id].model_label}
                  </span>
                </div>
                <div className="step-orders__model-info__item">
                  <span className="step-orders__model-info__item__label">
                    <Trans>Year</Trans>
                  </span>
                  <span className="step-orders__model-info__item__value">
                    {carModelOptions[configuration.automodel.merk_id][configuration.automodel.model_id].model_jaar}
                  </span>
                </div>
              </div>
              <div className="configurations-order__content__left__main">
                <div className="orders-table">
                  <h4 className="orders-table__title">
                    <Trans>Configuration Car</Trans>
                  </h4>
                  <table className="orders-table__tables__table">
                    <tbody>
                      <tr className="orders-table__tables__table__row">
                        <td className="orders-table__tables__table__row__label">
                          <Trans>Length</Trans>
                        </td>
                        <td className="orders-table__tables__table__row__value">{configuration.automodel.lengte_id}</td>
                      </tr>
                      <tr className="orders-table__tables__table__row">
                        <td className="orders-table__tables__table__row__label">
                          <Trans>Height</Trans>
                        </td>
                        <td className="orders-table__tables__table__row__value">{configuration.automodel.hoogte_id}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  {configuration?.product_items.map(productItem => {
                    if (productItem.item_type !== "Samengesteld" && productItem.item_groep !== 'Montage') {
                      return (
                        <ConfiguratorStepOrdersTable
                          key={productItem.id}
                          title={productItem.categorie_label}
                          product={productItem}
                        />
                      );
                    }
                  })}
                </div>
              </div>
              <div className="configurations-order__extra">
                <div className="configurations-order__extra__empty"/>
                <div className="configurations-order__extra__filled">
                  <Price
                    calculatePrice={() => {
                      return (parseFloat(configuration.totaalprijs)).toFixed(2);
                    }}
                  />
                  <div className="configurations-order__extra__filled__button-notification-wrapper">
                    <Button size="s" type="button">
                      <Trans>Order</Trans>
                    </Button>
                    {isUnsuccessful && (
                      <Fade classNames="fade-in">
                        <span className="error-notification">
                          <Trans>Order failed. Please contact Hebic for more info.</Trans>
                        </span>
                      </Fade>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      )}
    </I18n>
  );
};

const mapStateToProps = state => {
  return {
    allConfigurations: state.allConfigurations,
    currentClient: state.currentClient,
    amount: state.currentConfig.amount,
    carModelOptions: state.carModelOptions
  };
};

export default connect(mapStateToProps, { setSingleConfiguration })(ConfigurationsOrder);
