import { useField } from "formik";
import React from "react";

import "./StepProductsOptionCheckbox.scss";

const StepProductsOptionCheckbox = ({ label, ...props }) => {
  const [field] = useField({ ...props, type: 'checkbox' })

  return (
    <div className="step-products-option-checkbox">
      <div className="step-products-option-checkbox__label">{label}</div>
      <div className="step-products-option-checkbox__input">
        <label className="checkbox">
          <input
            type="checkbox"
            className="checkbox__input"
            {...field}
          />
          <span className="checkbox__checkmark"></span>
        </label>
      </div>
    </div>
  )
}

export default StepProductsOptionCheckbox;