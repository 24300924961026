import React from "react";
import { I18n } from "@lingui/react";
import { t } from "@lingui/macro";

import "./SearchBar.scss";
import SearchIcon from "../../../assets/icon-grey-search.svg";

export const SearchBar = ({ dataToFilter, keys, callback }) => {
  const filterData = input => {
    let filteredData = {};

    setTimeout(() => {
      keys.forEach(key => {
        for (const item of dataToFilter) {
          if (item[key] !== null && item[key].includes(input)) {
            filteredData[item.id] = item;
          }
        }
      });

      callback(filteredData);
    }, 500);
  };

  return (
    <I18n>
      {({ i18n }) => (
        <div className="searchbar">
          <img className="searchbar__icon" src={SearchIcon} alt="search icon" />
          <input
            className="searchbar__input"
            type="text"
            placeholder={i18n._(t`Search...`)}
            onChange={e => filterData(e.nativeEvent.target.value)}></input>
        </div>
      )}
    </I18n>
  );
};
