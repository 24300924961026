import React from "react";
import { Trans } from "@lingui/macro";
import { ConnectedHelp, ConnectedLanguages, Button } from "../../_General";
import { connect } from "react-redux";
import Logo from "../../../assets/logo.svg";
import { logout } from "../../../redux/action-creators";
import { withRouter } from "react-router";

const PlatformMenuBottom = ({ handleLogout }) => {
  return (
    <div className="platform__sidebar__bottom">
      <div className="platform__sidebar__bottom__help">
        <ConnectedHelp />
      </div>
      <div className="platform__sidebar__bottom__languages">
        <ConnectedLanguages />
      </div>
      <a className="platform__sidebar__bottom__logo" href="https://www.hebic.be/">
        <img src={Logo} alt="Logo" />
      </a>
        <Button className="btn-lg platform__sidebar__bottom__logout" onClick={handleLogout}>
          <Trans>Logout</Trans>
        </Button>
    </div>
  );
};

export default withRouter(connect(null, { logout })(PlatformMenuBottom));
