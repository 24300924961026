import React from "react";
import SpinnerIcon from "../../../assets/hebic-spinner.svg";
import "./Spinner.scss";

export const Spinner = ({ width }) => {
  return (
    <img
      src={SpinnerIcon}
      style={{ width: `${width}` }}
      className="spinner"
      alt="spinner"
    />
  );
};
