import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { Fade, Spinner } from "../_General";
import { setClients, setCurrentClient, setLanguage } from "../../redux/action-creators";
import { connect } from "react-redux";
import Logo from "../../assets/logo.svg";
import { Trans } from "@lingui/macro";
import { Button, ConnectedHelp, ConnectedLanguages } from "../_General";
import { useFetch } from "../Utilities/useFetch";
import LogoPlaceholder from "../../assets/silhouet-placeholder.svg";
import "./ChooseClient.scss";

const ChooseClient = ({ allClients, setCurrentClient, setClients, setLanguage, history }) => {
  const [chosenClient, setChosenClient] = useState();
  const [{ loading }, doFetch] = useFetch();

  useEffect(() => {
    doFetch({
      method: "GET",
      url: "hebic/json/contact-info",
      result: res => {
        setLanguage(res.data.langcode);
        if (res.data.klanten.length === 1) {
          setClients(res.data);
          setCurrentClient(res.data.klanten[0]);
          history.push("/configurator/model");
        } else {
          return setClients(res.data);
        }
      }
    });
  }, []);

  const chooseClient = client => {
    setChosenClient(client);
  };

  const goToPlatform = () => {
    setCurrentClient(chosenClient);
    history.push("/configurator/model");
  };

  return (
    <div className="choose-client">
      <div className="choose-client__left">
        <div className="choose-client__left__top">
          <a href="https://www.hebic.be/">
            <img src={Logo} alt="" />
          </a>
          <div className="choose-client__left__top__notification">
            <Trans>Logged in as</Trans> {allClients?.naam}
          </div>
        </div>
        <div className="choose-client__left__help">
          <ConnectedHelp />
        </div>
      </div>
      <div className="choose-client__company-choice">
        <ConnectedLanguages />
        <div className="choose-client__company-choice__options">
          <div>
            <h2 className="choose-client__company-choice__options__title">
              <Trans>Choose company</Trans>
            </h2>
            <div className="choose-client__company-choice__options__wrapper">
              {/* <Slider {...slickSettings}> */}
              {!loading ? (
                allClients?.klanten?.map(client => {
                  return <Client key={client.id} client={client} chooseClient={chooseClient} />;
                })
              ) : (
                <Spinner width="5rem" />
              )}
              {/* </Slider> */}
            </div>
          </div>
          <CSSTransition in={!!chosenClient} timeout={200} classNames="fade-in" unmountOnExit>
            <div className="choose-client__company-choice__options__submit">
              <Button size="lg" onClick={goToPlatform}>
                <Trans>Continue as</Trans> {chosenClient?.label}
              </Button>
              <span>
                <Trans>You will be redirected to your chosen company in 10 seconds.</Trans>
              </span>
            </div>
          </CSSTransition>
        </div>
      </div>
    </div>
  );
};

const Client = ({ client, chooseClient }) => {
  return (
    <Fade classNames="fade-from-right">
      <button
        key={client.id}
        className="choose-client__company-choice__options__option"
        onClick={() => chooseClient(client)}>
        <img src={client.image || LogoPlaceholder} alt={client.label} />
      </button>
    </Fade>
  );
};

const mapStateToProps = state => {
  return {
    isLoggedIn: state.loginDetails.isLoggedIn,
    accessToken: state.loginDetails.accessToken,
    allClients: state.allClients
  };
};

export default connect(mapStateToProps, {
  setClients,
  setCurrentClient,
  setLanguage
})(ChooseClient);
