import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Trans } from "@lingui/macro";

import {
  setConfigurationStep,
  setCurrConfigAmount,
  setAllowedStep,
  addOrderItem
} from "../../../../redux/action-creators";
import { useFetch } from "../../../Utilities/useFetch";
import { Button, Price, SaveConfigurationModal } from "../../../_General";

import ConfigurationIcon from "../../../../assets/icon-grey-configuration.svg";

import "./ConfiguratorStepOrders.scss";
import ConfiguratorStepOrdersTable from "./ConfiguratorStepOrdersTable";

const ConfiguratorStepOrders = ({
  setConfigurationStep,
  setAllowedStep,
  addOrderItem,
  carModelOptions,
  currentClient,
  history,
  language,
  currentConfig: { 
    automodel: carModel,
    products: chosenProducts,
    pack: chosenPack,
    packProducts
   }
}) => {
  const [showSaveConfigurationModal, setShowConfigurationModal] = useState(false);
  const [{}, doFetch] = useFetch();
  const [configuration, setConfiguration] = useState();
  const [count, setCount] = useState(0);

  useEffect(() => {
    createConfiguration();
    setConfigurationStep(3);
    setAllowedStep("order");
    window.scrollTo(0, 0);
    setCount(count + 1);
  }, []);

  useEffect(() => {
    if (count > 0) {
      createConfiguration();
      setConfigurationStep(3);
      setAllowedStep("order");
      window.scrollTo(0, 0);
    }
  }, [language]);

  const createProductItemsArray = () => {
    let products;
    if (!chosenPack.id) {
      products = chosenProducts;
    } else {
      products = packProducts;
    }
    let productItems = [];
    for (let product of Object.values(products)) {
      const productItem = {
        id: product.id,
        specificaties: Object.values(product.specifications),
        withAssembly: product.withAssembly
      };
      productItems.push(productItem);
    }

    if (chosenPack.id) {
      productItems.push({ id: chosenPack.id });
    }

    return productItems;
  };

  const createConfiguration = () => {
    const data = {
      klant_id: currentClient.id,
      automodel: {
        klant_id: currentClient.id,
        merk_id: carModel?.merk_id?.toUpperCase(),
        model_id: carModel?.model_id?.toUpperCase(),
        hoogte_id: carModel?.hoogte_id,
        lengte_id: carModel?.lengte_id,
        aandrijving_id: carModel.aandrijving ? carModel.aandrijving.waarde : "0",
        deuren_vooraan_id: carModel.deuren_vooraan ? carModel.deuren_vooraan.waarde : "0",
        deuren_achteraan_id: carModel.deuren_achteraan ? carModel.deuren_achteraan.waarde : "0",
        afwerking_vooraan_id: carModel.afwerking_vooraan ? carModel.afwerking_vooraan.waarde : "0",
        afwerking_achteraan_id: carModel.afwerking_achteraan ? carModel.afwerking_achteraan.waarde : "0"
      },
      product_items: createProductItemsArray(),
      aantal: 1
    };

    doFetch({
      method: "POST",
      url: "hebic/json/create-configuratie",
      data,
      result: res => {
        setConfiguration(res.data);
      }
    });
  };

  /**
   * Add the configuration to the order items.
   */
  const handleAddToShoppingCart = () => {
    addOrderItem(configuration);
    history.push('/cart');
  }

  return (
    <div className="step-orders">
      <h2 className="step-orders__title">
        <Trans>Overview configuration</Trans>
      </h2>
      <div className="step-orders__model-info">
        <div className="step-orders__model-info__item">
          <span className="step-orders__model-info__item__label">
            <Trans>Brand</Trans>
          </span>
          <span className="step-orders__model-info__item__value">{configuration?.automodel.merk_id}</span>
        </div>
        <div className="step-orders__model-info__item">
          <span className="step-orders__model-info__item__label">
            <Trans>Model</Trans>
          </span>
          <span className="step-orders__model-info__item__value">{configuration?.automodel.model_id}</span>
        </div>
        <div className="step-orders__model-info__item">
          <span className="step-orders__model-info__item__label">
            <Trans>Year</Trans>
          </span>
          <span className="step-orders__model-info__item__value">
            {carModelOptions[configuration?.automodel.merk_id] &&
              carModelOptions[configuration?.automodel.merk_id][configuration?.automodel.model_id] &&
              carModelOptions[configuration?.automodel.merk_id][configuration?.automodel.model_id]
                ?.model_jaar}
          </span>
        </div>
      </div>
      <div className="step-orders__actions">
        <button
          className="step-orders__actions__button"
          type="button"
          onClick={() => setShowConfigurationModal(true)}>
          <img className="step-orders__actions__button__icon" src={ConfigurationIcon} alt="" />
          <Trans>Save as configuration</Trans>
        </button>
      </div>
  
      <div className="step-orders__order-details">
        {configuration?.product_items.map(productItem => {
          if (productItem.item_type !== "Samengesteld" && productItem.item_groep !== 'Montage') {
            return (
              <ConfiguratorStepOrdersTable
                key={productItem.id}
                title={productItem.categorie_label}
                product={productItem}
              />
            );
          }
        })}
      </div>
      <div className="step-orders__bottom">
        <div className="step-orders__bottom__empty"/>
        <div className="step-orders__bottom__filled">
          <Price />
          <div className="step-orders__bottom__filled__button-notification-wrapper">
            <Button size="lg" type="button" onClick={handleAddToShoppingCart}>
              <Trans>Add to shopping cart</Trans>
            </Button>
          </div>
        </div>
      </div>
      <SaveConfigurationModal
        show={showSaveConfigurationModal}
        onClose={() => setShowConfigurationModal(false)}
      ></SaveConfigurationModal>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    currentConfig: state.currentConfig,
    carModelOptions: state.carModelOptions,
    currentClient: state.currentClient,
    language: state.language
  };
};

export default connect(mapStateToProps, {
  setConfigurationStep,
  setCurrConfigAmount,
  setAllowedStep,
  addOrderItem
})(ConfiguratorStepOrders);
