import React, { useState, useEffect } from "react";
import { Trans } from "@lingui/macro";

import { Spinner, Pagination, NotificationLarge, SearchBar } from "../../_General";
import MobileOrder from "./MobileOrder";

import Order from "./Order";

const OrdersFiltered = ({ loading, orders, history }) => {
  const [orderPage, setOrderPage] = useState(0);
  const [allOrders, setAllOrders] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [orderPage]);

  useEffect(() => {
    setAllOrders(orders);
  }, [orders]);

  const setOrders = result => {
    setAllOrders(Object.values(result));
  };

  return (
    <>
      <div className="orders__content__wrapper">
        <SearchBar dataToFilter={orders} keys={["id", "referentie"]} callback={setOrders} />
        <div className="orders__content__list__wrapper">
          {history.location.state && history.location.state.newOrder && (
            <NotificationLarge>
              <Trans>Thank you for your order with reference</Trans>
              <span className="order__id">{history.location.state.newOrder.id}</span>.
              <Trans>We will take the appropriate actions and contact you if necessary.</Trans>
            </NotificationLarge>
          )}
          <div className="orders__content__list">
            <div className="orders__content__list__headers">
              <div>
                <Trans>Sales order</Trans>
              </div>
              <div>
                <Trans>Date</Trans>
              </div>
            </div>

            <div className="desktop-orders">
              {loading ? (
                <Spinner width="7rem" />
              ) : (
                allOrders?.slice(orderPage * 10, orderPage * 10 + 10).map(order => {
                  return <Order key={order.id} order={order} />;
                })
              )}
            </div>

            <div className="mobile-orders">
              {loading ? (
                <Spinner width="7rem" />
              ) : (
                allOrders?.slice(orderPage * 10, orderPage * 10 + 10).map(order => {
                  return <MobileOrder key={order.id} order={order} />;
                })
              )}
            </div>
          </div>
        </div>
        {Math.floor(allOrders?.length / 10) > 1 && (
          <Pagination orderPage={orderPage} setOrderPage={setOrderPage} items={allOrders} />
        )}
      </div>
    </>
  );
};

export default OrdersFiltered;
