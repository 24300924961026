import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { t } from "@lingui/macro";
import axios from "axios";
import { Fade } from "../_General";
import qs from "qs";
import { connect } from "react-redux";
import { Trans } from "@lingui/macro";
import { login, setTokens } from "../../redux/action-creators";
import { Input, Button } from "../_General";
import { I18n } from "@lingui/react";
import "./LoginForm.scss"

const LoginForm = ({ login, setTokens, history, setIsPasswordReset }) => {
    const [errorCode, setErrorCode] = useState(undefined);

    const generateErrorMessage = () => {
        if (errorCode === 401) {
          return (
            <Fade classNames="fade-in">
              <div class="login__error">
                <Trans>Incorrect email address and/or password.</Trans>
              </div>
            </Fade>
          );
        } else {
          return (
            <Fade classNames="fade-in">
              <div className="login__error">
                <Trans>
                  There is a problem with the server. Please contact Hebic for more
                  info.
                </Trans>
              </div>
            </Fade>
          );
        }
      };

    const handleLogin = (email, password, setSubmitting) => {
        const data = {
          grant_type: "password",
          username: email,
          password: password,
          client_id: `${process.env.REACT_APP_OAUTH_CLIENT_ID}`,
          client_secret: `${process.env.REACT_APP_OAUTH_CLIENT_SECRET}`,
        };
    
        axios({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/oauth/token`,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: qs.stringify(data),
        })
          .then((res) => {
            setSubmitting(false);
            setTokens(res.data.access_token, res.data.refresh_token);
            login();
            history.push("/client");
          })
          .catch(({ response: { status } }) => {
            setSubmitting(false);
            return setErrorCode(status);
          });
      };

    const handleValidate = (values) => {
        const errors = {};
        if (!values.email) {
          errors.email = "Required";
        }
        if (!values.password) {
          errors.password = "Required";
        }
        return errors;
      };

    return (
        <I18n>
            {({ i18n }) => (
                <div className="login__form">
                    <Formik
                    initialValues={{ email: "", password: "" }}
                    validate={(values) => handleValidate(values)}
                    onSubmit={(values, { setSubmitting }) => {
                        handleLogin(values.email, values.password, setSubmitting);
                    }}
                    >
                    {({ isSubmitting, touched, errors }) => (
                        <Form>
                        <Field
                            label={i18n._(t`Email`)}
                            type="text"
                            name="email"
                            component={Input}
                            error={touched.email && errors.email}
                        />
                        <Field
                            label={i18n._(t`Password`)}
                            type="password"
                            name="password"
                            component={Input}
                            error={touched.password && errors.password}
                        />
                        <div>{errorCode && generateErrorMessage()}</div>
                        <div className="login__form__actions">
                            <button type="button" className="login__form__actions__forgot-pass" onClick={() => setIsPasswordReset(true)}>
                            <Trans>Forgot password</Trans>
                            </button>
                            <Button
                            type="submit"
                            disabled={isSubmitting}
                            loader={isSubmitting}
                            size="s"
                            >
                            <Trans>Log in</Trans>
                            </Button>
                        </div>
                        </Form>
                    )}
                    </Formik>
                </div>
             )}
        </I18n>
    );
}

const mapStateToProps = (state) => {
    return {
      accessToken: state.loginDetails.accessToken,
    };
  };
  
  export default connect(mapStateToProps, {
    login,
    setTokens,
  })(LoginForm);