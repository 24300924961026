import React from "react";
import { Trans } from "@lingui/macro";
import PersonalDetails from "./AccountPersonalDetails";
import CompanyDetails from "./AccountCompanyDetails";
import { CartLink } from "../../_General";

import "./Account.scss";

const Account = ({history}) => {
  return (
    <div className="account">
      <div className="account__header">
        <h1>
          <Trans>My account</Trans>
        </h1>
        <CartLink></CartLink>
      </div>
      <div className="content account__content">
        <div className="account__content__wrapper">
          <PersonalDetails />
          <CompanyDetails history={history} />
        </div>
      </div>
    </div>
  );
};

export default Account;
