import React from "react";
import SelectOriginal from "react-select";
import { Trans } from "@lingui/macro";

export const Select = ({
  label,
  className,
  name,
  optional,
  required,
  setFieldValue,
  options,
  defaultValue,
  placeholder,
  language,
  field,
  onChangeHook,
  onBlurHook,
  error,
  value,
}) => {
  const customStyles = {
    container: (styles) => ({
      ...styles,
      maxHeight: "3.6rem",
      maxWidth: "35rem",
    }),
    control: (styles, { isFocused }) => ({
      ...styles,
      height: language ? "4.4rem" : "3.6rem",
      fontSize: "1.4rem",
      borderRadius: "2px",
      boxShadow: isFocused
        ? "0px 0px 0px 3px rgba(0, 167, 86, 0.6)"
        : error
        ? "0px 0px 0px 3px #E0262A"
        : undefined,
      border: "1px solid #e2e7ec",
      borderLeft: language && "none",
      borderRight: language && "none",
      "&:hover": {
        border: "1px solid #e2e7ec",
      },
      transition: "box-shadow .2s ease-out",
    }),
    group: (styles) => ({
      ...styles,
    }),
    menu: (styles) => ({
      ...styles,
      display: "block",
      zIndex: 5000,
      marginTop: "1.4rem",
    }),
    valueContainer: (styles) => ({
      ...styles,
      marginBottom: "2px",
      marginLeft: language && "2.5rem",
    }),
    indicatorSeparator: () => ({ display: "none" }),
    dropdownIndicator: (styles) => ({
      ...styles,
      marginTop: "0.1rem",
      marginRight: "0.7rem",
      color: "#778594",
      "> svg": {
        width: "12px",
      },
    }),
    placeholder: (styles) => ({
      ...styles,
      marginTop: "2px",
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      backgroundColor: isSelected && "#00a756",
      color: isSelected && "#fff",
      "&:hover": {
        backgroundColor: "rgba(0, 167, 86, 0.6)",
        color: "#fff",
      },
    }),
  };

  return (
    <div className="input-group">
      {label && (
        <label className={`input-group__label ${className}`} htmlFor={name}>
          {label}
          {optional && (
            <em>
              {"("}
              <Trans>optional</Trans>
              {")"}
            </em>
          )}
          {required && <span>*</span>}
        </label>
      )}
      <SelectOriginal
        placeholder={placeholder}
        styles={customStyles}
        defaultValue={defaultValue}
        options={options}
        onChange={(e) => {
          setFieldValue && setFieldValue(field, e);
          onChangeHook && onChangeHook(e);
        }}
        onBlur={onBlurHook}
        menuPlacement="auto"
        value={value && value}
      />
    </div>
  );
};
