import React from "react";
import { connect } from "react-redux";
import Modal from 'react-modal';
import { Trans } from "@lingui/macro";
import { I18n } from "@lingui/react";

import { removeOrderItem } from "../../../redux/action-creators";

import CloseIcon from "../../../assets/icon-close.svg";

import "./DeleteOrderItemModal.scss";
import { Button } from "../Button/Button";

export let DeleteOrderItemModal = ({
  configurationName,
  show = false,
  onClose,
  removeOrderItem
}) => {

  const handleClose = () => {
    onClose && onClose();
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: "0",
      boxShadow: "0px 0px 40px 0 #383b3d"
    },
  };

  const handleDelete = () => {
    removeOrderItem(configurationName);
    handleClose();
  }

  return (
    <Modal
      isOpen={show}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Delete order item modal"
    >
      <I18n>
        {({ i18n }) => (
          <div className="delete-order-item-modal">
            <div className="delete-order-item-modal__close" onClick={handleClose}>
              <img src={CloseIcon} alt="" />
            </div>
            <div className="delete-order-item-modal__title">
              <Trans>Delete configuration</Trans>
            </div>
            <div className="delete-order-item-modal__content">
              <div class="delete-order-item-modal__wrapper">
                <span className="delete-order-item-modal__confirmation">
                  <Trans>Are you sure that you want to delete this configuration?</Trans>
                </span>
                <Button onClick={handleDelete}>
                  <Trans>Delete configuration</Trans>
                </Button>
              </div>
            </div>
          </div>
        )}
        </I18n>
    </Modal>
  );
};

DeleteOrderItemModal = connect(null, { removeOrderItem })(DeleteOrderItemModal);