import React from "react";
import "./CarInternal.scss";

const CarInternal = ({ chosenItems }) => {
  return (
    <div className="car-internal">
      <svg viewBox="0 0 746 552" width="746" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
          <path
            d="m193.22 353.91c-6.15-65.13 2.78-140.19 2.78-140.19.85-7.19 5.92-5.5 5.92-5.5l45 10.15c2.887277.421404 5.089985 2.798996 5.29 5.71l.84 200c-.06 2.41-.34 6.11-1.41 8.6l-7 13.81s-3.67 5.92-3.67-3.1l.28-16.07s-1.69-13.82-9-7.9c0 0-9.62 11.38-11.15 15 0 0-6.61 10.94-4.07 50.13 0 0 1.69 5.08-.29 8.74l-8.46 15.51s-3.09 4.23-3.66-1.41c0 0-10.56-121.79-11.33-153.52"
            fill="#dadbdc"
            fillRule="nonzero"
          />
          <g className={`part ${chosenItems["WAND"] && "part--active"}`}>
            <path d="m549.17 355.15c6.15-65.15-2.73-140.15-2.73-140.15-.84-7.19-5.92-5.5-5.92-5.5l-45 10.15c-2.883345.425681-5.080906 2.80221-5.28 5.71l-.85 200c.06 2.41.35 6.12 1.41 8.6l7.05 13.81s3.66 5.93 3.66-3.1l-.28-16.07s1.69-13.81 9-7.89c0 0 9.63 11.37 11.16 15 0 0 6.61 10.95 4.07 50.14 0 0-1.69 5.07.28 8.74l8.46 15.51s3.1 4.22 3.66-1.42c0 0 10.57-121.78 11.33-153.51" />
            <path d="m490.2 225.31c.199094-2.90779 2.396655-5.284319 5.28-5.71l45-10.15s5.08-1.69 5.92 5.5c0 0 8.88 75.07 2.73 140.2-.76 31.73-11.33 153.51-11.33 153.51-.56 5.65-3.66 1.41-3.66 1.41s-7.11-12.66-7.54-13.81 0-54 0-54c-.354326-1.84417-1.177401-3.566084-2.39-5l-19.12-25.26s-3.85-5.86-3.85 3.11v13.17c0 .21-.05.33-.05.33l.05 2.71v16.54c-.81 4.16-3.43-.08-3.43-.08l-7.05-13.78c-1.06-2.48-1.35-6.19-1.41-8.6z" />
            <path d="m462.85 309.89s-1.13-6.76 2.25-5.35l16.9 7.61s3.66 2 3.66 8.46l.29 90.22s.28 11-6.21.85l-13.23-21.68c-2.163127-2.964993-3.433071-6.486831-3.66-10.15z" />

            <path d="m455.870961 373-.280961-104.67-.08-41.52c0-14.08-12-25.48-26.74-25.48h-114.91c-14.78 0-26.74 11.4-26.74 25.48v101.12l-.12 44.23c.29 8.53 9.31 7.64 9.31 7.64l150.84.3c9 0 8.720961-7.1 8.720961-7.1zm-121.380961-154.28c0-3.131455 2.538545-5.67 5.67-5.67h62.53c1.503777 0 2.945964.597373 4.009295 1.660705 1.063332 1.063331 1.660705 2.505518 1.660705 4.009295v21.22c0 1.503777-.597373 2.945964-1.660705 4.009295-1.063331 1.063332-2.505518 1.660705-4.009295 1.660705h-62.53c-3.131455 0-5.67-2.538545-5.67-5.67z" />
            <path d="m621.46 208.5 96.42-.89s15-.78 14.38 16.78v110.42s.85 11.48-9.3 11.48-105.31.95-105.31.95-15.08-8.32-14.24-29.89l9.59-99.52s0-9.02 8.46-9.33z" />
            <path d="m632.59 362 86.84-.84s11.85-1.81 12.69 14.45v115.28s-.56 13.53-12.41 13.53l-77.25 2.23s-14.38 3-16.07-7.87l1.41-84.58-5.5-37.36s-5.77-15.5 10.29-14.84z" />
            <path d="m621.17 191 3.11-18.24s-.42-9.22 18.32-9.59l75-2.25s10.71-1.41 11 9.15l-.89 21.58s-1.83 6.22-13.3 5.78l-87.8.29s-6 .65-5.44-6.72z" />
            <path d="m187.84 90.83 15.22 22.83s1.7 8.46 34.95 7.07c0 0 127.45-13.83 275.1 2.39 0 0 21.18 4.31 29.13-5.23l15.39-29.75s2.39-9.54-15.9-11.93c0 0-131.44-15.83-330.75-1.59.02 0-35.83 2.67-23.14 16.21z" />
            <path d="m137.64 33.31 21 33.11s-.88 4.23 21.91 3.39 142.29-16.2 374.24-1.69c0 0 24.54 4.23 28.92-3.39l21.63-32.27s8.46-13.53-35.65-16.79c0 0-239.27-11.84-399.66 3.39.04 0-39.32 2.28-32.39 14.25z" />
            <path d="m122.1 208.07-96.42-.88s-14.95-.78-14.38 16.81v110.39s-.85 11.48 9.3 11.48 105.31.95 105.31.95 15.08-8.32 14.23-29.89l-9.58-99.53s0-9.02-8.46-9.33z" />
            <path d="m111 361.62-86.83-.85s-11.89-1.77-12.73 14.46v115.24s.56 13.53 12.4 13.53l77.26 2.23s14.37 3 16.07-7.87l-1.41-84.58 5.49-37.36s5.75-15.51-10.25-14.8z" />
            <path d="m122.38 190.53-3.1-18.24s.42-9.21-18.32-9.58l-75-2.26s-10.72-1.41-11 9.15l.9 21.58s1.83 6.23 13.3 5.78l87.8.3s6.04.74 5.42-6.73z" />
            <path d="m466.09 219.39 17.13-4.65s3.35-.85 3.26-3.81-.09-16.28-.09-16.28.63-4.23-3-2.75l-18.61 8c-1.18846.605275-1.926447 1.836547-1.9 3.17l-.36 13.32s-1.29 4.27 3.57 3z" />
            <path d="m493.43 187.53 38.85-18.07s4.68-3 6.51 3.83c0 0 3.08 12.88 3 19.33 0 0 1.51 5.53-3.06 7l-44.73 12.47s-3.67 1-3.44-2.12v-17.5s-.67-2.88 2.87-4.94z" />
            <path d="m462.56 235.18s.29-8.45 3.39-9.45l16.05-3.46s4-2.17 4 7.61l-.29 68.49s1.13 10.26-4.51 7.28l-16.63-6.95s-2-.33-2.26-6.29z" />
            <path d="m276.17 220.21-17.17-4.65s-3.35-.85-3.26-3.81.09-16.28.09-16.28-.63-4.23 3-2.75l18.61 8c1.186661.612295 1.923113 1.844884 1.9 3.18l.36 13.32s1.3 4.26-3.53 2.99z" />
            <path d="m248.83 188.34-38.83-18.06s-4.68-3-6.51 3.83c0 0-3.08 12.88-3 19.33 0 0-1.51 5.53 3.06 7l44.8 12.47s3.67 1 3.44-2.13v-17.49s.58-2.89-2.96-4.95z" />
            <path d="m279.41 314.34v66.29c-.224165 3.66374-1.494381 7.186331-3.66 10.15l-13.25 21.71c-6.49 10.15-6.21-.85-6.21-.85l.25-80.6v-32.78l-.28-67.56c0-9.79 3.95-7.61 3.95-7.61l16.07 3.46c3.1 1 3.39 9.44 3.39 9.44l.25 52.55z" />
            <path d="m252.2 224.08c-.200015-2.911004-2.402723-5.288596-5.29-5.71l-45-10.15s-5.07-1.69-5.92 5.5c0 0-8.88 75.06-2.73 140.19.77 31.73 11.33 153.52 11.33 153.52.57 5.64 3.66 1.41 3.66 1.41s7.12-12.66 7.54-13.81 0-54 0-54c.354326-1.84417 1.177401-3.566084 2.39-5l19.16-25.26s3.86-5.87 3.86 3.1v13.13.33 2.7 16.57c.8 4.17 3.43-.07 3.43-.07l7-13.81c1.07-2.49 1.35-6.19 1.42-8.6z" />
            <g fill="transparent">
              <path
                d="m187.33 130.5-64.45-97.34s-24.51-21.16 29.05-24.33 233.3-16.93 453.89-1.06c0 0 30.86 0 15.43 21.16s-77.16 107.92-77.16 107.92-2.67 4-23-1.07c-8-2-171.27-18-300.21-3.16.04 0-28.1 9.52-33.55-2.12z"
                stroke="#666766"
                strokeWidth="2.54"
              />
              <path
                d="m735.51 525.71c8.72-2.54 7.75-11 7.75-11v-364.55c-65.84-.84-123.93 4.23-123.93 4.23-5.81.85-12.59 8.46-12.59 8.46-6.74 31.29-16.46 131.15-16.46 131.15-2.91 24.53 1.93 43.14 1.93 43.14s21.31 53.29 21.31 79.51l-1.94 101.5s0 12.68 9.68 11.84z"
                stroke="#666766"
                strokeWidth="2.54"
              />

              <path
                d="m8.05 525.29c-8.72-2.54-7.75-11-7.75-11v-364.55c65.84-.85 123.93 4.23 123.93 4.23 5.81.84 12.59 8.46 12.59 8.46 6.78 31.29 16.46 131.1 16.46 131.1 2.9 24.53-1.94 43.14-1.94 43.14s-21.34 53.33-21.34 79.51l2 101.49s0 12.69-9.68 11.85z"
                stroke="#666766"
                strokeWidth="2.54"
              />
              <path
                d="m198.81 163.08s3.19-18.08 28.36-20.38c0 0 169.49-12.82 295.16 5.24 0 0 16.11 0 25.78 19.22 0 0 34.15 141.54 21.91 181.15 0 0-4.51 21.55-8.38 30.87 0 0-7.09 23.3-9 46l-6.45 95.53s.71 11.75-8.38 16.89c0 0-83.78 19.22-320.29 7.57 0 0-16.52-.1-18.45-10.58l-8.62-117.56s-2.3-34.95-12.61-49.51c0 0-9.31-8.46-7.37-49.52-.02 0 5.78-101.33 28.34-154.92z"
                stroke="#666766"
                strokeWidth="2.54"
              />
            </g>
          </g>
          <g className={`part ${chosenItems["VLOER"] && "part--active"}`}>
            <path d="m252.53 448.78 33.47-58.21s1.51-3 7.43-2.73h158.8c2.820482-.181254 5.522028 1.161888 7.08 3.52l31.71 56c2.68 4.93-1 5.5-1 5.5s-33.62-2.4-28.17 9.44 10.89 25.38 10.89 25.38 1.35 8.88 39.94 5.92c0 0 2.86-.71 6 2.7l15.2 26.7c-.63 6.63-8.45 7.74-8.45 7.74-103.49 16.08-300.64 5-309.72 3.26s-6.35-10.46-6.35-10.46l14.92-25.93s.66-3.32 9.76-3c33.74 1.1 34.17-6.13 34.17-6.13s5.45-13.53 10.9-25.37c4.27-9.29-16.18-9.73-25.19-9.49 0 .01-4.26 1.04-1.39-4.84z" />
          </g>
          <g className={`part ${chosenItems["PLAFOND"] && "part--active"}`}>
            <path d="m289.67 196.13s-4.65 1.27-13.54-3l-60.47-28.33s-6.77-1.7-1.27-6.77c0 0 7.19-7.19 27.91-8.46 0 0 141.25-9.3 260.51 2.11 0 0 15.23 1.7 24.53 6.35 0 0 6.77 3.38.85 5.92l-63 29.18s-3.39 3.38-15.65 3.38z" />
          </g>
          <rect
            height="32.56"
            rx="5.67"
            stroke="#666766"
            width="73.88"
            x="334.49"
            y="213.05"
          />
          <g className={`part ${chosenItems["WIELKAST"] && "part--active"}`}>
            <path
              d="m473.36 494.35 52.88 5.55-52.88-5.58v-62.16l52.88 5.55v62.4"
              stroke="#fff"
              strokeWidth="1.2"
            />
            <path
              d="m473 431.07-16.15-31.7 34.63-5.11 34.14 42.36z"
              stroke="#fff"
              strokeWidth="1.2"
            />
            <path
              d="m456.19 400.04 16.23 31.7v62.26l-16.23-44.04z"
              stroke="#fff"
              strokeWidth="1.2"
            />
            <path d="m456.11 402.38v-3.08l5.63-.78" stroke="#fff" />
            <path d="m477.44 494.87-5.02-.54-2.09-5.87" stroke="#fff" />
            <path
              d="m268.6 494.03-52.88 5.55 52.88-5.58v-62.16l-52.88 5.55v62.4"
              stroke="#fff"
              strokeWidth="1.2"
            />
            <path
              d="m268.96 430.75 16.15-31.71-34.63-5.1-34.15 42.36z"
              stroke="#fff"
              strokeWidth="1.2"
            />
            <path
              d="m285.76 399.72-16.22 31.7v62.26l16.22-44.05z"
              stroke="#fff"
              strokeWidth="1.2"
            />
          </g>
          <path
            d="m285.84 402.06v-3.08l-5.62-.78"
            fill="#dadbdc"
            fillRule="nonzero"
            stroke="#fff"
            strokeWidth="1.2"
          />
          <path
            d="m264.52 494.55 5.02-.55 2.08-5.87"
            fill="#dadbdc"
            fillRule="nonzero"
            stroke="#fff"
            strokeWidth="1.2"
          />
        </g>
      </svg>
    </div>
  );
};

export default CarInternal;
