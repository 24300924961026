import React, { useEffect, useState } from "react";
import { useField } from "formik";
import { Select } from "../../../_General";
import { I18n } from "@lingui/react";
import { t } from "@lingui/macro";

const HeightOption = ({
  options,
  setValues,
  setFieldTouched,
  isLengthOptionChosen,
  values,
  ...props
}) => {

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (isLengthOptionChosen) {
      setIsMounted(true);
    }
  }, [isLengthOptionChosen]);

  const [{ name }, meta] = useField(props);

  if (!options) {
    return null;
  }

  if (options && options.length === 0) {
    return null;
  }

  if (options && options.length === 1) {
    if (meta.value === "" || meta.value.id !== options[0].id) {
      setValues({ ...values, [name]: options[0] })
    }
    return null;
  }

  if (options && options.length > 1) {
    let isDifferent = true;

    for (let option of options) {
      if (meta.value !== "" && option.id === meta.value.id) {
        isDifferent = false;
      }
    }
    if (isDifferent) {
      meta.value !== "" && setValues({ ...values, [name]: "" });
    }
  }

  const customOnChange = (chosenOption) => {
    setValues({ ...values, [name]: chosenOption })
  }

  const customOnBlur = () => {
    setFieldTouched(name);
  }

  return (
    <I18n>
      {({ i18n }) => (
        <div className={`height-option ${options && isMounted && "height-option--active"}`}>
          <Select
            placeholder={i18n._(t`Height`)}
            defaultValue={meta.value}
            options={options}
            onChangeHook={(chosenOption) => customOnChange(chosenOption)}
            onBlurHook={() => customOnBlur()}
            error={meta.touched && meta.error}
          ></Select>
        </div>
      )}
    </I18n>
  );
};

export default HeightOption;
