import React from "react";
import { Trans } from "@lingui/macro";
import { Camera } from "../../../_General";
import { connect } from "react-redux";

const StepProductsOptionRadio = ({ 
  categoryId, 
  form, 
  materials,
  currentModel, 
  photoGallery,
  onChange
}) => {
  const filterPhotoGalleryItems = material => {
    let foundModelGroup;
    for (const model of photoGallery.parameters?.model_options) {
      if (model.value === currentModel.toUpperCase()) {
        foundModelGroup = model.modelgroup_id;
        break;
      }
    }

    let foundResults = [];
    for (const result of photoGallery.results) {
      if (result.modelgroup_id === foundModelGroup) {
        foundResults.push(result);
      }
    }

    let finalResults = [];
    for (const result of foundResults) {
      if (result.categorie_materiaal_captions) {
        for (const caption of result.categorie_materiaal_captions) {
          if (caption.base_categorie_id === categoryId && caption.materiaal_id === material.value) {
            finalResults.push(result);
          }
        }
      }
    }

    return finalResults;
  };

  const filterMaterialsOptions = material => {
    const relevantGalleryItems = filterPhotoGalleryItems(material);
    
      return (
        <div key={material.value} className="step-products__left__products__product-detail__option__value">
          <label className="product-option">
            <input
              className="product-option__input"
              type="radio"
              name={`materiaal${categoryId}`}
              onChange={(e) => onChange(e)}
              value={material.value}
              checked={
                form.values.products[categoryId] && material.value === form.values.products[categoryId].materiaal_id
              }
            />
            <img className="product-option__image" src={material.image} alt="" />
            <span className="product-option__label">{material.label} </span>
          </label>
          {relevantGalleryItems.length > 0 && <Camera images={relevantGalleryItems} />}
        </div>
      );
  };

  return (
    <>
      <div className="step-products__left__products__product-detail__option ">
        <div className="step-products__left__products__product-detail__option__label">
          <Trans>Material</Trans>
        </div>
        <div className="step-products__left__products__product-detail__option__wrapper">
          {materials.map(material => {
            return filterMaterialsOptions(material);
          })}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    allProducts: state.allProducts,
    currentModel: state.currentConfig.model,
    photoGallery: state.photoGallery
  };
};

export default connect(mapStateToProps)(StepProductsOptionRadio);
