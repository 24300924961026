import React from "react";
import { connect } from "react-redux";
import SilhouetPlaceholder from "../../../../assets/silhouet-placeholder.svg";

const Silhouet = ({ carModelOptions, brand, model, length = "", height = "" }) => {
  let silhouetOptions = [];

  if (carModelOptions && carModelOptions[brand] && carModelOptions[brand][model]) {
    silhouetOptions = carModelOptions[brand][model].silhouet_opties;
  }

  const setSilhouet = () => {
    if (length === "" || height === "") {
      if (silhouetOptions[0] && silhouetOptions[0].image !== null) {
        return <img src={silhouetOptions[0].image} alt="" />;
      }
    }
    for (const silhouetOption of silhouetOptions) {
      if (length === silhouetOption.lengte_id && height === silhouetOption.hoogte_id && silhouetOption.image !== null) {
        return <img src={silhouetOption.image} alt="" />;
      }
    }
    return <img src={SilhouetPlaceholder} alt="" />;
  };

  return <>{setSilhouet()}</>;
};

const mapStateToProps = state => {
  return {
    carModelOptions: state.carModelOptions
  };
};

export default connect(mapStateToProps)(Silhouet);
