import React, { useEffect, useState } from "react";
import { Trans } from "@lingui/macro";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import {
  resetFullCurrConfig,
  resetCategories,
  resetAllowedSteps,
  setAllConfigurations
} from "../../../redux/action-creators";
import { Button, Fade, Spinner, Pagination, NotificationLarge, CartLink } from "../../_General";
import ConfigurationsRow from "./ConfigurationsRow";
import ConfigurationsRowEmpty from "./ConfigurationsRowEmpty";
import { useFetch } from "../../Utilities/useFetch";

import "./Configurations.scss";
import Silhouet from "../Configurator/StepModel/Silhouet";

const Configurations = ({
  history,
  resetCategories,
  resetFullCurrConfig,
  resetAllowedSteps,
  setAllConfigurations,
  clientId,
  allConfigurations,
  language
}) => {
  const [{ loading }, doFetch] = useFetch();
  const [orderPage, setOrderPage] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count > 0) {
      getConfigurations();
    }
  }, [language])

  useEffect(() => {
    getConfigurations();
    setCount(count + 1);
  }, []);

  const getConfigurations = () => {
    doFetch({
      method: "GET",
      url: `hebic/json/klant/${clientId}/configuraties`,
      result: res => {
        const obj = {};

        for (let configuration of res.data) {
          const productItems = {};
          for (const productItem of configuration.product_items) {
            productItems[productItem.categorie_id] = productItem;
          }
          obj[configuration.id] = {
            ...configuration,
            product_items: productItems
          };
        }
        setAllConfigurations(obj);
      }
    });
  }

  return (
    <>
      <div className="configurations">
        <header className="configurations__header">
          <h1>
            <Trans>My configurations</Trans>
          </h1>
          <Button
            onClick={() => {
              resetFullCurrConfig();
              resetCategories();
              resetAllowedSteps();
              history.push("/configurator/model");
            }}>
            <Trans>New order</Trans>
          </Button>
          <CartLink></CartLink>
        </header>
        <main className="content configurations__content">
          <div>
            {history?.location?.state?.deletedConfiguration && (
              <NotificationLarge>
                <Trans>Configuration</Trans>
                <span className="configuration-name">{history.location.state.deletedConfiguration}</span>{" "}
                <Trans>has been deleted.</Trans>
              </NotificationLarge>
            )}
            {history?.location?.state?.duplicatedConfiguration && (
              <NotificationLarge>
                <Trans>Configuration</Trans>
                <span className="configuration-name">{history.location.state.duplicatedConfiguration}</span>{" "}
                <Trans>has been duplicated into configuration</Trans>
                <span className="configuration-name">{history.location.state.newConfiguration}</span>
              </NotificationLarge>
            )}
            <div className="configurations__content__list">
              <table className="configurations__content__list__table">
                <tbody>
                  <tr className="configurations__content__list__table__head">
                    <th style={{ width: "15%", minWidth: "18rem" }}></th>
                    <th style={{ width: "20%" }}>
                      <Trans>Configuration name</Trans>
                    </th>
                    <th>
                      <Trans>Brand</Trans>
                    </th>
                    <th>
                      <Trans>Model</Trans>
                    </th>
                    <th>
                      <Trans>Introduction year</Trans>
                    </th>
                    <th>
                      <Trans>Length</Trans>
                    </th>
                    <th>
                      <Trans>Height</Trans>
                    </th>
                    <th></th>
                  </tr>
                  <ConfigurationsRowEmpty />
                  {loading ? (
                    <Spinner width="7rem" />
                  ) : (
                    Object.values(allConfigurations).length === 0 ? (
                      <tr>
                        <td className="no-configurations-message" colSpan="9">
                          <h3><Trans>You do not have any configurations yet.</Trans></h3>
                        </td>
                      </tr>
                ) : (
                    Object.values(allConfigurations)
                      ?.sort((a, b) => b.id - a.id)
                      .map(configuration => (
                        <Fade key={configuration.id} classNames="fade-from-bottom">
                          <ConfigurationsRow configuration={configuration} history={history} />
                        </Fade>
                      ))
                  ))}
                </tbody>
              </table>

              <div className="mobile-configurations">
                {loading ? (
                  <Spinner width="7rem" />
                ) : (
                  Object.values(allConfigurations).length === 0 ?
                  (<div className="no-configurations-message">
                    <h3><Trans>You do not any configurations yet.</Trans></h3>
                  </div>) :
                  Object.values(allConfigurations)
                    ?.sort((a, b) => b.id - a.id)
                    .slice(orderPage * 10, orderPage * 10 + 10)
                    .map(configuration => {
                      return (
                        <div key={configuration.id} className="mobile-configuration">
                          <div className="mobile-configuration__image">
                            <Silhouet
                              brand={configuration.automodel.merk_id}
                              model={configuration.automodel.model_id}
                              height={configuration.automodel.hoogte_id}
                              length={configuration.automodel.lengte_id}
                            />
                          </div>
                          <div className="mobile-configuration__settings">
                            <div className="mobile-configuration__settings__left">
                              <div className="mobile-configuration__setting">
                                <div className="mobile-configuration__setting__label">
                                  <Trans>Name</Trans>
                                </div>
                                <div className="mobile-configuration__setting__value">{configuration.naam}</div>
                              </div>
                              <div className="mobile-configuration__setting">
                                <div className="mobile-configuration__setting__label">
                                  <Trans>Brand</Trans>
                                </div>
                                <div className="mobile-configuration__setting__value">
                                  {configuration.automodel.merk_id}
                                </div>
                              </div>
                              <div className="mobile-configuration__setting">
                                <div className="mobile-configuration__setting__label">
                                  <Trans>Model</Trans>
                                </div>
                                <div className="mobile-configuration__setting__value">
                                  {configuration.automodel.model_id}
                                </div>
                              </div>
                            </div>
                            <div className="mobile-configuration__settings__right">
                              <div className="mobile-configuration__setting">
                                <div className="mobile-configuration__setting__label">
                                  <Trans>Introduction year</Trans>
                                </div>
                                <div className="mobile-configuration__setting__value">2000</div>
                              </div>
                              <div className="mobile-configuration__setting">
                                <div className="mobile-configuration__setting__label">
                                  <Trans>Length</Trans>
                                </div>
                                <div className="mobile-configuration__setting__value">
                                  {configuration.automodel.lengte_id}
                                </div>
                              </div>
                              <div className="mobile-configuration__setting">
                                <div className="mobile-configuration__setting__label">
                                  <Trans>Height</Trans>
                                </div>
                                <div className="mobile-configuration__setting__value">
                                  {configuration.automodel.hoogte_id}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mobile-configuration__action">
                            <NavLink to={`/configurations/${configuration.id}`}>
                              <Trans>Detail</Trans>
                            </NavLink>
                          </div>
                        </div>
                      );
                    })
                )}
              </div>
            </div>
          </div>
          {Math.floor(allConfigurations.length / 10) > 1 && (
            <Pagination orderPage={orderPage} setOrderPage={setOrderPage} items={allConfigurations} />
          )}
        </main>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    clientId: state.currentClient.id,
    allConfigurations: state.allConfigurations,
    carModelOptions: state.carModelOptions,
    language: state.language
  };
};

export default connect(mapStateToProps, {
  resetFullCurrConfig,
  resetCategories,
  resetAllowedSteps,
  setAllConfigurations
})(Configurations);
