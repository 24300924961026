import React from "react";
import { Switch, Route, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { Trans } from "@lingui/macro";
import { CartLink, Fade } from "../../_General";

import ConfiguratorStepModel from "./StepModel/ConfiguratorStepModel";
import ConfiguratorStepProducts from "./StepProducts/ConfiguratorStepProducts";
import ConfiguratorStepOrders from "./StepOrders/ConfiguratorStepOrders";

import "./Configurator.scss";
import { KeepAlive } from "react-keep-alive";
import { setConfigurationStep } from "../../../redux/action-creators";

const Configurator = ({ match, configurationStep: step, model, allowedSteps, setConfigurationStep }) => {
  const checkIfAllowed = (e, step) => {
    if (allowedSteps[step] === false) {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="configurator">
        <header className="configurator__header">
          <div className="configurator__header__title">
            <h1>
              <Trans>Configurator</Trans>
            </h1>
          </div>
          <div className="configurator__header__steps">
            <NavLink
              onClick={() => setConfigurationStep(1)}
              to="/configurator/model"
              className={`configurator__header__steps__step ${step === 1 &&
                `configurator__header__steps__step--active`}`}>
              <div
                style={{
                  transform: model !== "" && "translateY(-.5rem)",
                  transition: "transform .2s cubic-bezier(0.5, 0, 0, 1)"
                }}>
                <Trans>Model</Trans>
              </div>
              {step === 1 && model !== "" && (
                <Fade classNames="fade-in">
                  <div
                    className={
                      step === 1
                        ? `configurator__header__steps__step--active__chosen-model`
                        : `configurator__header__steps__step__chosen-model`
                    }>
                    {model}
                  </div>
                </Fade>
              )}
              {step !== 1 && (
                <Fade classNames="fade-in">
                  <div
                    className={
                      step === 1
                        ? `configurator__header__steps__step--active__chosen-model`
                        : `configurator__header__steps__step__chosen-model`
                    }>
                    {model}
                  </div>
                </Fade>
              )}
            </NavLink>
            <NavLink
              onClick={e => { 
                checkIfAllowed(e, "products")
                setConfigurationStep(2);
            }}
              to="/configurator/products"
              className={`configurator__header__steps__step ${step === 2 &&
                `configurator__header__steps__step--active`}`}>
              <Trans>Products</Trans>
            </NavLink>
            <NavLink
              onClick={e => {
                checkIfAllowed(e, "order");
                setConfigurationStep(3);
              }}
              to="/configurator/orders"
              className={`configurator__header__steps__step ${step === 3 &&
                `configurator__header__steps__step--active`}`}>
              <Trans>Overview</Trans>
            </NavLink>
          </div>
          <CartLink></CartLink>
        </header>
        <main className="configurator__content">
          <Switch>
            <Route path={`${match.path}/model`}>
              <KeepAlive name={ConfiguratorStepModel}>
                <ConfiguratorStepModel />
              </KeepAlive>
            </Route>
            <Route path={`${match.path}/products`} component={ConfiguratorStepProducts} />
            <Route path={`${match.path}/orders`} component={ConfiguratorStepOrders} />
          </Switch>
        </main>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    configurationStep: state.currentConfigurationStep,
    model: state.currentConfig.model,
    allowedSteps: state.allowedSteps
  };
};

export default connect(mapStateToProps, {setConfigurationStep})(Configurator);
